import { RasterPoint, IRasterPoint } from "../geometry/raster-point"
import { IEditingTool } from "./iediting-tool"
import { FrameCharLineStyle, FrameCharCornerStyle, FrameCharDashStyle, frameCharTraits } from "../char-helpers/frame-chars"
import { IRaster, IRasterPointItem, Raster } from "../drawing/raster"
import { EditingToolEventHandler, IEditingToolEventHandler } from "./iediting-tool-session"
import { IToolStates, ToolInputModel, ToolOutputModel } from "./tool-model/tool-model"
import { IDragState } from "../app/store/reducers/drawing-reducer"
import { ConnectorRenderer } from "./routing-tool/connector-renderer"
import { IRasterNeighbour, RasterNeighbour } from "../char-helpers/raster-neighbour"
import { arrowHeads, ArrowStyle, getArrowForDirection, getArrowStyleStringAndDirForSample } from "../char-helpers/arrow-sets"
import { LineStyleActionProvider } from "../editing-actions/line-style-action-provider"


enum SegmentDirection { Undefined, Horizontal, Vertical }

export interface PathDrawingToolModel {
    inferStyleFromPoint: boolean,
    path: IRasterPoint[]
    activeSegmentDirection: SegmentDirection
}

export class PathDrawingTool implements IEditingTool {
    static staticName = "drawpath"
    readonly name = "drawpath"
    readonly iconName = "draw"
    readonly cursorName = "crosshair"
    readonly handleId = "drawpath"
    readonly canHandleDragFromAnywhere = true

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.pathDraw?.path) {
            state =
            {
                pathDraw: {
                    inferStyleFromPoint: true,
                    path: [],
                    activeSegmentDirection: SegmentDirection.Undefined
                }
            }
        }
        return state
    }

    makeHandler(input: ToolInputModel): IEditingToolEventHandler {
        if (!input.drawing.toolStates.pathDraw) {
            throw new Error("tried to construct handler without existing model")
        }
        return new PathDrawingToolEventHandler(input)
    }

}



export class PathDrawingToolEventHandler extends EditingToolEventHandler {

    private raster: IRaster<string>
    private cursorPosition: IRasterPoint
    private cornerStyle: FrameCharCornerStyle
    private lineStyle: FrameCharLineStyle
    private dashStyle: FrameCharDashStyle
    private inferStyle: boolean
    private path: IRasterPoint[]
    private activeSegmentDirection: SegmentDirection
    private arrowStyle: ArrowStyle

    constructor(private readonly inModel: ToolInputModel) {
        super()
        this.raster = inModel.drawing.characterRaster
        this.cursorPosition = inModel.drawing.cursorPos
        this.inferStyle = inModel.drawing.toolStates.pathDraw.inferStyleFromPoint
        this.path = inModel.drawing.toolStates.pathDraw.path
        this.activeSegmentDirection = inModel.drawing.toolStates.pathDraw.activeSegmentDirection
        const currentStyles = LineStyleActionProvider.getCurrentStyles(inModel.drawing)
        this.lineStyle = currentStyles.lineStyle
        this.dashStyle = currentStyles.dashStyle
        this.cornerStyle = currentStyles.cornerStyle
        this.arrowStyle = currentStyles.arrowStyle
    }

    initializeDrag() {
        this.path = []
    }

    handleDrag(event: IDragState): boolean {
        const from = RasterPoint.fromString(event.draggedCellKey)
        const to = RasterPoint.plus(from, event.dragOffset)

        const lastPointOfPath = this.path.length > 0 ? this.path[this.path.length - 1] : from
        const dTo = RasterPoint.minus(to, lastPointOfPath)
        let constrainedTo: IRasterPoint = null
        if (dTo.x != 0 || dTo.y != 0) {
            if (this.activeSegmentDirection == SegmentDirection.Undefined) {
                constrainedTo = Math.abs(dTo.x) > Math.abs(dTo.y) ? { x: to.x, y: lastPointOfPath.y } : { y: to.y, x: lastPointOfPath.x }
                this.activeSegmentDirection = (dTo.x > 0) ? SegmentDirection.Horizontal : SegmentDirection.Vertical
            } else if (this.activeSegmentDirection == SegmentDirection.Horizontal) {
                constrainedTo = { x: to.x, y: lastPointOfPath.y }
                if (Math.abs(dTo.y) > 1) {
                    this.path = [...this.path, constrainedTo]
                    this.activeSegmentDirection = SegmentDirection.Vertical
                    constrainedTo = { x: to.x, y: to.y }
                }
            } else if (this.activeSegmentDirection == SegmentDirection.Vertical) {
                constrainedTo = { y: to.y, x: lastPointOfPath.x }
                if (Math.abs(dTo.x) > 1) {
                    this.path = [...this.path, constrainedTo]
                    this.activeSegmentDirection = SegmentDirection.Horizontal
                    constrainedTo = { x: to.x, y: to.y }
                }
            }
        } else {
            if (this.path.length > 0) {
                constrainedTo = this.path[this.path.length - 1]
                this.path = this.path.slice(0, -1)
                this.activeSegmentDirection = SegmentDirection.Undefined
            }
        }

        if (constrainedTo) {
            this.raster = this.applyPathToRaster(this.raster, [from, ...this.path, constrainedTo])
            this.cursorPosition = constrainedTo
        }
        return true
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    startEditingAt(pos: IRasterPoint) {
    }

    canStartEditingAt(pos: IRasterPoint): boolean {
        return true
    }

    getResultModel(): ToolOutputModel {
        return {
            drawing: {
                ...this.inModel.drawing,
                characterRaster: this.raster,
                cursorPos: this.cursorPosition,
                toolStates: {
                    pathDraw: {
                        ...this.inModel.drawing.toolStates.pathDraw,
                        inferStyleFromPoint: this.inferStyle,
                        path: this.path,
                        activeSegmentDirection: this.activeSegmentDirection
                    }
                }
            }
        }
    }

    private applyPathToRaster(raster: IRaster<string>, path: IRasterPoint[]): IRaster<string> {
        if(!path || path.length === 0) return raster

        let previous = path[0]
        const fullPath: IRasterPoint[] = []
        for (const current of path) {
            const dx = current.x - previous.x
            const dy = current.y - previous.y
            if (dx != 0) {
                for (let x = previous.x; Math.round(x) != Math.round(current.x); x += Math.sign(dx)) {
                    fullPath.push({ x, y: previous.y })
                }
            } else if (dy != 0) {
                for (let y = previous.y; Math.round(y) != Math.round(current.y); y += Math.sign(dy)) {
                    fullPath.push({ x: previous.x, y })
                }
            }
            previous = current
        }
        if (path.length > 0) {
            fullPath.push({ x: path[path.length - 1].x, y: path[path.length - 1].y })
        }

        const connectorRenderer = new ConnectorRenderer(this.lineStyle, this.dashStyle, this.cornerStyle, this.arrowStyle, ArrowStyle.none)
        const pathRaster = connectorRenderer.renderPathToRaster(fullPath, raster)
        const updatedRaster = Raster.updateWith(raster, pathRaster)
        return updatedRaster
    }

    private applyCurrentStyleToSelection() {
        const updatedCells: IRasterPointItem<string>[] = []
        this.updateLineSegmentsWithCurrentStyle(updatedCells)
        this.updateArrowHeadsWithCurrentStyle(updatedCells)
        this.raster = Raster.updateWith(this.raster, Raster.fromCells(updatedCells))
    }

    private updateLineSegmentsWithCurrentStyle(updatedCells: IRasterPointItem<string>[]) {
        for (const cell of Raster.getCells(this.inModel.drawing.selection)) {
            const c = Raster.getAtPoint(this.raster, cell.point)
            const charTraits = frameCharTraits.getByChar(c)
            const targetStyles = charTraits.lineStyleList.map(l => this.getTargetStyleForSelectedCell(cell.point, l[0], l[1]))
            const candidates = frameCharTraits.all.filter(t => t.connectsExactlyToWithLineStyles(targetStyles))
                .filter(t => t.dashStyle !== FrameCharDashStyle.undefined ? t.dashStyle === this.dashStyle : true)
                .filter(t => t.cornerStyle !== FrameCharCornerStyle.undefined ? t.cornerStyle === this.cornerStyle : true)
            if (candidates.length === 1) {
                const updatedCellTraits = candidates[0]
                updatedCells.push({ point: cell.point, value: updatedCellTraits.char })
            }
        }
    }

    private updateArrowHeadsWithCurrentStyle(updatedCells: IRasterPointItem<string>[]) {
        let targetArrowString = ""
        switch(this.arrowStyle) {
            case ArrowStyle.ascii: targetArrowString = arrowHeads.ascii; break
            case ArrowStyle.big: targetArrowString = arrowHeads.big; break
            case ArrowStyle.small: targetArrowString = arrowHeads.small; break
        }
        for (const cell of Raster.getCells(this.inModel.drawing.selection)) {
            const c = Raster.getAtPoint(this.raster, cell.point)
            
            const [arrowString, dir] = getArrowStyleStringAndDirForSample(c)
            if(arrowString)  {
                const targetArrow = getArrowForDirection(targetArrowString, dir)
                updatedCells.push({point: cell.point, value: targetArrow})
            }
        }
    }

    private getTargetStyleForSelectedCell(p: IRasterPoint, d: IRasterNeighbour, currentLineStyle: FrameCharLineStyle): [IRasterNeighbour, FrameCharLineStyle] {
        const neighbourPoint = RasterPoint.plus(p, RasterNeighbour.offset(d, 1))
        const neighbourChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, neighbourPoint)
        const isConnectedBack = frameCharTraits.getByChar(neighbourChar).connectsTo(RasterNeighbour.opposite(d))
        const isNeighbourSelected = Raster.getAtPoint(this.inModel.drawing.selection, neighbourPoint) === true
        return [d, isConnectedBack && isNeighbourSelected ? this.lineStyle : currentLineStyle];
    }

}



