/* eslint-disable unicorn/no-array-reduce */
import { EditorD3Scene } from "../app/character-raster-editor/editor-d3-scene"
import { IDragState, KeyboardEventPayload } from "../app/store/reducers/drawing-reducer"
import { RasterNeighbour } from "../char-helpers/raster-neighbour"
import { IRaster, IRasterPointItem, Raster } from "../drawing/raster"
import { RasterBox } from "../geometry/raster-box"
import { IRasterPoint, RasterPoint } from "../geometry/raster-point"
import { HandleSymbol } from "./handle-model"
import { IEditingTool } from "./iediting-tool"
import { EditingToolEventHandler, IEditingToolEventHandler } from "./iediting-tool-session"
import { SelectShapeUtil } from "./select-shape-util"
import { previewBoxing, previewLasso } from "./select-tool-preview"
import { SmartDelete } from "./smart-delete"
import { IToolStates, ToolInputModel, ToolOutputModel } from "./tool-model/tool-model"
import { IInteractionHandleModel, InteractionHandleModel } from "./tool-services/handle-service"

const copyHandleId = "copyHandle"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SelectToolModel {
    isDraggingSelection: boolean,
    selectionMode: "box" | "lasso" | "undefined",
    path: IRasterPoint[]
}

export class SelectTool implements IEditingTool {
    static readonly toolName = "select"
    readonly name = SelectTool.toolName
    readonly cursorName = "default"
    readonly iconName = "select"
    readonly handleId = "select"
    readonly canHandleDragFromAnywhere = false

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.select) {
            state = {
                select: {
                    isDraggingSelection: false,
                    selectionMode: "undefined",
                    path: []
                }
            }
        }
        return state
    }

    makeHandler(inModel: ToolInputModel): IEditingToolEventHandler {
        return new SelectToolEventHandler(inModel)
    }
}

class SelectToolEventHandler extends EditingToolEventHandler {

    private raster: IRaster<string>
    private selection: IRaster<boolean>
    private cursor: IRasterPoint
    private path: IRasterPoint[]
    private isDraggingSelection: boolean
    private selectionMode: "box" | "lasso" | "undefined"

    constructor(private readonly model: ToolInputModel) {
        super()
        this.raster = model.drawing.characterRaster
        this.selection = model.drawing.selection
        this.cursor = model.drawing.cursorPos
        this.path = model.drawing.toolStates.select?.path ?? []
        this.isDraggingSelection = model.drawing.toolStates.select?.isDraggingSelection
        this.selectionMode = model.drawing.toolStates.select?.selectionMode
    }

    canStartEditingAt(pos: IRasterPoint) {
        return true
    }

    startEditingAt(pos: IRasterPoint) { return }

    initializeDrag(): void {
        this.path = []
        this.selectionMode = "undefined"
    }

    handleKeyEvent(event: KeyboardEventPayload) {
        if (event.key === "Delete" && event.isPress) {
            [this.raster, this.selection] = SmartDelete.deleteSelectionWithInterpolation(this.raster, this.selection)
            return true
        }
        return false
    }

    static getTestBoxForDrag(from: IRasterPoint, to: IRasterPoint): RasterBox {
        const offset = RasterPoint.minus(to, from)
        if (RasterPoint.getLength(offset) > 0) {
            const shortTo = RasterPoint.plus(RasterNeighbour.offset(RasterNeighbour.fromOffset(offset), -1), to)
            return new RasterBox(from, shortTo).getExpandedBy(0, 0, 1, 1)
        } else {
            return new RasterBox(from, from)
        }
    }

    handleDoubleClick(pos: IRasterPoint): boolean {
        this.selection = SelectShapeUtil.selectShapeAtPoint(this.raster, pos)
        return true
    }

    handleDrag(event: IDragState): boolean {
        const from = RasterPoint.fromString(event.draggedCellKey)
        const to = RasterPoint.plus(from, event.dragOffset)
        const isStartOnSelectedCell = Raster.getAtPoint(this.selection, from) ? true : false
        const copyHandleUsed = event.draggedHandleId === copyHandleId

        if (this.selectionMode === "undefined") {
            if (from.y < to.y) {
                this.selectionMode = "box"
            }
            if (from.y > to.y) {
                this.selectionMode = "lasso"
            }
        }

        if (isStartOnSelectedCell || copyHandleUsed) {
            this.isDraggingSelection = true
            this.handleMoveSelection(from, to, copyHandleUsed)
        } else {
            this.isDraggingSelection = false
            if (this.selectionMode === "lasso") {
                this.handleLasso(from, to)
                const lassoedCellsRaster = this.getSelectionForLassoPath()
                this.selection = this.limitedToNonEmptyCells(lassoedCellsRaster, this.raster)
            } else {
                this.handleBoxing(SelectToolEventHandler.getTestBoxForDrag(from, to), to)
            }
        }
        return true
    }

    getSvgDragLayer(scene: EditorD3Scene, event: IDragState): JSX.Element {
        // eslint-disable-next-line unicorn/prefer-ternary
        if (this.isDraggingSelection) {
            return
        } else {
            if (this.selectionMode === "lasso") {
                const lassoedCellsRaster = this.getSelectionForLassoPath()
                return previewLasso(scene, lassoedCellsRaster)
            } else {
                const from = RasterPoint.fromString(event.draggedCellKey)
                const to = RasterPoint.plus(from, event.dragOffset)
                const box = SelectToolEventHandler.getTestBoxForDrag(from, to)
                return previewBoxing(scene, box.topLeft, box.bottomRight)
            }
        }
    }

    getResultModel(): ToolOutputModel {
        return {
            ...this.model,
            drawing: {
                ...this.model.drawing,
                selection: this.selection,
                characterRaster: this.raster,
                cursorPos: this.cursor,
                toolStates: {
                    select: { path: this.path, isDraggingSelection: this.isDraggingSelection, selectionMode: this.selectionMode }
                }
            }
        }
    }

    getDisplayedHandles(): IInteractionHandleModel {
        let handles = InteractionHandleModel.withNoHandles()

        if (!Raster.isEmpty(this.selection)) {
            const selectionBounds = Raster.getBounds(this.selection)
            const handlePos = RasterPoint.xy(Math.round(0.5 * (selectionBounds.minX() + selectionBounds.maxX())), selectionBounds.minY() - 1)
            handles = InteractionHandleModel.withHandle(handles, RasterNeighbour.Top, RasterNeighbour.Bottom, HandleSymbol.Square, handlePos, copyHandleId)
        }
        return handles
    }

    private handleMoveSelection(from: IRasterPoint, to: IRasterPoint, copyHandleUsed: boolean) {
        const offset = RasterPoint.minus(to, from)
        const removedCells = Raster.getCells(this.selection)
            .filter(cell => Raster.getAtPoint(this.raster, cell.point))
            .map(cell => ({ value: undefined as string, point: cell.point }))
        const movedCells = Raster.getCells(this.selection)
            .filter(cell => Raster.getAtPoint(this.raster, cell.point))
            .map(cell => {
                const origValue = Raster.getAtPoint(this.raster, cell.point)
                const movedCell = { value: origValue, point: RasterPoint.plus(cell.point, offset) }
                return movedCell
            })
        const movedSelection = Raster.fromCells(Raster.getCells(this.selection).map(cell => ({ ...cell, point: RasterPoint.plus(cell.point, offset) })))
        let updatedRaster = this.raster
        if (!copyHandleUsed) {
            updatedRaster = Raster.updateWith(updatedRaster, Raster.fromCells(removedCells))
        }
        updatedRaster = Raster.updateWith(updatedRaster, Raster.fromCells(movedCells))
        this.raster = updatedRaster
        this.selection = movedSelection
        this.cursor = RasterPoint.plus(this.cursor, offset)
    }

    private handleBoxing(box: RasterBox, to: IRasterPoint) {
        const selectedCells = Raster.getCells(this.raster).filter(cell => box.isPointInside(RasterPoint.plusXY(cell.point, 0.5, 0.5))).map(cell => ({ point: cell.point, value: true }))
        this.selection = Raster.fromCells(selectedCells)
        if (selectedCells.length > 0) {
            const closestSelectedCell = selectedCells.map(c => c.point).reduce((previous, current) => {
                const previousDist = RasterPoint.manhattanDistTo(previous, to)
                const currentDist = RasterPoint.manhattanDistTo(current, to)
                return currentDist < previousDist ? current : previous
            })
            this.cursor = closestSelectedCell
        }
    }


    private handleLasso(from: IRasterPoint, to: IRasterPoint) {

        const path = this.path.length > 0 ? this.path : [from]
        const lineStart = path[path.length - 1]
        const line = RasterPoint.lineBresenham(lineStart, to)
        this.path = [...path, ...line.slice(1)]
    }

    private getSelectionForLassoPath(): IRaster<boolean> {
        const outlineSelection = Raster.fromCells(this.path.map(p => ({ point: p, value: true })))
        let originalSelection: IRaster<boolean>
        // eslint-disable-next-line unicorn/prefer-ternary
        if (this.path.length > 0 && RasterPoint.equals(this.path[0], this.path[this.path.length - 1])) {
            originalSelection = SelectShapeUtil.floodFillRaster(outlineSelection)
        } else {
            originalSelection = outlineSelection
        }
        return originalSelection
    }

    private limitedToNonEmptyCells(rasterToFilter: IRaster<boolean>, reference: IRaster<string>): IRaster<boolean> {
        const filteredCells = Raster.getCells(rasterToFilter).filter(c => Raster.getAtPoint(reference, c.point) !== undefined)
        return Raster.fromCells(filteredCells)
    }
}

