import * as d3 from 'd3';
import * as d3scale from 'd3-scale';
import { PointerEvent, PointerEventType } from '../../editor-model/pointer-event';
import { IRasterPoint, RasterPoint } from '../../geometry/raster-point';
import { collaborationService } from '../../services/collaborationservice';
import { drawingSlice } from '../store/reducers/drawing-reducer';
import { editorCanvasSlice } from "../store/reducers/editor-canvas-reducer";
import { globalStore } from '../store/store';


export class EditorD3Scene {

    private nativeViewBox: any
    private lastMovePos: IRasterPoint


    updateD3Svg(nativeViewBox: any) {
        this.nativeViewBox = nativeViewBox
    }

    notifyPointerDown(event: React.PointerEvent) {
        if (!event.isPrimary) {
            return
        }
        const newEvent = { pos: this.rasterizeMouseEvent(event), type: PointerEventType.down, isPressed: true }
        this.handlePointerEvent(newEvent)
    }

    notifyPointerUp(event: React.PointerEvent) {
        if (!event.isPrimary) {
            return
        }
        const newEvent = { pos: this.rasterizeMouseEvent(event), type: PointerEventType.up, isPressed: event.pressure > 0 }
        this.handlePointerEvent(newEvent)
    }

    notifyPointerMove(event: React.PointerEvent) {
        if (!event.isPrimary) {
            return
        }
        const newEvent = { pos: this.rasterizeMouseEvent(event), type: PointerEventType.move, isPressed: false }
        if (!RasterPoint.equals(newEvent.pos, this.lastMovePos)) {
            if (collaborationService.isConnected()) {
                collaborationService.sendPointerPosition(newEvent.pos)
            }
            this.lastMovePos = newEvent.pos
            this.handlePointerEvent(newEvent)
        }
    }

    notifyKeyDown(event: React.KeyboardEvent) {
        const originalState = globalStore.getState()
        this.applyTextInput(event.nativeEvent, true)
        const newState = globalStore.getState()

        if (originalState !== newState) {
            // event.preventDefault()
            // event.stopPropagation()
        }
    }

    notifyKeyUp(event: React.KeyboardEvent) {
        const originalState = globalStore.getState()
        this.applyTextInput(event.nativeEvent, false)
        const newState = globalStore.getState()

        if (originalState !== newState) {
            // event.preventDefault()
            // event.stopPropagation()
        }
    }

    private handlePointerEvent(event: PointerEvent): void {
        const pointerEvent = drawingSlice.actions.pointerEvent(event)
        globalStore.dispatch(pointerEvent)
    }

    private applyTextInput(keyboardEvent: KeyboardEvent, isPress: boolean) {
        if (!keyboardEvent.defaultPrevented && !keyboardEvent.altKey && !keyboardEvent.metaKey && !keyboardEvent.ctrlKey) {
            const keyboardAction = drawingSlice.actions.keyboardEvent({ isPress: isPress, key: keyboardEvent.key })
            globalStore.dispatch(keyboardAction)
        }
    }

    public clientToCellCoordinates(x: number, y: number): IRasterPoint {
        // see https://www.sitepoint.com/how-to-translate-from-dom-to-svg-coordinates-and-back-again/
        const canvas = globalStore.getState().canvas
        const svg = this.nativeViewBox
        if (!svg) {
            return { x: 0, y: 0 }
        }
        const svgPoint = svg.createSVGPoint()
        svgPoint.x = x
        svgPoint.y = y
        const svgP = svgPoint.matrixTransform(svg.getScreenCTM().inverse());
        const cellX: number = svgP.x / canvas.pxPerCellX
        const cellY: number = svgP.y / canvas.pxPerCellY
        return { x: Math.floor(cellX) - canvas.viewportOriginCell.x, y: Math.floor(cellY) - canvas.viewportOriginCell.y }
    }

    public cellToClientCoordinates(cellX: number, cellY: number): IRasterPoint {
        // see https://www.sitepoint.com/how-to-translate-from-dom-to-svg-coordinates-and-back-again/
        const canvas = globalStore.getState().canvas
        const svg = this.nativeViewBox
        if (!svg) {
            return { x: 0, y: 0 }
        }
        const svgPoint = svg.createSVGPoint()
        svgPoint.x = (cellX + canvas.viewportOriginCell.x) * canvas.pxPerCellX
        svgPoint.y = (cellY + canvas.viewportOriginCell.y) * canvas.pxPerCellY
        const svgP = svgPoint.matrixTransform(svg.getScreenCTM());
        const x: number = svgP.x
        const y: number = svgP.y
        return { x: x, y: y }
    }

    public scaleX(cellX: number) {
        const canvas = globalStore.getState().canvas
        const clientX = (cellX + canvas.viewportOriginCell.x) * canvas.pxPerCellX
        return clientX
    }

    public scaleY(cellY: number) {
        const canvas = globalStore.getState().canvas
        const clientY = (cellY + canvas.viewportOriginCell.y) * canvas.pxPerCellY
        return clientY
    }

    public rasterizeMouseEvent(event: React.MouseEvent) {
        const pos = this.clientToCellCoordinates(event.clientX, event.clientY)
        return pos
    }

}