import { RasterNeighbour } from '../../char-helpers/raster-neighbour'
import { HandleSymbol, IHandleModel } from '../../editing-tools/handle-model'

export const EditorD3Handle = {

    getHandleRotation: function(model: IHandleModel) {
        switch (model.shapeDirection) {
            case RasterNeighbour.Center: return 0
            case RasterNeighbour.Right: return 0
            case RasterNeighbour.BottomRight: return 45
            case RasterNeighbour.Bottom: return 90
            case RasterNeighbour.BottomLeft: return 135
            case RasterNeighbour.Left: return 180
            case RasterNeighbour.TopLeft: return 225
            case RasterNeighbour.Top: return 270
            case RasterNeighbour.TopRight: return 315
            default: return 0
        }
    },

    buildHandlePath: function(model: IHandleModel, cw: number, ch: number): string {
        const r = cw / 2
        // All handles are drawn for shapeside = right
        switch (model.symbol) {
            case HandleSymbol.Circle:
                return `M 0 0 a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 ${-r * 2} 0`
            case HandleSymbol.CenteredCircle:
                return `M ${-r} 0  a ${r} ${r} 0 1 0 ${r * 2} 0  a ${r} ${r} 0 1 0 ${-r * 2} 0`
            case HandleSymbol.Limit:
                return `M 0 0 L ${-cw / 2} ${cw / 2} H ${-cw} V ${-cw / 2} H ${-cw / 2} L 0 0`
            default:
                return `M 0 0 a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 ${-r * 2} 0`
        }
    }

}