import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRasterPoint } from "../../../geometry/raster-point";
import { createSelector } from 'reselect'
import * as d3scale from 'd3-scale';

export interface EditorCanvasState {
    pxPerCellX: number,
    pxPerCellY: number,
    viewportOriginCell: IRasterPoint,
}

const initialState: EditorCanvasState = {
    pxPerCellX: 10,
    pxPerCellY: 20,
    viewportOriginCell: { x: 0, y: 0 },
}

export class EditorCanvasHelpers {
    public cellToElementX: d3.ScaleLinear<number, number>
    public cellToElementY: d3.ScaleLinear<number, number>
}

export const getEditorCanvasHelpers = createSelector(
    [(s: EditorCanvasState) => s.pxPerCellX,
    (s: EditorCanvasState) => s.pxPerCellY,
    (s: EditorCanvasState) => s.viewportOriginCell],
    (pxPerCellX, pxPerCellY, viewportOriginCell): EditorCanvasHelpers => {
        return {
            cellToElementX: d3scale.scaleLinear()
                .domain([-viewportOriginCell.x, 1 - viewportOriginCell.x])
                .range([0, pxPerCellX]),

            cellToElementY: d3scale.scaleLinear()
                .domain([-viewportOriginCell.y, 1 - viewportOriginCell.y])
                .range([0, pxPerCellY]),
        }
    }
)

export function canvasToCellCoordinates(canvasState: EditorCanvasState, p: IRasterPoint): IRasterPoint {
    return { x: Math.round(p.x / canvasState.pxPerCellX), y: Math.round(p.y / canvasState.pxPerCellY) };
}

export function cellTopLeftToCanvasCoordinates(canvasState: EditorCanvasState, p: IRasterPoint): IRasterPoint {
    return { x: p.x * canvasState.pxPerCellX, y: p.y * canvasState.pxPerCellY };
}

export const editorCanvasSlice = createSlice({
    initialState,
    name: "EditorCanvas",
    reducers: {
        canvasResolutionChanged: (state, action: PayloadAction<{ pxPerCellX: number, pxPerCellY: number }>) => {
            state.pxPerCellX = action.payload.pxPerCellX
            state.pxPerCellY = action.payload.pxPerCellY
        },
        viewportOriginCellChanged: (state, action: PayloadAction<IRasterPoint>) => {
            state.viewportOriginCell = action.payload
        }
    }
})
