import { RasterNeighbour, IRasterNeighbour } from "./raster-neighbour"
import { Map as ImmutableMap } from "immutable"

export enum FrameCharDashStyle {
    undefined,
    regular,
    double,
    triple,
    quad
}

export enum FrameCharLineStyle {
    undefined,
    ascii,
    single,
    double,
    thick
}

export enum FrameCharCornerStyle {
    undefined,
    ascii,
    regular,
    rounded
}

export class FrameCharTraits {
    char = " "
    lineStyles: Map<IRasterNeighbour, FrameCharLineStyle> = new Map<IRasterNeighbour, FrameCharLineStyle>()
    lineStyleList: [IRasterNeighbour, FrameCharLineStyle][] = []
    dashStyle: FrameCharDashStyle = FrameCharDashStyle.undefined
    cornerStyle: FrameCharCornerStyle = FrameCharCornerStyle.undefined

    connectsTo(direction: IRasterNeighbour) {
        return this.lineStyles.has(direction) && this.lineStyles.get(direction) !== FrameCharLineStyle.undefined
    }

    connectsExactlyTo(connectedDirectories: IRasterNeighbour[]) {
        let allValid = true
        for (const direction of RasterNeighbour.AllDirections) {
            allValid = allValid && (this.connectsTo(direction) === connectedDirectories.includes(direction))
        }
        return allValid
    }

    connectsExactlyToWithLineStyle(connectedDirectories: IRasterNeighbour[], requiredLineStyle: FrameCharLineStyle) {
        let allValid = true
        for (const direction of RasterNeighbour.AllDirections) {
            const connects = this.connectsTo(direction)
            allValid = allValid && (connects === connectedDirectories.includes(direction))
                && (!connects || this.lineStyles.get(direction) === requiredLineStyle)
        }
        return allValid
    }

    connectsExactlyToWithLineStyles(connectedDirectories: [IRasterNeighbour, FrameCharLineStyle][]) {
        const mismatchIndex = connectedDirectories.findIndex(
            ([direction, style]) => !this.lineStyles.has(direction) || !(this.lineStyles.get(direction) === style))
        const allConnectedAsExpected = (mismatchIndex === -1) && (connectedDirectories.length === this.lineStyles.size)
        return allConnectedAsExpected
    }

    compareWithPreferredDashStyle(other: FrameCharTraits, preferredDashStyle?: FrameCharDashStyle) {
        if (preferredDashStyle && this.dashStyle !== other.dashStyle) {
            return this.dashStyle === preferredDashStyle ? -1 : 1
        } else {
            return 0
        }
    }

    compareWithPreferredCornerStyle(other: FrameCharTraits, preferredCornerStyle?: FrameCharCornerStyle) {
        if (preferredCornerStyle && this.cornerStyle !== other.cornerStyle) {
            return this.cornerStyle === preferredCornerStyle ? -1 : 1
        } else {
            return 0
        }
    }

    isCompatibleConnection(other: FrameCharTraits, toOtherDir: IRasterNeighbour) {
        const isDashStyleCompatible = this.dashStyle === other.dashStyle || this.dashStyle === FrameCharDashStyle.undefined || other.dashStyle === FrameCharDashStyle.undefined
        const lineStyle = this.lineStyles.get(toOtherDir)
        const lineStyleOther = other.lineStyles.get(RasterNeighbour.opposite(toOtherDir))
        const isLineStyleCompatible = lineStyle && lineStyleOther && lineStyle === lineStyleOther
        return isDashStyleCompatible && isLineStyleCompatible
    }

    getAnyUsedLineStyle(): FrameCharLineStyle {
        return this.lineStyleList.length === 0 ? FrameCharLineStyle.undefined : this.lineStyleList[0][1];
    }

    isCorner() {
        if (this.lineStyleList.length === 2 && RasterNeighbour.opposite(this.lineStyleList[0][0]) !== this.lineStyleList[1][0]) {
            return true
        }
        return false
    }
}


function groupTraitsBy<KEY>(items: FrameCharTraits[], keysFunction: (t: FrameCharTraits) => KEY[]): Map<KEY, FrameCharTraitsIndex> {
    const map = new Map<KEY, FrameCharTraitsIndex>()
    for (const item of items) {
        const keys = keysFunction(item)
        for (const key of keys) {
            if (!map.has(key)) {
                map.set(key, new FrameCharTraitsIndex())
            }
            map.get(key).chars.push(item)
        }
    }
    return map
}

export class FrameCharTraitsIndex {

    public chars: FrameCharTraits[] = []
    private static empty = new FrameCharTraitsIndex()
    private byCornerStyleMap: Map<FrameCharCornerStyle, FrameCharTraitsIndex>
    private byDashStyleMap: Map<FrameCharDashStyle, FrameCharTraitsIndex>
    private byNeighbourMap: Map<string, FrameCharTraitsIndex>
    private byNeighbourCountMap: Map<number, FrameCharTraitsIndex>
    private byCompatibleConnectionStyleMap: Map<string, FrameCharTraitsIndex> // using "<boxchar><dir>" as key

    withCornerStyle(cornerStyle: FrameCharCornerStyle): FrameCharTraitsIndex {
        if (!this.byCornerStyleMap) {
            this.byCornerStyleMap = groupTraitsBy(this.chars, t => [t.cornerStyle])
        }
        return this.byCornerStyleMap.get(cornerStyle)
    }

    withDashStyle(dashStyle: FrameCharDashStyle): FrameCharTraitsIndex {
        if (!this.byDashStyleMap) {
            this.byDashStyleMap = groupTraitsBy(this.chars, t => [t.dashStyle])
        }
        return this.byDashStyleMap.get(dashStyle)
    }

    withNeighbour(rasterNeighbour: IRasterNeighbour): FrameCharTraitsIndex {
        if (!this.byNeighbourMap) {
            this.byNeighbourMap = groupTraitsBy(this.chars, t => [...t.lineStyles.keys()].map(key => key.fx + "/" + key.fy))
        }
        return this.byNeighbourMap.get(rasterNeighbour.fx + "/" + rasterNeighbour.fy)
    }

    withNeighbourCount(n: number): FrameCharTraitsIndex {
        if (!this.byNeighbourCountMap) {
            this.byNeighbourCountMap = groupTraitsBy(this.chars, t => [[...t.lineStyles.keys()].length])
        }
        return this.byNeighbourCountMap.get(n)
    }

    withCompatibleConnectionStyleFor(c: string, dir: IRasterNeighbour): FrameCharTraitsIndex {
        if (!this.byCompatibleConnectionStyleMap) {
            this.byCompatibleConnectionStyleMap = groupTraitsBy(this.chars, tFrom => {
                const charsPerDir = RasterNeighbour.FourDirections.map(dir => {
                    const charsForDir = frameCharTraits.all.filter(tTo => tFrom.isCompatibleConnection(tTo, dir)).map(t => t.char + RasterNeighbour.asString(dir))
                    return charsForDir
                })
                const r = charsPerDir.flat()
                return r
            })
        }
        return this.byCompatibleConnectionStyleMap.get(c + RasterNeighbour.asString(dir)) ?? FrameCharTraitsIndex.empty
    }
}



class TraitsMap {

    byChar = ImmutableMap<string, FrameCharTraits>()
    // FIXME: Remove maps in favor of FrameCharTraitsIndex
    byCornerStyle = new Map<FrameCharCornerStyle, Array<FrameCharTraits>>()
    byDashStyle = new Map<FrameCharDashStyle, Array<FrameCharTraits>>()
    byNeighbour = new Map<RasterNeighbour, Array<FrameCharTraits>>()
    all: FrameCharTraits[] = []
    forAll: FrameCharTraitsIndex

    constructor() {
        // All: \/+-|─━│┃┄┅┆┇┈┉┊┋┌┍┎┏┐┑┒┓└┕┖┗┘┙┚┛├┝┞┟┠┡┢┣┤┥┦┧┨┩┪┫┬┭┮┯┰┱┲┳┴┵┶┷┸┹┺┻┼┽┾┿╀╁╂╃╄╅╆╇╈╉╊╋╌╍╎╏═║╒╓╔╕╖╗╘╙╚╛╜╝╞╟╠╡╢╣╤╥╦╧╨╩╪╫╬╭╮╯╰╱╲╳╴╵╶╷╸╹╺╻╼╽╾╿

        // Top: +|│┃┆┇┊┋└┕┖┗┘┙┚┛├┝┞┟┠┡┢┣┤┥┦┧┨┩┪┫┴┵┶┷┸┹┺┻┼┽┾┿╀╁╂╃╄╅╆╇╈╉╊╋╎╏║╘╙╚╛╜╝╞╟╠╡╢╣╧╨╩╪╫╬╯╰╵╹╽╿
        this.setLineStyle(RasterNeighbour.Top, FrameCharLineStyle.ascii, "+|")
        this.setLineStyle(RasterNeighbour.Top, FrameCharLineStyle.single, "│┆┊└┕┘┙├┝┟┢┤┥┧┪┴┵┶┷┼┽┾┿╁╅╆╈╎╘╛╞╡╧╪╯╰╵╽")
        this.setLineStyle(RasterNeighbour.Top, FrameCharLineStyle.thick, "┃┇┋┖┗┚┛┞┠┡┣┦┨┩┫┸┹┺┻╀╂╃╄╇╉╊╋╏╹╿")
        this.setLineStyle(RasterNeighbour.Top, FrameCharLineStyle.double, "║╙╚╜╝╟╠╢╣╨╩╫╬")

        // Bottom: +|│┃┆┇┊┋┌┍┎┏┐┑┒┓├┝┞┟┠┡┢┣┤┥┦┧┨┩┪┫┬┭┮┯┰┱┲┳┼┽┾┿╀╁╂╃╄╅╆╇╈╈╈╉╊╋╎╏║╒╓╔╕╖╗╞╟╠╡╢╣╤╥╦╪╫╬╭╮╷╻╽╿
        this.setLineStyle(RasterNeighbour.Bottom, FrameCharLineStyle.ascii, "+|")
        this.setLineStyle(RasterNeighbour.Bottom, FrameCharLineStyle.single, "│┆┊┌┍┐┑├┝┞┡┤┥┦┩┬┭┮┯┼┽┾┿╀╃╄╇╎╒╕╞╡╤╪╭╮╷╿")
        this.setLineStyle(RasterNeighbour.Bottom, FrameCharLineStyle.thick, "┃┇┋┎┏┒┓┟┠┢┣┧┨┪┫┰┱┲┳╁╂╅╆╈╈╈╉╊╋╏╻╽")
        this.setLineStyle(RasterNeighbour.Bottom, FrameCharLineStyle.double, "║╓╔╖╗╟╠╢╣╥╦╫╬")

        // Left: +-─━┄┅┈┉┐┑┒┓┘┙┚┛┤┥┦┧┨┩┪┫┬┭┮┯┰┱┲┳┴┵┶┷┸┹┺┻┼┽┾┿╀╁╂╃╄╅╆╇╈╈╈╉╊╋╌╍═╕╖╗╛╜╝╡╢╣╤╥╦╧╨╩╪╫╬╮╯╴╸╼╾
        this.setLineStyle(RasterNeighbour.Left, FrameCharLineStyle.ascii, "+-")
        this.setLineStyle(RasterNeighbour.Left, FrameCharLineStyle.single, "─┄┈┐┒┘┚┤┦┧┨┬┮┰┲┴┶┸┺┼┾╀╁╂╄╆╊╌╖╜╢╥╨╫╮╯╴╼")
        this.setLineStyle(RasterNeighbour.Left, FrameCharLineStyle.thick, "━┅┉┑┓┙┛┥┩┪┫┭┯┱┳┵┷┹┻┽┿╃╅╇╈╈╈╉╋╍╸╾")
        this.setLineStyle(RasterNeighbour.Left, FrameCharLineStyle.double, "═╕╗╛╝╡╣╤╦╧╩╪╬")

        // Right: +-─━┄┅┈┉┌┍┎┏└┕┖┗├┝┞┟┠┡┢┣┬┭┮┯┰┱┲┳┴┵┶┷┸┹┺┻┼┽┾┿╀╁╂╃╄╅╆╇╈╈╈╉╊╋╌╍═╒╓╔╘╙╚╞╟╠╤╥╦╧╨╩╪╫╬╭╰╶╺╼╾
        this.setLineStyle(RasterNeighbour.Right, FrameCharLineStyle.ascii, "+-")
        this.setLineStyle(RasterNeighbour.Right, FrameCharLineStyle.single, "─┄┈┌┎└┖├┞┟┠┬┭┰┱┴┵┸┹┼┽╀╁╂╃╅╉╌╓╙╟╥╨╫╭╰╶╾")
        this.setLineStyle(RasterNeighbour.Right, FrameCharLineStyle.thick, "━┅┉┍┏┕┗┝┡┢┣┮┯┲┳┶┷┺┻┾┿╄╆╇╈╈╈╊╋╍╺╼")
        this.setLineStyle(RasterNeighbour.Right, FrameCharLineStyle.double, "═╒╔╘╚╞╠╤╦╧╩╪╬")

        this.setLineStyle(RasterNeighbour.TopLeft, FrameCharLineStyle.ascii, "\\X");
        this.setLineStyle(RasterNeighbour.TopLeft, FrameCharLineStyle.single, "╲╳");

        this.setLineStyle(RasterNeighbour.TopRight, FrameCharLineStyle.ascii, "/X");
        this.setLineStyle(RasterNeighbour.TopRight, FrameCharLineStyle.single, "╱╳");

        this.setLineStyle(RasterNeighbour.BottomLeft, FrameCharLineStyle.ascii, "/X");
        this.setLineStyle(RasterNeighbour.BottomLeft, FrameCharLineStyle.single, "╱╳");

        this.setLineStyle(RasterNeighbour.BottomRight, FrameCharLineStyle.ascii, "\\X");
        this.setLineStyle(RasterNeighbour.BottomRight, FrameCharLineStyle.single, "╲╳");

        this.setDashStyle(FrameCharDashStyle.regular, "\\/+-|─━│┃═║╱╲╳")
        this.setDashStyle(FrameCharDashStyle.double, "╌╍╎╏")
        this.setDashStyle(FrameCharDashStyle.triple, "┄┅┆┇")
        this.setDashStyle(FrameCharDashStyle.quad, "┈┉┊┋")

        this.setCornerStyle(FrameCharCornerStyle.ascii, "+")
        this.setCornerStyle(FrameCharCornerStyle.regular, "┌┍┎┏┐┑┒┓└┕┖┗┘┙┚┛╒╓╔╕╖╗╘╙╚╛╜╝")
        this.setCornerStyle(FrameCharCornerStyle.rounded, "╭╮╯╰")

        for (const c of this.byChar.keySeq()) {
            const ct = this.byChar.get(c) as FrameCharTraits

            if (!ct.cornerStyle) {
                ct.cornerStyle = FrameCharCornerStyle.undefined
            }
            if (!this.byCornerStyle.has(ct.cornerStyle)) {
                this.byCornerStyle.set(ct.cornerStyle, new Array<FrameCharTraits>())
            }
            this.byCornerStyle.get(ct.cornerStyle)?.push(ct)

            if (!ct.dashStyle) {
                ct.dashStyle = FrameCharDashStyle.undefined
            }
            if (!this.byDashStyle.has(ct.dashStyle)) {
                this.byDashStyle.set(ct.dashStyle, new Array<FrameCharTraits>())
            }
            this.byDashStyle.get(ct.dashStyle)?.push(ct)
            this.all.push(ct)
        }
        this.forAll = new FrameCharTraitsIndex()
        this.forAll.chars = this.all
    }

    getByChar(c: string): FrameCharTraits {
        return this.byChar.has(c) ? this.byChar.get(c) as FrameCharTraits : this.makeEmptyTraits(c);
    }

    private makeEmptyTraits(c: string): FrameCharTraits {
        const t = new FrameCharTraits()
        t.char = c
        return t
    }

    private setLineStyle(direction: IRasterNeighbour, style: FrameCharLineStyle, applicableChars: string) {
        for (const c of applicableChars) {
            if (!this.byChar.has(c)) {
                this.byChar = this.byChar.set(c, this.makeEmptyTraits(c))
            }
            const t = this.getByChar(c)
            t.lineStyles.set(direction, style)
            t.lineStyleList.push([direction, style])
        }
    }

    private setDashStyle(style: FrameCharDashStyle, applicableChars: string) {
        for (const c of applicableChars) {
            if (!this.byChar.has(c)) {
                this.byChar = this.byChar.set(c, this.makeEmptyTraits(c))
            }
            this.getByChar(c).dashStyle = style
        }
    }

    private setCornerStyle(style: FrameCharCornerStyle, applicableChars: string) {
        for (const c of applicableChars) {
            if (!this.byChar.has(c)) {
                this.byChar = this.byChar.set(c, this.makeEmptyTraits(c))
            }
            this.getByChar(c).cornerStyle = style
        }
    }
}

export const frameCharTraits = new TraitsMap()
