import { IRaster, IRasterPointItem, Raster } from "../drawing/raster";
import { IRasterPoint, RasterPoint } from "../geometry/raster-point";

export const SelectShapeUtil = {
    selectShapeAtPoint: function(raster: IRaster<string>, pos: IRasterPoint): IRaster<boolean> {
        const nextCells = [pos]
        const outFillMap = new Map<string, boolean>()
        while (nextCells.length > 0) {
            const p = nextCells.pop()
            for (const nextP of [RasterPoint.plusXY(p, -1, 0), RasterPoint.plusXY(p, 1, 0), RasterPoint.plusXY(p, 0, -1), RasterPoint.plusXY(p, 0, 1)]) {
                const nextKey = RasterPoint.toString(nextP)
                if (!outFillMap.has(nextKey) && Raster.getAtPoint(raster, nextP) !== undefined) {
                    nextCells.push(nextP)
                    outFillMap.set(RasterPoint.toString(nextP), true)
                }
            }
        }

        const floodSelectedCells: IRasterPointItem<boolean>[] = [...outFillMap.keys()]
            .map(p => ({point: RasterPoint.fromString(p), value: true}))
        const floodSelectedRaster = Raster.fromCells(floodSelectedCells)
        const floodFilledRaster = SelectShapeUtil.floodFillRaster(floodSelectedRaster)
        
        return floodFilledRaster
    },

    floodFillRaster: function(inRaster: IRaster<boolean>): IRaster<boolean> {
        const bounds = Raster.getBounds(inRaster).getExpandedBy(1, 1, 1, 1)
        const nextCells = [bounds.topLeft]
        const outFillMap = new Map<string, boolean>()
        while (nextCells.length > 0) {
            const p = nextCells.pop()
            for (const nextP of [RasterPoint.plusXY(p, -1, 0), RasterPoint.plusXY(p, 1, 0), RasterPoint.plusXY(p, 0, -1), RasterPoint.plusXY(p, 0, 1)]) {
                if (!outFillMap.has(RasterPoint.toString(nextP)) && !bounds.isPointOutside(nextP) && Raster.getAtPoint(inRaster, nextP) !== true) {
                    nextCells.push(nextP)
                    outFillMap.set(RasterPoint.toString(nextP), true)
                }
            }
        }

        const floodFilledCells: IRasterPointItem<boolean>[] = []
        for (let x = bounds.minX(); x <= bounds.maxX(); ++x) {
            for (let y = bounds.minY(); y <= bounds.maxY(); ++y) {
                if (!outFillMap.has(RasterPoint.toString(RasterPoint.xy(x, y)))) {
                    floodFilledCells.push({ point: { x, y }, value: true })
                }
            }
        }
        const floodFilledRaster = Raster.updateWith(inRaster, Raster.fromCells(floodFilledCells))
        return floodFilledRaster
    }


};