import * as React from 'react'
import './template-picker.scss'
import { useDispatch } from 'react-redux'
import { BaseTemplates } from "../../shape-templates/base-templates"
import { drawingSlice } from '../store/reducers/drawing-reducer'
import { useSelector } from '../store/app-react-redux'
import { EditorD3Scene } from '../character-raster-editor/editor-d3-scene'

export interface TemplatePickerProperties {
    editorD3Scene: EditorD3Scene
  }

export function TemplatePicker(properties: TemplatePickerProperties) {
    const dispatch = useDispatch()
    const selectedStencilId = useSelector(state => state.drawing.present.toolStates.stencil?.selectedStencilId)
    const buttons = BaseTemplates.getTemplates().map((template, index) => (
        <div className="template-button-tile" key={index}>
            <button draggable={true}
                onClick={() => selectTemplateByIndex(dispatch, index)}
                onDragStart={(event) => dragStartTemplate(dispatch, event, index)}
                onDrag={(event) => dragMoveTemplate(dispatch, event, properties.editorD3Scene)}
                onDrop={(event) => dropTemplate(dispatch, event, properties.editorD3Scene)}
                onDragEnd={(event) => dropTemplate(dispatch, event, properties.editorD3Scene)}
                className={selectedStencilId !== index ? 'template-button' : 'template-button template-selected'}>
                <pre className="template-preview-text">{template.templateText}</pre>
            </button>
        </div>
    ))

    return <div className="template-button-container">
        {buttons}
    </div>
}

function selectTemplateByIndex(dispatch: React.Dispatch<any>, index: number) {
    const action = drawingSlice.actions.stencilSelected(index)
    dispatch(action)
}

function dragStartTemplate(dispatch: React.Dispatch<any>, event: React.DragEvent, index: number) {
    event.dataTransfer.dropEffect = 'copy'
    const action = drawingSlice.actions.stencilDragStarted(index)
    dispatch(action)
}

function dropTemplate(dispatch: React.Dispatch<any>, event: React.MouseEvent, scene: EditorD3Scene) {
    const dropPos = scene.clientToCellCoordinates(event.clientX, event.clientY)
    const action = drawingSlice.actions.stencilDropped(dropPos)
    dispatch(action)
}

function dragMoveTemplate(dispatch: React.Dispatch<any>, event: React.MouseEvent, scene: EditorD3Scene) {
    const dragPos = scene.clientToCellCoordinates(event.clientX, event.clientY)
    const action = drawingSlice.actions.stencilDragMove(dragPos)
    dispatch(action)
}


