import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextareaAutosize, Tooltip, Typography, Zoom } from '@material-ui/core'
import { Alert, ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CharRaster } from '../../drawing/raster'
import { Watermarker } from '../../services/watermarker'
import { useSelector } from '../store/app-react-redux'
import { exportSettingsSlice } from '../store/reducers/export-settings-reducer'
import InfoIcon from '@material-ui/icons/Info';
import { DrawingState } from '../store/reducers/drawing-reducer'
import './clipboard-export-dialog.scss'

export function ClipboardExportDialog() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const useNonbreakingSpaces = useSelector(state => state.exportSettings.useNonbreakingSpaces)
    const [expandSettings, setSettingsExpanded] = React.useState<boolean>()
    const [settingsDirty, setSettingsDirty] = React.useState<boolean>(true)
    const exportedText = useSelector(state => getForExportToClipboard(state.drawing.present, { useNonbreakingSpaces: useNonbreakingSpaces }))

    React.useEffect(() => {
        navigator.clipboard.writeText(exportedText)
        setSettingsDirty(false)
    })
    return <Dialog maxWidth="lg" onClose={() => navigate("/")} aria-labelledby="simple-dialog-title" open={true}>
        <DialogTitle id="simple-dialog-title">Drawing Export
        </DialogTitle>
        <DialogContent>
            <Paper elevation={1}>
                <pre className="export-preview-text">{exportedText}</pre>
            </Paper>
            <Accordion expanded={expandSettings} onChange={(e, v) => setSettingsExpanded(v)}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Settings <span className="settings-summary">({useNonbreakingSpaces ? "non-breaking spaces" : "regular spaces"})</span></Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <ToggleButtonGroup size="small"
                        value={useNonbreakingSpaces}
                        exclusive
                        onChange={(e, v) => {
                            if (v !== null) {
                                setSettingsDirty(true)
                                dispatch(exportSettingsSlice.actions.useNonbreakingSpaces(v))
                            }
                        }
                        }
                        aria-label="indent character">
                        <ToggleButton value={false}>
                            Regular Spaces
                        </ToggleButton>
                        <ToggleButton value={true}>
                            Non-breaking Spaces
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Tooltip placement='top' title="Choose between the good old space character or special non-breaking spaces that avoid unintended linebreaks in emails, but can look odd in code editors.">
                        <InfoIcon />
                    </Tooltip>
                </AccordionDetails>
            </Accordion>
            <br/>
            {settingsDirty ? "" :
                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                    <Alert severity="success">Copied drawing to clipboard!</Alert>
                </Zoom>}
        </DialogContent >
        <DialogActions>
            {settingsDirty ? <Button variant="contained" color="primary" onClick={() => {
                navigator.clipboard.writeText(exportedText)
                setSettingsDirty(false)
            }}>Copy to Clipboard</Button> : ""}
        </DialogActions>
    </Dialog >
}


function getForExportToClipboard(state: DrawingState, props: { useNonbreakingSpaces: boolean }): string {
    const characterRaster = state.characterRaster
    const drawingAsString = CharRaster.getAsString(characterRaster, props.useNonbreakingSpaces)
    const watermarker = new Watermarker(drawingAsString)
    if (!watermarker.hasSomeKindOfWatermark()) {
        watermarker.addWatermark()
    }
    return watermarker.getContent()
}

