/* eslint-disable unicorn/no-null */
import * as d3 from 'd3'
import * as React from 'react'
import Hotkeys from 'react-hot-keys';
import { useMemo } from 'react'
import { DrawingController } from '../../editor-model/drawing-controller'
import { HandleBar } from './handle-bar/handle-bar'

import styles from './d3-editor.module.css'
import { RasterBox } from '../../geometry/raster-box'
import { IRasterPoint, RasterPoint } from '../../geometry/raster-point'
import { useSelector } from '../../app/store/app-react-redux'
import { drawingSlice, DrawingState, makeRasterBoundsSelector } from '../store/reducers/drawing-reducer'
import { IRaster, IRasterPointItem, Raster } from '../../drawing/raster'
import { IHandleModel } from '../../editing-tools/handle-model'
import { EditorD3Handle } from './editor-d3-handle'
import { EditorD3Scene } from './editor-d3-scene'
import { createSelector } from 'reselect'
import { getEditingToolCollection } from '../../editing-tools/editing-tool-collection'
import { ToolInputModel } from '../../editing-tools/tool-model/tool-model'
import { useDispatch } from 'react-redux'
import { pasteText, undo } from './drawing-actions';
import useSize from '@react-hook/size';
import { getEditorCanvasHelpers } from '../store/reducers/editor-canvas-reducer';
import { TextEditingTool } from '../../editing-tools/text-editing-tool';

export interface ID3EditorProperties {
  editorD3Scene: EditorD3Scene
}

function getCanvasSizeForBounds(bounds: RasterBox, editorD3Scene: EditorD3Scene) {
  const canvasWidth = editorD3Scene.scaleX(bounds.maxX()) - editorD3Scene.scaleX(bounds.minX())
  const canvasHeight = editorD3Scene.scaleY(bounds.maxY()) - editorD3Scene.scaleY(bounds.minY())

  return { width: canvasWidth, height: canvasHeight }
}

function getCellBackgroundColor(ci: IRasterPointItem<string>, selectedCells: IRaster<boolean>): string {
  const isSelected = Raster.getAtPoint(selectedCells, ci.point)
  return isSelected ? "#44448844" : "#88888811";
}

function getHandleTransform(handle: IHandleModel, cellWidth: number, cellHeight: number, scaleX: ((number) => number), scaleY: ((number) => number)) {
  const transform = "translate(" +
    (scaleX(handle.pos.x) + 0.5 * (1 + handle.anchor.fx) * cellWidth) + "," +
    (scaleY(handle.pos.y) + 0.5 * (1 + handle.anchor.fy) * cellHeight) + ") " +
    "rotate(" + EditorD3Handle.getHandleRotation(handle) + ")"
  return transform
}

export function D3EditorComponent(properties: ID3EditorProperties) {
  // For giving d3 access to the svg dom node - See https://reactjs.org/docs/refs-and-the-dom.html
  const d3RootReference = React.createRef()
  const sizeTarget = React.useRef(null)
  const [width, height] = useSize(sizeTarget)

  const dispatch = useDispatch()
  const editorD3Scene = properties.editorD3Scene
  React.useEffect(() => {
    editorD3Scene.updateD3Svg(d3RootReference.current)
  }, [])
  const selectCanvasBounds = useMemo(makeRasterBoundsSelector, [])
  const canvasBounds = useSelector(state => selectCanvasBounds(state.drawing.present), RasterBox.equals)
  const canvasSize = getCanvasSizeForBounds(canvasBounds, editorD3Scene)

  const selectEffectiveStateAfterDrag = useMemo(() => createSelector(
    [(s: DrawingState) => s], // FIXME: Does this make sense?
    (s) => getEffectiveStateConsideringOngoingDrag(s)), [])

  const drawingState = useSelector(state => state.drawing.present)
  const activeTool = getEditingToolCollection().getActiveTool(drawingState)

  const style = { minWidth: canvasSize.width, minHeight: canvasSize.height, cursor: activeTool?.cursorName ?? "standard" }
  // FIXME: Ctrl-Z not working properly yet.
  return <div className={styles.d3svgContainer} onDragOver={(event) => handleDragOver(event)} ref={sizeTarget}>
    <Hotkeys keyName="ctrl+z" onKeyDown={() => undo(dispatch)}></Hotkeys>
    <svg className={styles.d3svg} style={style} ref={d3RootReference as React.RefObject<SVGSVGElement>} tabIndex={0}
      onPointerDown={event => editorD3Scene.notifyPointerDown(event)}
      onPointerUp={event => editorD3Scene.notifyPointerUp(event)}
      onPointerMove={event => editorD3Scene.notifyPointerMove(event)}
      // onKeyDown={event => { editorD3Scene.notifyKeyDown(event) }}
      // onKeyUp={event => { editorD3Scene.notifyKeyUp(event) }}
      onKeyDown={event => {
        if (event.code) {
          editorD3Scene.notifyKeyDown(event)
        }
      }}
      onKeyUp={event => {
        if (event.code) {
          editorD3Scene.notifyKeyUp(event)
        }
      }}

    >

      <RasterComponent sizePx={{ x: width, y: height }} />
      <ToolBottomLayerComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <CellItemsComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <CursorComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <CollaborationPointersComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <PointerComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <DragDecorationComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <HandlesComponent scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
      <HandleBar d3Scene={editorD3Scene} stateAfterDragSelector={selectEffectiveStateAfterDrag} />
    </svg>
  </div>;
}

function handleDragOver(event: React.DragEvent) {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'copy'
}



function RasterComponent(properties: { sizePx: IRasterPoint }) {
  const canvas = useSelector(state => state.canvas)
  const canvasHelper = useSelector(state => getEditorCanvasHelpers(state.canvas))

  return <g>
    <defs>
      <linearGradient id="myGradientH" gradientTransform="rotate(0)">
        <stop offset="0%" stopColor="white" />
        <stop offset="10%" stopColor="#EEEEEE" />
        <stop offset="90%" stopColor="#EEEEEE" />
        <stop offset="100%" stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient id="myGradientV" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="white" />
        <stop offset="5%" stopColor="#FFFFFF00" />
        <stop offset="95%" stopColor="#FFFFFF00" />
        <stop offset="100%" stopColor="#BBBBBB" />
      </linearGradient>
      <linearGradient id="myOffLimitsGradient" gradientTransform="rotate(40)">
        <stop offset="5%" stopColor="white" />
        <stop offset="100%" stopColor="#F9F9F9" />
      </linearGradient>
    </defs>
    <pattern id="rasterPattern" x={0} y={0} width={canvas.pxPerCellX} height={canvas.pxPerCellY} patternUnits="userSpaceOnUse">
      <rect x={0} y={0} width={canvas.pxPerCellX} height={canvas.pxPerCellY} fill="url(#myGradientH)"></rect>
      <rect x={0} y={0} width={canvas.pxPerCellX} height={canvas.pxPerCellY} fill="url(#myGradientV)"></rect>
    </pattern>
    <pattern id="offLimitsRasterPattern" x={0} y={0} width={canvas.pxPerCellX} height={canvas.pxPerCellY} patternUnits="userSpaceOnUse">
      <rect x={0} y={0} width={canvas.pxPerCellX} height={canvas.pxPerCellY} fill="url(#myOffLimitsGradient)"></rect>
    </pattern>

    <rect x={canvasHelper.cellToElementX(0)} y={canvasHelper.cellToElementY(0)}
      width={properties.sizePx.x - canvas.pxPerCellX * canvas.viewportOriginCell.x}
      height={properties.sizePx.y - canvas.pxPerCellY * canvas.viewportOriginCell.y} fill="url(#rasterPattern)" />
    <rect
      width={canvas.viewportOriginCell.x * canvas.pxPerCellX}
      height={properties.sizePx.y} fill="url(#offLimitsRasterPattern)" />
    <rect x={canvasHelper.cellToElementX(0)} y={0}
      width={properties.sizePx.x - canvas.pxPerCellX * canvas.viewportOriginCell.x}
      height={canvas.viewportOriginCell.y * canvas.pxPerCellY} fill="url(#offLimitsRasterPattern)" />
    <line x1={canvasHelper.cellToElementX(0)} y1={canvasHelper.cellToElementY(0)}
      x2={properties.sizePx.x} y2={canvasHelper.cellToElementY(0)}
      stroke="lightgray"></line>
    <line x1={canvasHelper.cellToElementX(0)} y1={canvasHelper.cellToElementY(0)}
      x2={canvasHelper.cellToElementX(0)} y2={properties.sizePx.y}
      stroke="lightgray"></line>
  </g>
}



interface CellItemComponentProperties {
  scene: EditorD3Scene
  stateAfterDragSelector: (state: DrawingState) => DrawingState
}


function CellItemsComponent(properties: CellItemComponentProperties) {
  const scene = properties.scene
  const temporaryDrawing = useSelector(state => properties.stateAfterDragSelector(state.drawing.present))
  const canvas = useSelector(state => state.canvas)
  const cellItems = Raster.getCells(temporaryDrawing.characterRaster) // FIXME: Performance??
  const selection = temporaryDrawing.selection

  return <g className="cells">
    {
      cellItems.map(ci => {
        return <g key={RasterPoint.toString(ci.point)}
          className="cellitem"
          transform={"translate(" + scene.scaleX(ci.point.x) + "," + scene.scaleY(ci.point.y) + ")"}>
          <rect width={canvas.pxPerCellX} height={canvas.pxPerCellY} fill={getCellBackgroundColor(ci, selection)}></rect>
          <text x={0} y={canvas.pxPerCellY / 2} fontSize={canvas.pxPerCellY} dy=".35em" className="cell-default2">{ci.value}</text>
        </g>
      })
    }
  </g>

}

interface SceneProperties {
  scene: EditorD3Scene,
  stateAfterDragSelector: (state: DrawingState) => DrawingState
}


function getDisplayedHandles(drawingState: DrawingState): IHandleModel[] {
  const toolInputModel: ToolInputModel = {
    drawing: drawingState
  }
  const handler = getEditingToolCollection().getActiveTool(drawingState)?.makeHandler(toolInputModel)
  if (handler) {
    handler.startEditingAt(drawingState.cursorPos)
    const handles = handler.getDisplayedHandles()
    return handles.activeHandles
  } else {
    return []
  }
}

function DragDecorationComponent(properties: SceneProperties) {
  const drawingState = useSelector(state => properties.stateAfterDragSelector(state.drawing.present))
  const activeTool = getEditingToolCollection().getActiveTool(drawingState)

  if (activeTool && drawingState.activeDrag) {
    const toolInputModel: ToolInputModel = {
      drawing: drawingState
    }
    const handler = activeTool.makeHandler(toolInputModel)
    const dragLayer = handler.getSvgDragLayer(properties.scene, drawingState.activeDrag)
    return dragLayer ?? null
  }
  else {
    return null
  }
}

function ToolBottomLayerComponent(properties: SceneProperties) {
  const drawingState = useSelector(state => state.drawing.present)
  const activeTool = getEditingToolCollection().getActiveTool(drawingState)

  if (activeTool) {
    const toolInputModel: ToolInputModel = {
      drawing: drawingState
    }
    const handler = activeTool.makeHandler(toolInputModel)
    handler.startEditingAt(drawingState.cursorPos)
    const bottomLayer = handler.getSvgBottomLayer(properties.scene)
    return bottomLayer ?? null
  }
  else {
    return null
  }
}



function HandlesComponent(properties: SceneProperties) {
  const [dragDetectStartPos, setDragDetectStartPos] = React.useState<IRasterPoint>() // defined as long as a drag is awaited
  const handles = useSelector(state => getDisplayedHandles(state.drawing.present))
  const canvas = useSelector(state => state.canvas)
  const dispatch = useDispatch()
  return <g>
    {
      handles.map(handle => {
        return <g key={handle.id} className={styles.handle} transform={getHandleTransform(handle, canvas.pxPerCellX, canvas.pxPerCellY, properties.scene.scaleX, properties.scene.scaleY)}>
          <path d={EditorD3Handle.buildHandlePath(handle, canvas.pxPerCellX, canvas.pxPerCellY)}
            onPointerDown={(event) => onPointerDown(event, dispatch, handle.id, properties, dragDetectStartPos, setDragDetectStartPos)}
            onPointerUp={(event) => onPointerUp(event, dispatch, handle.id, properties, dragDetectStartPos, setDragDetectStartPos)}
            onPointerMove={(event) => onPointerMove(event, dispatch, handle.id, properties, dragDetectStartPos, setDragDetectStartPos)} />
        </g>
      })
    }
  </g>
}

function onPointerDown(event: React.PointerEvent, dispatch: React.Dispatch<any>, handleId: string, properties: SceneProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint) => void) {
  const clientClickPos = RasterPoint.xy(event.clientX, event.clientY)
  setDragDetectStartPos(clientClickPos)

  event.stopPropagation()
  event.preventDefault()

  event.currentTarget.setPointerCapture(event.pointerId)
  console.log("onPointerDown on handle")
}

function onPointerMove(event: React.PointerEvent, dispatch: React.Dispatch<any>, handleId: string, properties: SceneProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint?) => void) {
  if (dragDetectStartPos) {
    const currentPos = RasterPoint.xy(event.clientX, event.clientY)
    const cellPosCur = properties.scene.clientToCellCoordinates(currentPos.x, currentPos.y)
    const cellPosDrag = properties.scene.clientToCellCoordinates(dragDetectStartPos.x, dragDetectStartPos.y)
    const cellDist = RasterPoint.manhattanDistTo(cellPosCur, cellPosDrag)
    if (cellDist > 0) {
      const action = drawingSlice.actions.startDragOfToolHandle({ handleId: handleId, clickPosF: properties.scene.clientToCellCoordinates(dragDetectStartPos.x, dragDetectStartPos.y) })
      dispatch(action)
      setDragDetectStartPos(null)
    }
  }
  // Note that we do not stop event propagation, so the canvas will handle the event and update the drag state. FIXME: Make this logic work together more cleanly.
}

function onPointerUp(event: React.PointerEvent, dispatch: React.Dispatch<any>, handleId: string, properties: SceneProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint?) => void) {
  event.currentTarget.releasePointerCapture(event.pointerId)

  // Let the canvas handle the event. FIXME: Make this logic less convoluted
  setDragDetectStartPos(null)
}

function CursorComponent(properties: SceneProperties) {
  const clipboardTarget = React.useRef<HTMLDivElement>(null)
  const dispatcher = useDispatch()
  const currentTool = useSelector(state => state.drawing.present.currentlySelectedTool)
  const enableTextInput = currentTool === TextEditingTool.toolName
  React.useEffect(() => {
    if (enableTextInput && document.activeElement !== clipboardTarget.current) {
      clipboardTarget.current.focus()
    }
  })
  const cursorPos = useSelector(state => properties.stateAfterDragSelector(state.drawing.present).cursorPos)
  const cellWidth = useSelector(state => state.canvas.pxPerCellX)
  const cellHeight = useSelector(state => state.canvas.pxPerCellY)

  return <g className="cursor" transform={"translate(" + properties.scene.scaleX(cursorPos.x) + "," + properties.scene.scaleY(cursorPos.y) + ")"}>
    <rect width={cellWidth} height={cellHeight} fill="#88444444" visibility={cursorPos ? "visible" : "hidden"} />
    <foreignObject width="1em" height="1em">
      <div ref={clipboardTarget}
        className="cursorEditable"
        contentEditable={enableTextInput}
        onKeyDown={event => {
            properties.scene.notifyKeyDown(event)
            event.preventDefault()
            event.stopPropagation()
        }}
        onKeyUp={event => {
            properties.scene.notifyKeyUp(event)
            event.preventDefault()
            event.stopPropagation()
        }}
        onInput={(event) => {
          // weirdly, spaces are converted to nbsp in the editable field, so we have to convert it back to spaces.
          for (const c of event.currentTarget.textContent.replace(" ", " ")) {
            const keyboardDownAction = drawingSlice.actions.keyboardEvent({ isPress: true, key: c })
            dispatcher(keyboardDownAction)
            const keyboardUpAction = drawingSlice.actions.keyboardEvent({ isPress: false, key: c })
            dispatcher(keyboardUpAction)
          }
          event.preventDefault()
          clipboardTarget.current.innerHTML = ""
        }}
        onPaste={(event) => {
          event.preventDefault()
          event.stopPropagation()
          pasteText(event.clipboardData.getData("text"), dispatcher)
          clipboardTarget.current.innerHTML = ""
        }}
      ></div>
    </foreignObject>
  </g>
}

function PointerComponent(properties: SceneProperties) {
  const pointerPos = useSelector(state => state.drawing.present.pointerPos)
  const cellWidth = useSelector(state => state.canvas.pxPerCellX)
  const cellHeight = useSelector(state => state.canvas.pxPerCellY)
  return <g className="pointer" transform={"translate(" + properties.scene.scaleX(pointerPos.x) + "," + properties.scene.scaleY(pointerPos.y) + ")"}>
    <rect width={cellWidth} height={cellHeight} fill="#88888844" visibility={pointerPos ? "visible" : "hidden"} />
  </g>
}

function CollaborationPointersComponent(properties: SceneProperties) {
  const participants = useSelector(state => state.collaboration.participants)
  const cellWidth = useSelector(state => state.canvas.pxPerCellX)
  const cellHeight = useSelector(state => state.canvas.pxPerCellY)

  const cursors = participants.filter(p => p.pointerX !== null && p.pointerY !== null)
    .map(p => <g key={p.id} className="pointer" transform={"translate(" + properties.scene.scaleX(p.pointerX) + "," + properties.scene.scaleY(p.pointerY) + ")"}>
      <rect width={cellWidth} height={cellHeight} fill="#88888877" />
      <text>{p.name}</text>
    </g>)
  return <g>{cursors}</g>
}

function getEffectiveStateConsideringOngoingDrag(state: DrawingState): DrawingState {
  let newState = state
  if (newState.activeDrag?.triggered) {
    newState = DrawingController.invokeToolEventHandler(newState, (handler) => handler.handleDrag(newState.activeDrag))
    newState = { ...newState/*, activeDrag: null FIXME: WHY?*/ }
  }
  return newState
}
