import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ExportSettingsState {
    useNonbreakingSpaces: boolean
}

const initialState: ExportSettingsState = {
    useNonbreakingSpaces: false
}


export const exportSettingsSlice = createSlice({
    initialState,
    name: "ExportSettings",
    reducers: {
        useNonbreakingSpaces: (state, action: PayloadAction<boolean>) => {
            state = { ...state, useNonbreakingSpaces: action.payload }
            return state
        }
    }
})
