import { frameCharTraits } from "../../char-helpers/frame-chars";
import { LineInterpolation } from "../../char-helpers/line-interpolation";
import { IRasterNeighbour, RasterNeighbour } from "../../char-helpers/raster-neighbour";
import { IRaster, Raster } from "../../drawing/raster";
import { IRasterPoint, RasterPoint } from "../../geometry/raster-point";


export interface PushMoveState {
    nextId: number
    pushPath: PushCellsStackItem[]
}

export enum Movability {
    Ignore,
    ShouldStay,
    ShouldBeMoved,
    MustBeMoved,
    MustBeFixed
}

/** Algorithm to implement move behaviour where the moved character push away obstacles and shrink/stretch connections.
 *  
 *          ┌──────────┐  label                 
 *        ┌─┴──┐    ┏━━┷━┓    ┌────┐      
 *        │    │    ┃    ┃->  │    │ 
 *        └────┘    ┗━━━┯┛    └─┬──┘      
 *                |     └───────┘         
 *                v
 *          ┌─────────────┐ label
 *        ┌─┴──┐       ┏━━┷━┓ ┌────┐
 *        │    │       ┃    ┃ │    │
 *        └────┘       ┗━━━┯┛ └─┬──┘
 *                         └────┘   
 *
 *  It works without deep knowlege about the shapes, just by tracking adjacent cells
 *  and identifying repeatable character sequences.
 *  
 *  Input:
 *  - Original raster
 *  - List of cell coordinates to move
 *  - List of cell coordinates that must stay fixed
 *  - Move direction
 *  - Move distance
 * 
 *  Output:
 *  - Success state
 *  - List changed cells, for each:
 *  -- Coordinates
 *  -- moved/added/deleted-flag
 *  -- Character
 * 
 *  Core Algorithm: Move by 1 in x:
 *  - add selected cells to Q
 *  - MovedSet = ()
 *  - while not Q empty:
 *      c = Q.pop()
 *      MovedSet.add(c)
 *      if(IsSideattached(c, above(c)) && !MovedSet.Contains)
 *         Q.add(above)
 *      
 * 
 */
export class PushMove {

    nextId: number
    pushPath: PushCellsStackItem[]

    constructor(private inputRaster: IRaster<string>, movedCells: IRasterPoint[], fixedCells: IRasterPoint[], private readonly state: PushMoveState) {
        this.nextId = state.nextId
        this.pushPath = [...state.pushPath]
        const movability = new Map<string, Movability>()
        for (const pos of movedCells) {
            movability.set(RasterPoint.toString(pos), Movability.MustBeMoved)
        }
        for (const pos of fixedCells) {
            movability.set(RasterPoint.toString(pos), Movability.MustBeFixed)
        }

        const pathStart: PushCellsStackItem = {
            pushedCells: Raster.getCells(inputRaster).map(item => {
                return {
                    pos: item.point,
                    char: item.value,
                    id: ++this.nextId,
                    movability: movability.get(RasterPoint.toString(item.point)) ?? Movability.ShouldStay
                }
            }),
            currentOffset: RasterPoint.xy(0, 0)
        }
        this.pushPath.push(pathStart)
    }

    moveTo(nextOffset: IRasterPoint) {

        // Roll back the move stack until the last distance is no more than the current distance
        for (;;) {
            if (this.pushPath.length <= 1) {
                break
            }
            const lastPathItem = this.pushPath[this.pushPath.length - 1]
            const lastDistance = RasterPoint.getLength(lastPathItem.currentOffset)
            const nextDistance = RasterPoint.getLength(nextOffset)
            if (nextDistance < lastDistance) {
                this.pushPath.pop()
            } else {
                break
            }
        }

        const delta = RasterPoint.minus(nextOffset, this.pushPath[this.pushPath.length - 1].currentOffset)
        let dx = delta.x
        let dy = delta.y
        while (dx < 0) {
            ++dx
            this.moveToDir(RasterNeighbour.Left)
        }
        while (dx > 0) {
            --dx
            this.moveToDir(RasterNeighbour.Right)
        }
        while (dy < 0) {
            ++dy
            this.moveToDir(RasterNeighbour.Top)
        }
        while (dy > 0) {
            --dy
            this.moveToDir(RasterNeighbour.Bottom)
        }
    }

    moveToDir(direction: IRasterNeighbour) {
        const lastStackItem = this.pushPath[this.pushPath.length - 1]
        const newStackItem: PushCellsStackItem = {
            currentOffset: RasterPoint.plus(lastStackItem.currentOffset, RasterNeighbour.offset(direction, 1)),
            pushedCells: [...lastStackItem.pushedCells.map(c => { return { ...c } })]
        }
        this.pushPath.push(newStackItem)
        const movabilities = this.determine1StepMovability(newStackItem.pushedCells, direction)
        if (!movabilities) throw new Error("Failed to move")
        newStackItem.pushedCells = this.applyMove(newStackItem.pushedCells, direction, movabilities)
    }

    renderChanges(targetRaster: IRaster<string>): IRaster<string> {
        const lastStackItem = this.pushPath[this.pushPath.length - 1]
        const changedCells = lastStackItem.pushedCells.filter(cell => Raster.getAtPoint(this.inputRaster, cell.pos) !== cell.char)
            .map(cell => ({ point: cell.pos, value: (cell.char && cell.char.length > 0) ? cell.char : undefined }))
        const newRaster = Raster.updateWith(targetRaster, Raster.fromCells(changedCells))
        return newRaster
    }

    determine1StepMovability(pushCells: PushCell[], moveDirection: IRasterNeighbour): [IRasterPoint, Movability][] {
        const cellMap = new Map<string, PushCell>(pushCells.map(c => [RasterPoint.toString(c.pos), c]))
        const movability = new Map(pushCells
            .filter(c => c.movability !== Movability.ShouldStay)
            .map(c => [RasterPoint.toString(c.pos), c.movability]))
        const pendingCellPositions = [...movability.keys()].map(index => RasterPoint.fromString(index))

        while (pendingCellPositions.length > 0) {
            const currentCellPos = pendingCellPositions.pop()

            if (!cellMap.has(RasterPoint.toString(currentCellPos))) {
                cellMap.set(RasterPoint.toString(currentCellPos), { pos: RasterPoint.plusXY(currentCellPos, 0, 0), char: '', id: 0, movability: movability.get(RasterPoint.toString(currentCellPos)) ?? Movability.ShouldStay })
            }

            // If the current cell should stay in place, we do not have to consider its neighbours.
            const movabilityOfCurrent = movability.get(RasterPoint.toString(currentCellPos))
            if (movabilityOfCurrent === Movability.MustBeFixed || movabilityOfCurrent === Movability.ShouldStay) {
                continue;
            }

            const nextCellPos = RasterPoint.plus(currentCellPos, RasterNeighbour.offset(moveDirection, 1))
            const movabilityOfNext = movability.get(RasterPoint.toString(nextCellPos))

            if (movabilityOfNext === Movability.MustBeFixed) {
                return 
            }

            if (!this.canCellCollapse(cellMap, moveDirection, nextCellPos) && movabilityOfNext !== Movability.MustBeMoved) {
                movability.set(RasterPoint.toString(nextCellPos), Movability.MustBeMoved)
                pendingCellPositions.push(nextCellPos)
            }

            if (!this.updateMovabilityOfSideCell(cellMap, currentCellPos, RasterNeighbour.leftTurn90Degrees(moveDirection), movability, pendingCellPositions)) {
                return 
            }

            if (!this.updateMovabilityOfSideCell(cellMap, currentCellPos, RasterNeighbour.rightTurn90Degrees(moveDirection), movability, pendingCellPositions)) {
                return 
            }

            this.updateMovabilityOfPreviousCell(cellMap, currentCellPos, RasterNeighbour.opposite(moveDirection), movability, pendingCellPositions)
        }

        return [...movability.entries()].map(index => [RasterPoint.fromString(index[0]), index[1]])
    }

    applyMove(pushedCells: PushCell[], direction: IRasterNeighbour, movabilities: [IRasterPoint, Movability][]): PushCell[] {
        const cellsBySourcePos = new Map(pushedCells.map(c => [RasterPoint.toString(c.pos), c]))
        const cellsByTargetPos = new Map(pushedCells.map(c => [RasterPoint.toString(c.pos), c]))

        // Determine the cell contents at the original positions - same or interpolated or null
        for (const [pos, movability] of movabilities) {
            const cell = cellsBySourcePos.get(RasterPoint.toString(pos))
            if (!cell) continue
            if (movability === Movability.MustBeMoved || movability === Movability.ShouldBeMoved) {
                const previousChar = cellsBySourcePos.get(RasterPoint.toString(RasterPoint.plus(pos, RasterNeighbour.offset(direction, -1))))?.char
                const interpolatedChar = this.tryInterpolateCharBetween(previousChar, direction, cell.char)
                const interpolatedCell: PushCell = { pos: RasterPoint.plus(cell.pos, { x: 0, y: 0 }), char: interpolatedChar, id: ++this.nextId, movability: Movability.ShouldStay }
                cellsByTargetPos.set(RasterPoint.toString(cell.pos), interpolatedCell)
            } else {
                cellsByTargetPos.set(RasterPoint.toString(cell.pos), cell)
            }
        }

        // Place the moved cells at their target position
        for (const [pos, movability] of movabilities) {
            const cell = cellsBySourcePos.get(RasterPoint.toString(pos))
            if (cell && (movability === Movability.MustBeMoved || movability === Movability.ShouldBeMoved)) {
                cell.pos = RasterPoint.plus(cell.pos, RasterNeighbour.offset(direction, 1))
                cellsByTargetPos.set(RasterPoint.toString(cell.pos), cell)
            }
        }
        return [...cellsByTargetPos.values()]
    }

    updateMovabilityOfSideCell(cellMap: Map<string, PushCell>, currentCellPos: IRasterPoint, sideDirection: IRasterNeighbour, movability: Map<string, Movability>, pendingCellPositions: IRasterPoint[]): boolean {
        const sideCellPos = RasterPoint.plus(currentCellPos, RasterNeighbour.offset(sideDirection, 1))
        const isCellGluedToSide = this.areCellsGluedTogether(cellMap, currentCellPos, sideDirection)
        if (isCellGluedToSide) {
            const movabilityOfSide = movability.get(RasterPoint.toString(sideCellPos))
            if (movabilityOfSide === Movability.MustBeFixed) {
                return false
            }

            if (movabilityOfSide !== Movability.MustBeMoved) {
                movability.set(RasterPoint.toString(sideCellPos), Movability.MustBeMoved)
                pendingCellPositions.push(sideCellPos)
            }
        }
        return true
    }

    updateMovabilityOfPreviousCell(cellMap: Map<string, PushCell>, currentCellPos: IRasterPoint, previousDirection: IRasterNeighbour, movability: Map<string, Movability>, pendingCellPositions: IRasterPoint[]) {
        const char = cellMap.get(RasterPoint.toString(currentCellPos))?.char
        const previousPos = RasterPoint.plus(currentCellPos, RasterNeighbour.offset(previousDirection, 1))
        const previousChar = cellMap.get(RasterPoint.toString(previousPos))?.char
        if (!previousChar || previousChar.length === 0) {
            return
        }

        const movabilityOfCurrent = movability.get(RasterPoint.toString(currentCellPos))
        const movabilityOfPrevious = movability.get(RasterPoint.toString(previousPos))
        if (movabilityOfCurrent === Movability.MustBeMoved || movabilityOfCurrent === Movability.ShouldBeMoved) {
            if (movabilityOfPrevious === Movability.MustBeMoved || movabilityOfPrevious === Movability.ShouldBeMoved) {
                // previous cell will already follow the current cell. We don't change this
            } else if (movabilityOfPrevious !== Movability.MustBeFixed) {
                // previous cell may move, but doesn't have to.
                const interpolatedChar = this.tryInterpolateCharBetween(previousChar, RasterNeighbour.opposite(previousDirection), char)
                if (interpolatedChar != undefined) {
                    if (movabilityOfPrevious !== Movability.ShouldStay) {
                        movability.set(RasterPoint.toString(previousPos), Movability.ShouldStay)
                        pendingCellPositions.push(previousPos)
                    }
                } else {
                    movability.set(RasterPoint.toString(previousPos), Movability.ShouldBeMoved)
                    pendingCellPositions.push(previousPos)
                }
            }
        }
    }

    tryInterpolateCharBetween(fixedFrom: string, toDirection: IRasterNeighbour, to: string): string {
        const fromTraits = frameCharTraits.byChar.get(fixedFrom)
        const toTraits = frameCharTraits.byChar.get(to)
        if (fromTraits && toTraits) {
            const areConnected = fromTraits.connectsTo(toDirection) && toTraits.connectsTo(RasterNeighbour.opposite(toDirection))
            if (!areConnected) {
                return ''
            }
        }

        if (fromTraits && !fromTraits.connectsTo(toDirection)) {
            return ''
        }

        const interpolatedLine = LineInterpolation.tryInterpolateLineCharBetween(fixedFrom, toDirection, to)
        if (interpolatedLine) {
            return interpolatedLine
        }

        return 
    }

    canCellCollapse(cellMap: Map<string, PushCell>, previousMoveDirection: IRasterNeighbour, cellPos: IRasterPoint): boolean {
        const char = cellMap.get(RasterPoint.toString(cellPos))?.char
        const previousChar = cellMap.get(RasterPoint.toString(RasterPoint.plus(cellPos, RasterNeighbour.offset(previousMoveDirection, -1))))?.char
        const nextChar = cellMap.get(RasterPoint.toString(RasterPoint.plus(cellPos, RasterNeighbour.offset(previousMoveDirection, 1))))?.char

        // two subsequent empty/null character can always be collapsed
        if (!char && !nextChar) {
            return true
        }

        // If interpolation of the neighbour characters result it the actual character itself, we can safely collapse it
        const interpolatedChar = this.tryInterpolateCharBetween(previousChar, previousMoveDirection, nextChar)
        if (interpolatedChar && interpolatedChar === char) {
            return true
        }
        return false
    }

    areCellsGluedTogether(cellMap: Map<string, PushCell>, cellPos: IRasterPoint, neighbourDirection: IRasterNeighbour): boolean {
        const char = cellMap.get(RasterPoint.toString(cellPos))?.char
        const neighbourChar = cellMap.get(RasterPoint.toString(RasterPoint.plus(cellPos, RasterNeighbour.offset(neighbourDirection, 1))))?.char
        if (char && neighbourChar && char.length > 0 && neighbourChar.length > 0) {
            const charTraits = frameCharTraits.byChar.get(char)
            const neighbourCharTraits = frameCharTraits.byChar.get(neighbourChar)
            const charConnectsToNeighbour = charTraits ? charTraits.connectsTo(neighbourDirection) : true
            const neighbourConnectsToChar = neighbourCharTraits ? neighbourCharTraits.connectsTo(RasterNeighbour.opposite(neighbourDirection)) : true
            return charConnectsToNeighbour && neighbourConnectsToChar
        }
        return false
    }
}

export interface PushCellsStackItem {
    pushedCells: PushCell[]
    currentOffset: IRasterPoint
}

export interface PushCell {
    pos: IRasterPoint
    char: string
    id: number
    movability: Movability
}
