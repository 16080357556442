import { RasterPoint, IRasterPoint } from "../geometry/raster-point";
import { IRasterNeighbour, RasterNeighbour } from "./raster-neighbour";
import { frameCharTraits, FrameCharTraits, FrameCharLineStyle, FrameCharCornerStyle } from "./frame-chars";
import { Map } from "immutable";
import { IRaster, Raster } from "../drawing/raster";

/**
 * Help determine what line/box character to use to continue a path at a given position. 
 */
export class LineContinuation {
    private surroundingChars: Map<IRasterNeighbour, string> // immutable map!

    constructor(raster: IRaster<string>, pos: IRasterPoint, private preferredCornerStyle: FrameCharCornerStyle) {
        this.surroundingChars = Map<IRasterNeighbour, string>(
            RasterNeighbour.AllDirections.map(direction => [direction, Raster.getAtPoint(raster, RasterPoint.plusXY(pos, direction.fx, direction.fy))])
        )
    }

    getCenterCharWhenContinuingTo(nextDirection: IRasterNeighbour, nextChar: string, fallbackChar?: string): string {
        // Get all characters that continue towards the direction of nextChar
        // The charcter to consider must be connected to the neighbours, 
        // with one of the neighbours being nextChar
        const updatedSurrounding = this.surroundingChars.set(nextDirection, nextChar)

        const surroundingConnectionState = updatedSurrounding
            .map((neighbourChar, neighbourDirection) => {
                const neighbourTraits = frameCharTraits.getByChar(neighbourChar)
                const isConnectedToCenter = neighbourTraits.connectsTo(RasterNeighbour.opposite(neighbourDirection))
                return isConnectedToCenter
            })

        const surroundingConnectedStyles = updatedSurrounding
            .map((neighbourChar, neighbourDirection) => {
                const neighbourTraits = frameCharTraits.getByChar(neighbourChar)
                const isConnectedToCenter = neighbourTraits.connectsTo(RasterNeighbour.opposite(neighbourDirection))
                const connectedStyle: FrameCharLineStyle = isConnectedToCenter ? neighbourTraits.lineStyles.get(RasterNeighbour.opposite(neighbourDirection)) as FrameCharLineStyle : FrameCharLineStyle.undefined
                return connectedStyle
            })

        const suitableConnectedChars = frameCharTraits.byChar
            .filter(ct => {
                return surroundingConnectionState.every((isNeighbourConnected, neighbourDirection) =>
                ct.connectsTo(neighbourDirection) === isNeighbourConnected)})
            .toList()

        // console.log("suitableconnections: "+JSON.stringify(suitableConnectedChars.toArray()))

        let bestChar: string
        if (suitableConnectedChars.isEmpty()) {
            bestChar = fallbackChar
        } else {
            const bestCharTrait = suitableConnectedChars.min((a, b) => this.compareCharacterTraits(a, b, surroundingConnectedStyles)) as FrameCharTraits
            bestChar = bestCharTrait.char
        }
        return bestChar
    }

    private compareCharacterTraits(a: FrameCharTraits, b: FrameCharTraits, surroundingConnectedStyles: Map<IRasterNeighbour, FrameCharLineStyle>): number {
        const cornerStyleOrder = a.compareWithPreferredCornerStyle(b, this.preferredCornerStyle)
        if(cornerStyleOrder !== 0) {
            return cornerStyleOrder;
        }
        const numberOfMatchingStylesA = this.getNumberOfMatchingStyles(a, surroundingConnectedStyles)
        const numberOfMatchingStylesB = this.getNumberOfMatchingStyles(b, surroundingConnectedStyles)
        return numberOfMatchingStylesA < numberOfMatchingStylesB ? 1 : (numberOfMatchingStylesA > numberOfMatchingStylesB ? -1 : 0)
    }

    private getNumberOfMatchingStyles(ct: FrameCharTraits, surroundingConnectedStyles: Map<IRasterNeighbour, FrameCharLineStyle>): number {
        const numberOfMatchingStyles = surroundingConnectedStyles.map((style, direction) => ct.lineStyles.get(direction) === style ? 1 : 0).toList().reduce((a: number, b: number) => a + b)
        return numberOfMatchingStyles
    }
}