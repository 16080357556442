import React from "react";
import { EditorD3Scene } from "../../app/character-raster-editor/editor-d3-scene";
import { IRasterPoint } from "../../geometry/raster-point";
import styles from './routing-tool-preview.module.css';

export function previewPath(scene: EditorD3Scene, path: IRasterPoint[]) {
    const pathExpression = path.map((p, index) => {
        return index === 0 ? "M " + scene.scaleX(p.x + 0.5) + " " + scene.scaleY(p.y + 0.5) : " L " + scene.scaleX(p.x + 0.5) + " " + scene.scaleY(p.y + 0.5);
    })
    if (pathExpression.length > 0) {
        const pathString = pathExpression.reduce((s1, s2) => s1 + s2)
        return <path d={pathString} className={styles.path}></path>
    } else {
        return
    }
}