import { IRasterPoint, RasterPoint } from '../../geometry/raster-point'
import { IEditingTool } from '../iediting-tool'
import { IRaster, IRasterPointItem, Raster } from '../../drawing/raster'
import { IToolStates, ToolInputModel, ToolOutputModel } from '../tool-model/tool-model'
import { EditingToolEventHandler, IEditingToolEventHandler } from '../iediting-tool-session'
import { IDragState } from '../../app/store/reducers/drawing-reducer'
import { FrameCharCornerStyle, FrameCharDashStyle, FrameCharLineStyle, FrameCharTraits, frameCharTraits } from '../../char-helpers/frame-chars'
import { IRasterNeighbour, RasterNeighbour } from '../../char-helpers/raster-neighbour'
import { previewPath } from './routing-tool-preview'
import { EditorD3Scene } from '../../app/character-raster-editor/editor-d3-scene'
import { SmartDelete } from '../smart-delete'
import { ShortestPathFinder, Side } from './shortest-path-finder'
import { ConnectorRenderer } from './connector-renderer'
import { IInteractionHandleModel, InteractionHandleModel } from '../tool-services/handle-service'
import { HandleSymbol } from '../handle-model'
import { ArrowStyle, getArrowStyleStringForSample } from '../../char-helpers/arrow-sets'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RoutingToolModel {
}

export class RoutingTool implements IEditingTool {
    readonly name = 'routing'
    readonly iconName = 'routing'
    readonly cursorName = "grab"
    readonly handleId = 'routing'
    readonly canHandleDragFromAnywhere = false

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.routing) {
            state = {
                routing: {}
            }
        }
        return state
    }

    makeHandler(input: ToolInputModel): IEditingToolEventHandler {
        return new RoutingToolEventHandler(input)
    }

}

export class RoutingToolEventHandler extends EditingToolEventHandler {

    path: IRasterPoint[]
    raster: IRaster<string>
    cursorPos: IRasterPoint

    // FIXME: Infer from original path. Make sure that styles are consistent.
    lineStyle = FrameCharLineStyle.single
    dashStyle = FrameCharDashStyle.regular
    cornerStyle = FrameCharCornerStyle.regular
    headStyle = ArrowStyle.none
    tailStyle = ArrowStyle.none

    constructor(private readonly inModel: ToolInputModel) {
        super()
        this.raster = inModel.drawing.characterRaster
        this.cursorPos = inModel.drawing.cursorPos
    }

    handleDrag(event: IDragState): boolean {
        if(!event.triggered) {
            return false
        }
        const startPos = RasterPoint.fromString(event.draggedCellKey)
        this.startEditingAt(startPos);
        const dragEnd = RasterPoint.plus(startPos, event.dragOffset)

        // first we delete the current path with the smart delete function to restore overlaps
        const pathRasterItems: IRasterPointItem<boolean>[] = this.path.map(p => ({ point: p, value: true }))
        const pathRaster = Raster.fromCells(pathRasterItems)
        const [cleanedRaster] = SmartDelete.deleteSelectionWithInterpolation(this.raster, pathRaster)

        if(this.path.length > 1) {
            if(event.draggedHandleId === 'connectionStart')
            {
                const targetPos = RasterPoint.plus(this.path[0], event.dragOffset)
                const [newPathRaster, newPath] = this.addUpdatedPath(cleanedRaster, targetPos, targetPos, this.path[this.path.length - 1])
                if(newPath.length > 1) {
                    this.cursorPos = newPath[1]
                }
                this.raster = Raster.updateWith(cleanedRaster, newPathRaster)
            } else if( event.draggedHandleId === 'connectionEnd') {
                const targetPos = RasterPoint.plus(this.path[this.path.length-1], event.dragOffset)
                const [newPathRaster, newPath] = this.addUpdatedPath(cleanedRaster, this.path[0], targetPos, targetPos)
                if(newPath.length > 1) {
                    this.cursorPos = newPath[newPath.length-2]
                }
                this.raster = Raster.updateWith(cleanedRaster, newPathRaster)
            } else {
                const [newPathRaster] = this.addUpdatedPath(cleanedRaster, this.path[0], dragEnd, this.path[this.path.length - 1])
                this.cursorPos = dragEnd
                this.raster = Raster.updateWith(cleanedRaster, newPathRaster)
            }
            return true
        }

        return false
    }

    getDisplayedHandles(): IInteractionHandleModel {
        let handles = InteractionHandleModel.withNoHandles()
        if(this.path?.length >= 2) {
            handles = InteractionHandleModel.withHandle(handles, RasterNeighbour.Center, RasterNeighbour.Center, HandleSymbol.CenteredCircle, this.path[0], 'connectionStart')
            handles = InteractionHandleModel.withHandle(handles, RasterNeighbour.Center, RasterNeighbour.Center, HandleSymbol.CenteredCircle, this.path[this.path.length-1], 'connectionEnd')
        }
        return handles;
     }


    private addUpdatedPath(cleanedRaster: IRaster<string>, from: IRasterPoint, via: IRasterPoint, to: IRasterPoint) : [IRaster<string>, IRasterPoint[]] {
        const fromChar = frameCharTraits.getByChar(Raster.getAtPoint(cleanedRaster, from))
        const toChar = frameCharTraits.getByChar(Raster.getAtPoint(cleanedRaster, to))
        cleanedRaster = Raster.removeAt(cleanedRaster, from.x, from.y)
        cleanedRaster = Raster.removeAt(cleanedRaster, via.x, via.y) // FIXME: Remove if full routing is implemented
        cleanedRaster = Raster.removeAt(cleanedRaster, to.x, to.y)
        
        const spf = new ShortestPathFinder(cleanedRaster, from, via, to)

        spf.setAllowedStartDirections(getAttachDirections(fromChar))
        spf.setAllowedEndDirections(getAttachDirections(toChar))

        const newPath = spf.findShortestPath()
        const renderer = new ConnectorRenderer(this.lineStyle, this.dashStyle, this.cornerStyle, this.headStyle, this.tailStyle)
        const newPathRaster = renderer.renderPathToRaster(newPath, cleanedRaster)
        return [newPathRaster, newPath]
    }

    canStartEditingAt(pos: IRasterPoint): boolean {
        const startChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, pos)
        const startTraits = frameCharTraits.getByChar(startChar)
        return startTraits.getAnyUsedLineStyle() !== FrameCharLineStyle.undefined ? true : false;
    }

    // REFACTOR: Move logic for recognizing connection lines next to conenction line rendering logic.
    tryGetNextCellOnPath(from: IRasterPoint, incomingDirection: IRasterNeighbour): [IRasterPoint, IRasterNeighbour] {
        const fromChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, from)
        const fromTraits = frameCharTraits.getByChar(fromChar)
        const neighbourDirectories = [...fromTraits.lineStyles.keys()]
        if (neighbourDirectories.length === 2) {
            const direction1 = neighbourDirectories[0]
            const direction2 = neighbourDirectories[1]
            const nextDirection = RasterNeighbour.opposite(direction1 === incomingDirection ? direction2 : direction1)
            const nextPos = RasterPoint.plus(from, RasterNeighbour.offset(nextDirection, -1))
            const nextChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, nextPos)
            const nextTraits = frameCharTraits.getByChar(nextChar)
            if (nextTraits.connectsTo(nextDirection) && nextTraits.lineStyles.get(nextDirection) === fromTraits.lineStyles.get(RasterNeighbour.opposite(nextDirection))) {
                return [nextPos, nextDirection]
            } else {
                const headStyleChar = getArrowStyleStringForSample(nextChar, RasterNeighbour.asString(nextDirection))
                if(headStyleChar) {
                    return [nextPos, nextDirection] // fixme: include arrowstyle somehow
                }
            }
        }
        return [undefined, undefined]
    }


    followPath(pos: IRasterPoint, incomingDirection: IRasterNeighbour) {
        const forwardPath: IRasterPoint[] = []
        const startPos = pos
        do {
            const [nextPos, nextIncoming] = this.tryGetNextCellOnPath(pos, incomingDirection)
            if (nextPos) {
                forwardPath.push(nextPos)
                pos = nextPos
                incomingDirection = nextIncoming
            } else {
                break
            }
        } while (!RasterPoint.equals(startPos, pos))
        return forwardPath
    }

    startEditingAt(pos: IRasterPoint): boolean {
        const startChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, pos)
        const startTraits = frameCharTraits.getByChar(startChar)
        const neighbourDirectories = [...startTraits.lineStyles.keys()]
        if (neighbourDirectories.length === 2) {
            const pathForward = this.followPath(pos, neighbourDirectories[0])
            const pathBackward = this.followPath(pos, neighbourDirectories[1])
            pathBackward.reverse()
            this.path = [...pathBackward, pos, ...pathForward]
            return true
        } else {
            this.path = []
            return false
        }

    }

    getResultModel(): ToolOutputModel {
        return {
            ...this.inModel,
            drawing: {
                ...this.inModel.drawing,
                characterRaster: this.raster,
                cursorPos: this.cursorPos,
                toolStates: {
                    ...this.inModel.drawing.toolStates,
                    routing: {
                        ...this.inModel.drawing.toolStates.routing
                    }
                }
            }
        }
    }

    getSvgBottomLayer(scene: EditorD3Scene) {
        return previewPath(scene, this.path)
    }

}

function getAttachDirections(t: FrameCharTraits): Side[] {
    const attachSides : Side[] = []
    if(!t.connectsTo(RasterNeighbour.Left)) {
        attachSides.push(Side.Left)
    }
    if(!t.connectsTo(RasterNeighbour.Top)) {
        attachSides.push(Side.Top)
    }
    if(!t.connectsTo(RasterNeighbour.Bottom)) {
        attachSides.push(Side.Bottom)
    }
    if(!t.connectsTo(RasterNeighbour.Right)) {
        attachSides.push(Side.Right)
    }
    return attachSides
}

