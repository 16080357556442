import * as React from "react";

function SvgPushMoveTool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <g stroke="#000">
        <path fill="none" d="M8.584 10.73h42.64v32.563H8.584z" />
        <path
          d="M28.163 29.235l-.106 9.53 3.068-2.932 16.925 15.71-3.491 3.979 13.011-1.885.212-12.672-3.28 4.713-16.925-14.453 2.962-3.246z"
          strokeWidth={1.128}
        />
      </g>
    </svg>
  );
}

export default SvgPushMoveTool;
