import { AnyAction, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UndoableOptions } from "redux-undo";
import { CharRaster, IRaster, Raster } from "../../../drawing/raster";
import { IActionHandlerStates } from "../../../editing-actions/iaction-handler-states";
import { LineStyleActionProvider } from "../../../editing-actions/line-style-action-provider";
import { SelectTool } from "../../../editing-tools/select-tool";
import { StencilTool } from "../../../editing-tools/stencil-tool";
import { IToolStates } from "../../../editing-tools/tool-model/tool-model";
import { DrawingController } from "../../../editor-model/drawing-controller";
import { PointerEvent } from "../../../editor-model/pointer-event";
import { RasterBox } from "../../../geometry/raster-box";
import { IRasterPoint } from "../../../geometry/raster-point";


export interface ILoadDocumentPayload {
    content: string
}

export interface IUpdateDocumentPayload {
    rasterModifications: IRaster<string>
}

export interface KeyboardEventPayload {
    isPress: boolean
    key: string
}

export interface IDragState {
    startPosF: IRasterPoint
    dragOffset: IRasterPoint
    draggedCellKey: string
    draggedHandleId?: string
    triggered: boolean
}

export interface DrawingState {
    cursorPos: IRasterPoint
    pointerPos: IRasterPoint
    characterRaster: IRaster<string>
    selection: IRaster<boolean>
    currentlySelectedTool: string
    actionHandlerStates: IActionHandlerStates
    toolStates: IToolStates
    activeDrag?: IDragState | null
    lastClickTimeForDoubleClickDetection: number | null
    handledEventCounter: number
}

const initialState: DrawingState = {
    cursorPos: { x: 2, y: 2 },
    pointerPos: { x: 0, y: 0 },
    characterRaster: {},
    selection: {},
    currentlySelectedTool: new SelectTool().name,
    toolStates: {
        select: {
            isDraggingSelection: false,
            selectionMode: "undefined",
            path: []
        }
    },
    actionHandlerStates: {
        lineStyle: LineStyleActionProvider.defaultStyles
    },
    lastClickTimeForDoubleClickDetection: null,
    handledEventCounter: 0
}

export interface PointerDownOnHandlePayload {
    handleId: string,
    clickPosF: IRasterPoint
}

export class rasterSelectors {
    public scaleX?: d3.ScaleLinear<number, number>
    public scaleY?: d3.ScaleLinear<number, number>
}

export const makeRasterBoundsSelector = () => createSelector(
    [(s: DrawingState) => s.characterRaster],
    (characterRaster): RasterBox => {
        return Raster.getBounds(characterRaster) ?? new RasterBox({ x: 0, y: 0 }, { x: 0, y: 0 })
    }
)

export const drawingSlice = createSlice({
    initialState,
    name: "Raster",
    reducers: {
        documentLoaded: (state, action: PayloadAction<ILoadDocumentPayload>) => {
            state.characterRaster = CharRaster.readFromString(action.payload.content)
        },
        documentUpdated: (state, action: PayloadAction<IUpdateDocumentPayload>) => {
            state.characterRaster = Raster.updateWith(state.characterRaster, action.payload.rasterModifications)
        },
        selectedToolChanged: (state, action: PayloadAction<{ newToolName: string }>) => {
            const newState = DrawingController.startEditingWithTool(state, action.payload.newToolName)
            return newState
        },
        // fixmeDerivedState_selectableToolsChanged: (state, action: PayloadAction<{ selectableTools: string[] }>) => {
        //     //state.selectableTools = action.payload.selectableTools;
        // },
        pointerEvent: (state, action: PayloadAction<PointerEvent>) => {
            const newState = DrawingController.handlePointerEvent(state, action.payload)
            return newState
        },
        keyboardEvent: (state, action: PayloadAction<KeyboardEventPayload>) => {
            const newState = DrawingController.handleKeyboardEvent(state, action.payload)
            return newState
        },
        startDragOfHandleBarHandle: (state, action: PayloadAction<PointerDownOnHandlePayload>) => {
            let newState = DrawingController.startEditingWithTool(state, action.payload.handleId)
            newState = DrawingController.handlePointerDownOnToolButtonAction(newState, action.payload.handleId, action.payload.clickPosF)
            newState = DrawingController.startEditingWithTool(newState, newState.currentlySelectedTool)
            return newState
        },
        startDragOfToolHandle: (state, action: PayloadAction<PointerDownOnHandlePayload>) => {
            const newState = DrawingController.handlePointerDownOnToolButtonAction(state, action.payload.handleId, action.payload.clickPosF)
            return newState
        },
        toolOptionClicked: (state, action: PayloadAction<string>) => {
            const newState = DrawingController.handleToolOptionClicked(state, action.payload)
            return newState
        },
        actionOptionClicked: (state, action: PayloadAction<{providerName: string, optionId: string}>) => {
            const newState = DrawingController.handleActionOptionClicked(state, action.payload)
            return newState
        },
        stencilSelected: (state, action: PayloadAction<number>) => {
            const newState = StencilTool.handleStencilSelectedAction(state, action.payload)
            return newState
        },
        stencilDragStarted: (state, action: PayloadAction<number>) => {
            const newState = StencilTool.handleStencilDragStartedAction(state, action.payload)
            return newState
        },
        stencilDragMove: (state, action: PayloadAction<IRasterPoint>) => {
            if (state.toolStates.stencil) {
                state = StencilTool.handleStencilDragMoveAction(state, action.payload)
            }
            return state
        },
        stencilDropped: (state, action: PayloadAction<IRasterPoint>) => {
            if (state.toolStates.stencil) {
                state = StencilTool.handleStencilDroppedAction(state, action.payload)
            }
            return state
        }

    }
})

export const drawingSliceUndoOptions: UndoableOptions<DrawingState, AnyAction> = {
    filter: (action, current, previous) => {
        let includeInHistory = true
        const numberPreviousState = previous.past.length
        // Only include actions that modify the raster into the history.
        if (numberPreviousState > 0 && current.characterRaster === previous.past[numberPreviousState - 1].characterRaster) {
            includeInHistory = false
        }
        // Do not include states during an active drag into the history.
        if (current.activeDrag) {
            includeInHistory = false
        }
        return includeInHistory
    }
}

