import React from "react"
import ReactHotkeys from "react-hot-keys"
import { ActionCreators } from "redux-undo"
import { CharRaster, Raster } from "../../drawing/raster"
import { drawingSlice, IUpdateDocumentPayload } from "../store/reducers/drawing-reducer"
import { globalStore } from "../store/store"

export function undo(dispatch: React.Dispatch<any>) {
    const undoAction = ActionCreators.undo()
    dispatch(undoAction)
}


export function copy() {
    const drawing = globalStore.getState().drawing.present
    const raster = drawing.characterRaster
    const selection = drawing.selection
    const selectedCells = Raster.getCells(selection).filter(s => s.value).map(s => ({ point: s.point, value: Raster.getAtPoint(raster, s.point) }))
    const selectedRaster = Raster.fromCells(selectedCells)
    const rasterBounds = Raster.getBounds(selection)
    const normalizedRaster = Raster.translate(selectedRaster, -rasterBounds.minX(), -rasterBounds.minY())
    const text = CharRaster.getAsString(normalizedRaster)
    navigator.clipboard.writeText(text)
}

export function paste(dispatch: React.Dispatch<any>) {
    if(navigator?.clipboard?.readText) {
        navigator.clipboard.readText().then(text => {
            pasteText(text, dispatch)
        })
    } else {
        window.alert("Pasting text from the toolbar is not supported by your browser. Try using the corresponding keyboard shortcut, such as Ctrl-V")
    }
}

export function pasteText(text: string, dispatch: React.Dispatch<any>) {
    const cursorPos = globalStore.getState().drawing.present.cursorPos
    const pasteRaster = CharRaster.readFromString(text)
    const translatedRaster = Raster.translate(pasteRaster, cursorPos.x, cursorPos.y)
    const update : IUpdateDocumentPayload = {
        rasterModifications: translatedRaster
    }
    const documentUpdateAction = drawingSlice.actions.documentUpdated(update)
    dispatch(documentUpdateAction)
}