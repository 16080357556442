import { combineReducers, configureStore } from "@reduxjs/toolkit";
import undoable from "redux-undo";
import { collaborationSlice } from "./reducers/collaboration-reducer";
import { drawingSlice, drawingSliceUndoOptions } from "./reducers/drawing-reducer";
import { editorCanvasSlice } from "./reducers/editor-canvas-reducer";
import { exportSettingsSlice } from "./reducers/export-settings-reducer";

const appReducer = combineReducers({
    drawing: undoable(drawingSlice.reducer, drawingSliceUndoOptions),
    canvas: editorCanvasSlice.reducer,
    collaboration: collaborationSlice.reducer,
    exportSettings: exportSettingsSlice.reducer,
})

export const makeEmptyStore = () => configureStore({
    reducer: appReducer
})

export const globalStore = makeEmptyStore()

export type AppDispatch = typeof globalStore.dispatch
export type AppState = ReturnType<typeof appReducer>