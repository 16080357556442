import * as React from "react";

function SvgRoutingTool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <path
        d="M10.837 9.453h21.121l.237 37.772 17.166.04v0"
        fill="none"
        stroke="#000"
      />
      <circle cx={50.113} cy={47.027} r={2.136} />
      <circle cx={10.165} cy={9.493} r={2.136} />
    </svg>
  );
}

export default SvgRoutingTool;
