import { IRasterPoint } from "../../geometry/raster-point";
import { IHandleModel } from "../handle-model";
import { IRasterNeighbour } from "../../char-helpers/raster-neighbour";


export interface IInteractionHandleModel {
    activeHandles: IHandleModel[]
}

export const InteractionHandleModel = {
    withHandle: function (model: IInteractionHandleModel, anchor: IRasterNeighbour, shapeDirection: IRasterNeighbour, symbol: string, pos: IRasterPoint, handleId: string): IInteractionHandleModel {
        model = InteractionHandleModel.withoutHandle(model, handleId)
        const displayHandle: IHandleModel = {
            id: handleId,
            pos,
            anchor,
            shapeDirection,
            symbol
        }
        model = { ...model, activeHandles: [...model.activeHandles, displayHandle] }
        return model
    },

    withoutHandle: function (model: IInteractionHandleModel, handleId: string): IInteractionHandleModel {
        model = { ...model, activeHandles: model.activeHandles.filter(h => h.id !== handleId) }
        return model
    },

    withHandleMovedTo: function (model: IInteractionHandleModel, handleId: string, pos: IRasterPoint): IInteractionHandleModel {
        model = { ...model, activeHandles: model.activeHandles.map(h => h.id === handleId ? { ...h, pos: pos } : h) }
        return model
    },

    withNoHandles: function (): IInteractionHandleModel {
        return {
            activeHandles: []
        }
    }
}


