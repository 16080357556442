import * as React from "react";

function SvgDrawTool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <g stroke="#000">
        <path
          d="M14.302 17.083l.132 5.376 2.509-.872 1.32-2.47z"
          strokeWidth={1.485}
          strokeLinejoin="bevel"
        />
        <path
          d="M11.512 16.457c-.569-1.713-.777-3.798-.61-5.748.082-.95 1.958-3.187 2.436-3.718.476-.529.934-1.326 1.523-1.522.969-.322 2.386-.836 3.349-1.014.403-.074.811 0 1.217 0 3.276 0 6.47.908 9.438 3.38.312.26.62.535.914.846.838.889.71 2.043.71 3.55 0 .225-.05.467 0 .676.128.534.556 2.047.812 2.366.908 1.135.43.442 1.116 1.015.219.181.393.486.61.676 1.056.926 1.88 1.238 3.044 1.352.725.071 1.986.296 2.638 0 .249-.113.468-.365.71-.507.82-.478 1.73-.814 2.538-1.352.28-.188.53-.494.812-.677.827-.536 1.694-.884 2.537-1.352 1.34-.744 4.366-2.195 5.784-1.014.673.56 1.147 1.651 1.32 2.874.078.55.03 1.136.101 1.69.032.246.178.427.203.676.106 1.062.145 2.14.203 3.212.089 1.623-.256 4.26.102 5.747.159.664.639 1.508.812 1.86.428.871 1.204.844 1.826.844"
          fill="none"
          strokeWidth={1.404}
        />
        <path
          d="M14.302 17.083l.667 12.061 3.825-.736 3.826-3.283 2.036-4.22-10.354-3.822"
          fill="none"
          strokeWidth={1.319}
          strokeLinejoin="bevel"
        />
        <path
          d="M14.97 29.144l3.824-.736 3.826-3.283 2.036-4.22 22.951 27.061-.37 4.89-3.332 3.483-3.763.67z"
          strokeWidth={1.319}
          strokeLinejoin="bevel"
        />
      </g>
    </svg>
  );
}

export default SvgDrawTool;
