/* eslint-disable unicorn/no-array-reduce */
export class Watermarker {
    constructor(private content: string) { }

    static watermark = "  [http://edit.ax]"
    static testWatermark = "edit.ax"

    removeWatermark() {
        const watermarkIndex = this.content.indexOf(Watermarker.watermark)
        if (watermarkIndex >= 0) {
            const afterWatermark = this.content.slice(watermarkIndex + Watermarker.watermark.length)
            if (afterWatermark.trim().length === 0) {
                const cleanedContent = this.content.slice(0, Math.max(0, watermarkIndex)) + afterWatermark
                this.content = cleanedContent
            }
        }
    }

    hasSomeKindOfWatermark(): boolean {
        return this.content.includes(Watermarker.testWatermark)
    }

    addWatermark() {
        const lines = this.content.split("\n")
        const maxLineLength = lines.map(line => line.length).reduce((p, c) => Math.max(p, c), 0)
        const lastIndex = lines.map(line => line.length > 1).lastIndexOf(true);
        if (lastIndex >= 0) {
            const additionalSpaces = " ".repeat(maxLineLength - lines[lastIndex].length)
            lines[lastIndex] = lines[lastIndex] + additionalSpaces + Watermarker.watermark
        }
        this.content = lines.join("\n")
    }

    getContent() {
        return this.content
    }
}