import { IRasterPoint, RasterPoint } from "../geometry/raster-point";
import { InteractionHandleModel, IInteractionHandleModel } from "./tool-services/handle-service";
import { HandleSymbol } from "./handle-model";
import { RasterNeighbour } from "../char-helpers/raster-neighbour";
import { IRaster, Raster } from "../drawing/raster";
import { IToolStates, ToolInputModel, ToolOutputModel } from "./tool-model/tool-model";
import { IEditingTool } from "./iediting-tool";
import { EditingToolEventHandler, IEditingToolEventHandler } from "./iediting-tool-session";
import { IDragState, KeyboardEventPayload } from "../app/store/reducers/drawing-reducer";
import { PushMove } from "./push-move-tool/push-move";


export interface TextEditingToolModel {
    lineLeft: IRasterPoint // the first character of the line
    lineRight: IRasterPoint // the last character of the line
}

const handleIdLeftLimit = "leftLimit"
const handleIdRightLimit = "rightLimit"

export class TextEditingTool implements IEditingTool {
    static readonly toolName = "text"
    readonly name = TextEditingTool.toolName
    readonly iconName = "text"
    readonly cursorName = "text"
    readonly handleId = "text"
    readonly canHandleDragFromAnywhere = false

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.text?.lineLeft || !state.text?.lineRight) {
            state = {
                text: {
                    lineLeft: undefined,
                    lineRight: undefined
                }
            }
        }
        return state
    }

    makeHandler(inModel: ToolInputModel): IEditingToolEventHandler {
        return new TextEditingToolEventHandler(inModel)
    }

    static shouldActivateOnKeyboardEvent(keyboardEvent: KeyboardEventPayload): boolean {
        if (!keyboardEvent) return false
        if (keyboardEvent.key.length === 1) return true; // Accept characters
        switch (keyboardEvent.key) {
            case "Backspace":
            case "Delete":
            case "ArrowLeft":
            case "ArrowRight":
            case "ArrowUp":
            case "ArrowDown":
                return true
            default:
                return false
        }
    }
}

class TextEditingToolEventHandler extends EditingToolEventHandler {

    lineLeft: IRasterPoint
    lineRight: IRasterPoint
    cursorPos: IRasterPoint
    raster: IRaster<string>

    constructor(private readonly inModel: ToolInputModel) {
        super()
        this.lineLeft = inModel.drawing.toolStates.text?.lineLeft ?? RasterPoint.origin
        this.lineRight = inModel.drawing.toolStates.text?.lineRight ?? RasterPoint.origin
        this.cursorPos = inModel.drawing.cursorPos
        this.raster = inModel.drawing.characterRaster
    }

    getResultModel(): ToolOutputModel {
        return {
            ...this.inModel,
            drawing: {
                ...this.inModel.drawing,
                //displayedHandles: this.handles,
                cursorPos: this.cursorPos,
                characterRaster: this.raster,
                toolStates: {
                    text: {
                        ...this.inModel.drawing.toolStates.text,
                        lineLeft: this.lineLeft,
                        lineRight: this.lineRight
                    }
                }
            }
        }
    }

    getDisplayedHandles(): IInteractionHandleModel {
        const lineLeft = this.lineLeft
        const lineRight = this.lineRight
        let handles = InteractionHandleModel.withNoHandles()
        handles = InteractionHandleModel.withHandle(handles, RasterNeighbour.Left, RasterNeighbour.Right, HandleSymbol.Limit, lineLeft, handleIdLeftLimit)
        handles = InteractionHandleModel.withHandle(handles, RasterNeighbour.Right, RasterNeighbour.Left, HandleSymbol.Limit, lineRight, handleIdRightLimit)
        return handles
    }

    handleKeyEvent(event: KeyboardEventPayload): boolean {
        const keyText = event.key
        if (!keyText) return
        if (!event.isPress) return

        let newPos: IRasterPoint = this.cursorPos
        let handled = true
        if (!this.lineRight || !this.lineLeft) {
            this.lineLeft = this.cursorPos
            this.lineRight = RasterPoint.plus(this.cursorPos, { x: -1, y: 0 })
        }

        if (keyText.length === 1) {
            this.pushSurroundingOnInsertChar()

            for (let x = this.lineRight.x; x >= this.cursorPos.x; --x) {
                const c = Raster.getAt(this.raster, x, this.cursorPos.y)
                this.raster = c ? Raster.setAt(this.raster, x + 1, this.cursorPos.y, c) : Raster.removeAt(this.raster, x + 1, this.cursorPos.y);
            }

            this.raster = keyText[0] !== " " ? Raster.setAtPoint(this.raster, this.cursorPos, keyText[0]) : Raster.removeAt(this.raster, this.cursorPos.x, this.cursorPos.y);
            const rightChar = Raster.getAtPoint(this.raster, RasterPoint.plus(this.lineRight, { x: 1, y: 0 }))
            if (rightChar && !/^\s$/.test(rightChar) && rightChar !== "") {
                this.lineRight = RasterPoint.plus(this.lineRight, { x: 1, y: 0 })
            }
            newPos = RasterPoint.plus(this.cursorPos, { x: 1, y: 0 })
            if (newPos.x - 1 > this.lineRight.x) {
                this.lineRight = { x: newPos.x - 1, y: this.lineRight.y }
            }
            if (newPos.x < this.lineLeft.x) {
                this.lineLeft = { x: newPos.x, y: this.lineLeft.y }
            }
            console.log("newPos.x='" + this.cursorPos.x + "' " + this.lineRight.x)
        } else switch (keyText) {
            case "ArrowLeft": {
                newPos = RasterPoint.plus(this.cursorPos, { x: -1, y: 0 })

                break;
            }
            case "ArrowRight": {
                newPos = RasterPoint.plus(this.cursorPos, { x: 1, y: 0 })

                break;
            }
            case "ArrowDown": {
                newPos = RasterPoint.plus(this.cursorPos, { x: 0, y: 1 })
                const newRange = this.getStartEndOfLineAt(newPos) ?? [newPos, RasterPoint.plus(newPos, { x: -1, y: 0 })]
                this.lineLeft = newRange[0]
                this.lineRight = newRange[1]

                break;
            }
            case "ArrowUp": {
                newPos = RasterPoint.plus(this.cursorPos, { x: 0, y: -1 })
                const newRange = this.getStartEndOfLineAt(newPos) ?? [newPos, RasterPoint.plus(newPos, { x: -1, y: 0 })]
                this.lineLeft = newRange[0]
                this.lineRight = newRange[1]

                break;
            }
            case "Backspace": {
                if (this.cursorPos.x > this.lineLeft.x) {
                    newPos = RasterPoint.plus(this.cursorPos, { x: -1, y: 0 })
                    for (let x = this.cursorPos.x; x <= this.lineRight.x; ++x) {
                        const c = Raster.getAtPoint(this.raster, { x, y: this.cursorPos.y })
                        this.raster = c ? Raster.setAtPoint(this.raster, { x: x - 1, y: this.cursorPos.y }, c) : Raster.removeAt(this.raster, x - 1, this.cursorPos.y);
                    }
                    this.raster = Raster.removeAt(this.raster, this.lineRight.x, this.lineRight.y)
                    this.lineRight = RasterPoint.plus(this.lineRight, { x: -1, y: 0 })
                    this.lineLeft = { x: Math.min(this.lineRight.x + 1, this.lineLeft.x), y: this.lineLeft.y }
                }

                break;
            }
            case "Delete": {
                if (this.cursorPos.x <= this.lineRight.x) {
                    for (let x = this.cursorPos.x; x < this.lineRight.x; ++x) {
                        const c = Raster.getAtPoint(this.raster, { x: x + 1, y: this.cursorPos.y })
                        this.raster = c ? Raster.setAtPoint(this.raster, { x, y: this.cursorPos.y }, c) : Raster.removeAt(this.raster, x, this.cursorPos.y);
                    }
                    this.raster = Raster.removeAt(this.raster, this.lineRight.x, this.lineRight.y)
                    this.lineRight = RasterPoint.plus(this.lineRight, { x: -1, y: 0 })
                    this.lineLeft = { x: Math.min(this.lineRight.x + 1, this.lineLeft.x), y: this.lineLeft.y }
                }

                break;
            }
            case "Enter": {
                this.pushSurroundingOnNewline()
                newPos = RasterPoint.plus(this.lineLeft, { x: 0, y: 1 })
                const newRange = this.getStartEndOfLineAt(newPos) ?? [newPos, RasterPoint.plus(newPos, { x: -1, y: 0 })]
                this.lineLeft = newRange[0]
                this.lineRight = newRange[1]

                break;
            }
            case "End": {
                newPos = RasterPoint.plus(this.lineRight, { x: 1, y: 0 })

                break;
            }
            case "Home": {
                newPos = this.lineLeft

                break;
            }
            default: {
                console.log("key: " + event.key)
                handled = false
            }
        }

        if (handled) {
            this.cursorPos = newPos
            this.adjustLineEndsToSurroundCursor();
        }


        return handled
    }

    pushSurroundingOnInsertChar() {
        try {
            const pushMove = new PushMove(this.raster, [RasterPoint.plusXY(this.lineRight, +1, 0)], [RasterPoint.plusXY(this.lineRight, 0, 0)], { nextId: 0, pushPath: [] })
            pushMove.moveTo(RasterPoint.xy(1, 0))
            this.raster = pushMove.renderChanges(this.raster)
        } catch {
            console.error("Error pushing content on insert char")
        }
    }

    pushSurroundingOnNewline() {
        // eslint-disable-next-line unicorn/new-for-builtins
        const linePosList: IRasterPoint[] = [...Array(this.lineRight.x - this.lineLeft.x + 1).keys()].map(d => RasterPoint.plusXY(this.lineLeft, d, 0))
        let rasterWithoutSourceLine = this.raster
        for (const p of linePosList) {
            rasterWithoutSourceLine = Raster.removeAt(rasterWithoutSourceLine, p.x, p.y)
        }
        const pushMove = new PushMove(rasterWithoutSourceLine, [RasterPoint.plusXY(this.lineLeft, 0, 1)], [this.lineLeft], { nextId: 0, pushPath: [] })
        try {
            pushMove.moveTo(RasterPoint.xy(0, 1))
            this.raster = pushMove.renderChanges(this.raster)
        } catch {
            console.error("Error pushing content on newline")
        }
    }

    handleDrag(event: IDragState): boolean {
        const dragPosX = Math.round(event.startPosF.x) + event.dragOffset.x
        if (event.draggedHandleId === handleIdLeftLimit) {
            this.lineLeft = RasterPoint.xy(dragPosX, this.lineLeft.y)
            return true
        } else if (event.draggedHandleId === handleIdRightLimit) {
            this.lineRight = RasterPoint.xy(dragPosX, this.lineRight.y)
            return true
        } else {
            return false
        }
    }

    canStartEditingAt(pos: IRasterPoint): boolean {
        return true
    }

    startEditingAt(pos: IRasterPoint) {

        const areLimitsAlreadyLeftAndRightOfCursor = this.lineLeft &&
            this.lineRight &&
            pos.y === this.lineLeft.y &&
            pos.x >= this.lineLeft.x &&
            pos.x <= this.lineRight.x + 1
        if (areLimitsAlreadyLeftAndRightOfCursor) {
            return
        }

        const lineRange = this.getStartEndOfLineAt(pos)
        if (lineRange) {
            const [from, to] = lineRange
            this.lineLeft = from
            this.lineRight = to
        } else {
            this.lineLeft = pos
            this.lineRight = RasterPoint.plusXY(pos, -1, 0)
        }
    }

    private adjustLineEndsToSurroundCursor() {
        if (this.lineLeft.y !== this.cursorPos.y || this.lineRight.y !== this.cursorPos.y) {
            this.lineLeft = RasterPoint.xy(this.lineLeft.x, this.cursorPos.y);
            this.lineRight = RasterPoint.xy(this.lineRight.x, this.cursorPos.y);
        }
        if (this.lineLeft.x > this.cursorPos.x) {
            this.lineLeft = RasterPoint.xy(this.cursorPos.x, this.lineLeft.y);
        }
        if (this.lineRight.x < this.cursorPos.x - 1) {
            this.lineRight = RasterPoint.xy(this.cursorPos.x - 1, this.lineRight.y);
        }
    }

    private getStartEndOfLineAt(pos: IRasterPoint): [IRasterPoint, IRasterPoint] {

        if (this.isEmptyAt(pos) && this.isLanguageCharAt(RasterPoint.plusXY(pos, 1, 0))) {
            pos = RasterPoint.plusXY(pos, 1, 0)
        }

        if (this.isLanguageCharAt(pos)) {
            let p = pos
            while (this.isLanguageCharAt(p)) {
                p = RasterPoint.plusXY(p, -1, 0)
                if (this.isEmptyAt(p) && this.isLanguageCharAt(RasterPoint.plusXY(p, -1, 0))) {
                    p = RasterPoint.plusXY(p, -1, 0)
                    continue
                }
            }
            p = RasterPoint.plus(p, { x: 1, y: 0 })
            const left = p
            while (this.isLanguageCharAt(p)) {
                p = RasterPoint.plusXY(p, 1, 0)
                // include spaces between characters
                if (this.isEmptyAt(p) && this.isLanguageCharAt(RasterPoint.plusXY(p, 1, 0))) {
                    p = RasterPoint.plusXY(p, 1, 0)
                    continue
                }
            }
            const right = RasterPoint.plus(p, { x: -1, y: 0 })
            return [left, right]
        } else {
            return
        }
    }

    private isEmptyAt(pos: IRasterPoint) {
        const c = Raster.getAtPoint(this.raster, pos)
        return c == undefined
    }

    private isLanguageCharAt(pos: IRasterPoint) {
        const c = Raster.getAtPoint(this.raster, pos)
        return (/^[\d !,.:A-Z\\a-z]$/.test(c))
    }


}