import { getEditingToolCollection } from "../editing-tools/editing-tool-collection";
import { PointerEvent, PointerEventType } from "./pointer-event";
import { DrawingState, KeyboardEventPayload, IDragState } from "../app/store/reducers/drawing-reducer";
import { IEditingToolEventHandler } from "../editing-tools/iediting-tool-session";
import { ToolInputModel } from "../editing-tools/tool-model/tool-model";
import { TextEditingTool } from '../editing-tools/text-editing-tool';
import { IRasterPoint, RasterPoint } from '../geometry/raster-point';
import { SelectTool } from '../editing-tools/select-tool';
import { Raster } from '../drawing/raster';
import { Satellite } from "@material-ui/icons";
import { getEditingActionCollection } from "../editing-actions/editing-action-collection";

export class DrawingController {

    public static invokeToolEventHandler(drawingState: DrawingState, action: (handler: IEditingToolEventHandler) => boolean): DrawingState {

        const toolInputModel: ToolInputModel = {
            drawing: drawingState
        }

        const handler = getEditingToolCollection().getActiveTool(drawingState)?.makeHandler(toolInputModel)
        if (handler && action(handler)) {
            const toolOutputModel = handler.getResultModel()
            return toolOutputModel.drawing
        } else {
            return drawingState
        }
    }


    public static handlePointerEvent(state: DrawingState, event: PointerEvent): DrawingState {
        if (event.type === PointerEventType.down) {
            state = {
                ...state,
                cursorPos: event.pos,
                activeDrag: {
                    draggedCellKey: RasterPoint.toString(event.pos),
                    dragOffset: RasterPoint.origin,
                    startPosF: event.pos,
                    triggered: false
                }
            }
            state = DrawingController.updateToolState(state, (handler) => { handler.initializeDrag(); return true; })
        }

        if (event.type === PointerEventType.move) {
            let activeDrag = state.activeDrag
            if (activeDrag && !activeDrag.triggered && !RasterPoint.equals(RasterPoint.fromString(activeDrag.draggedCellKey), event.pos)) {
                activeDrag = {
                    ...activeDrag,
                    triggered: true
                }
            }

            if (activeDrag) {
                state = {
                    ...state,
                    activeDrag: {
                        ...activeDrag,
                        dragOffset: RasterPoint.minus(event.pos, RasterPoint.xy(activeDrag.startPosF.x, activeDrag.startPosF.y))
                    }
                }
                state = DrawingController.updateToolState(state, (handler) => handler.handleDrag(state.activeDrag as IDragState))
            }
        }

        if (event.type === PointerEventType.up && state.activeDrag) {
            if (RasterPoint.getLength(state.activeDrag.dragOffset) === 0) {
                state = DrawingController.handleClick(state, event.pos)
            }
            state = DrawingController.invokeToolEventHandler(state, (handler) => handler.handleDrag(state.activeDrag as IDragState))
            state = { ...state, activeDrag: undefined }
        }

        return state
    }

    private static updateToolState(state: DrawingState, action: (handler: IEditingToolEventHandler) => boolean): DrawingState {
        const newState = DrawingController.invokeToolEventHandler(state, action)
        return { ...state, toolStates: newState.toolStates }
    }

    public static handlePointerDownOnToolButtonAction(state: DrawingState, handleId: string, clickPosF: IRasterPoint): DrawingState {
        state = {
            ...state,
            activeDrag: {
                draggedCellKey: RasterPoint.toString(state.cursorPos),
                draggedHandleId: handleId,
                dragOffset: RasterPoint.origin,
                startPosF: clickPosF,
                triggered: false
            }
        }
        state = DrawingController.updateToolState(state, (handler) => { handler.initializeDrag(); return true; })
        return state
    }

    static handleToolOptionClicked(state: DrawingState, optionId: string): DrawingState {
        state = DrawingController.invokeToolEventHandler(state, (handler) => handler.handleToolOption(optionId))
        return state
    }

    static handleActionOptionClicked(state: DrawingState, args: { providerName: string, optionId: string }): DrawingState {
        const resultState = getEditingActionCollection().invokeAction(state, args.providerName, args.optionId);
        return resultState
    }

    static handleClick(state: DrawingState, pos: IRasterPoint): DrawingState {
        if (!Raster.getAtPoint(state.selection, pos)) {
            state = {
                ...state,
                selection: Raster.empty<boolean>()
            }
        }
        if (this.canStartEditingWithToolAt(state, state.currentlySelectedTool, pos)) {
            const now = Date.now()
            const doubleClickInterval = 300
            const timeSinceLastClick = now - (state.lastClickTimeForDoubleClickDetection ?? 0)
            console.log("elapsed: " + timeSinceLastClick)
            if (timeSinceLastClick > doubleClickInterval) {
                state = { ...state, lastClickTimeForDoubleClickDetection: now }
                state = this.startEditingWithTool(state, state.currentlySelectedTool)
            } else {
                state = DrawingController.invokeToolEventHandler(state, (handler) => {
                    return handler.handleDoubleClick(state.cursorPos)
                })
            }
        } else if (this.canStartEditingWithToolAt(state, SelectTool.toolName, pos)) {
            state = this.startEditingWithTool(state, SelectTool.toolName)
        }
        return state
    }

    public static canStartEditingWithToolAt(state: DrawingState, toolName: string, pos: IRasterPoint): boolean {
        const tool = getEditingToolCollection().getToolByName(toolName)
        if (tool) {
            state = { ...state, currentlySelectedTool: tool.name }
            state = { ...state, toolStates: tool.getInitializedState(state.toolStates) }
            const toolInputModel: ToolInputModel = { drawing: state }
            const handler = getEditingToolCollection().getActiveTool(state)?.makeHandler(toolInputModel)
            if (handler) {
                const canStartEditing = handler.canStartEditingAt(pos)
                return canStartEditing
            } else {
                return false
            }
        } else {
            return false
        }
    }


    public static startEditingWithTool(state: DrawingState, toolName: string): DrawingState {
        const tool = getEditingToolCollection().getToolByName(toolName)
        if (tool) {
            state = { ...state, currentlySelectedTool: tool.name }
            state = { ...state, toolStates: tool.getInitializedState(state.toolStates) }

            if (state.cursorPos) {
                state = DrawingController.invokeToolEventHandler(state, (handler) => { handler.startEditingAt(state.cursorPos); return true })
            }
        }
        return state
    }

    static handleKeyboardEvent(drawingState: DrawingState, keyboardEvent: KeyboardEventPayload): DrawingState {
        const originalDrawingState = drawingState
        const text = keyboardEvent.key
        const currentTool = getEditingToolCollection().getActiveTool(drawingState)
        let handled = false

        if (!keyboardEvent.isPress) {
            // we only handle keypresses, not releases. Otherwise releases might get handled by another tool than the press.
            return drawingState
        }

        // First let the current tool try to handle the keyboard event
        if (currentTool) {
            drawingState = DrawingController.invokeToolEventHandler(drawingState, (handler) => handler.handleKeyEvent(keyboardEvent))
            if (drawingState !== originalDrawingState) {
                handled = true
            }
        }

        if (drawingState.cursorPos && text && !handled) {
            handled = true
            const textEditingToolCanHandle = TextEditingTool.shouldActivateOnKeyboardEvent(keyboardEvent)

            if (drawingState.currentlySelectedTool !== getEditingToolCollection().textEditingTool.name && textEditingToolCanHandle) {
                drawingState = DrawingController.startEditingWithTool(drawingState, getEditingToolCollection().textEditingTool.name);
                // post event again to have it handled by the now activated text tool
                drawingState = DrawingController.invokeToolEventHandler(drawingState, (handler) => handler.handleKeyEvent(keyboardEvent))
            } else {
                handled = false
            }

            if (handled) {
                drawingState = { ...drawingState, handledEventCounter: drawingState.handledEventCounter += 1 }
            }
        }
        return drawingState
    }


}
