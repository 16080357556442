import { frameCharTraits, FrameCharTraits } from "./frame-chars";
import { IRasterNeighbour, RasterNeighbour } from "./raster-neighbour";


export class LineInterpolation
{
    private static GetLineCharactersByDirection(): Map<RasterNeighbour, FrameCharTraits[]> {
        const map = new Map<RasterNeighbour, FrameCharTraits[]>()
        const charTraits = [...frameCharTraits.byChar.values()]
        for(const direction of [RasterNeighbour.Left, RasterNeighbour.Top, RasterNeighbour.TopLeft, RasterNeighbour.TopRight]) {
            const lineList: FrameCharTraits[] = []
            for(const trait of charTraits) {
                if(trait.connectsExactlyTo([direction, RasterNeighbour.opposite(direction)])) {
                    lineList.push(trait)
                }
            }
            map.set(direction, lineList)
            map.set(RasterNeighbour.opposite(direction), lineList)
        }
        return map
    }

    private static lineCharacterByDirection = LineInterpolation.GetLineCharactersByDirection()

    static tryInterpolateLineCharBetween(from: string, toDirection: IRasterNeighbour, to: string): string | null | undefined {
        const fromTrait = frameCharTraits.byChar.get(from)
        const toTrait = frameCharTraits.byChar.get(to)
        if(!fromTrait || !toTrait) {
            return
        }
        if(!fromTrait.connectsTo(toDirection) || !toTrait.connectsTo(RasterNeighbour.opposite(toDirection))) {
            return
        }

        const fromLineStyle = fromTrait.lineStyles.get(toDirection)
        const toLineStyle = toTrait.lineStyles.get(RasterNeighbour.opposite(toDirection))
        
        const candiates = LineInterpolation.lineCharacterByDirection.get(toDirection)
        if(!candiates) {
            return
        }

        const foundLine = candiates.find(t => t.lineStyles.get(RasterNeighbour.opposite(toDirection)) === fromLineStyle && t.lineStyles.get(toDirection) === toLineStyle)
        return foundLine?.char
    }
}