import * as React from 'react'
import { useState } from 'react';
import styles from './application.module.css';
import './application.css';
import { Provider } from 'react-redux';
import { globalStore } from './app/store/store';
import { TemplatePicker } from './app/template-picker/template-picker';
import { HeaderComponent } from './app/header-component';
import { D3EditorComponent } from './app/character-raster-editor/d3-editor.component';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ClipboardImportDialog } from './app/clipboard-import-dialog/clipboard-import-dialog';
import { PrivacyPolicyDialog } from './app/privacy-policy-page/privacy-policy-page';
import { FooterComponent } from './app/footer-component';
import { JoinSessionDialog } from './app/collaboration-controls';
import { EditorD3Scene } from './app/character-raster-editor/editor-d3-scene';
import { ClipboardExportDialog } from './app/clipboard-export-dialog/clipboard-export-dialog';
import { OptionsOverlayComponent } from './app/options-overlay';
import { Drawer, Hidden } from '@material-ui/core';

function App() {
  const [editorD3Scene] = useState(new EditorD3Scene())


  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = <div className={styles.pickerBox}><TemplatePicker editorD3Scene={editorD3Scene}></TemplatePicker></div>

  return (
    <div className={styles.d3Editor}>
      <Provider store={globalStore}>
        <BrowserRouter>
          <HeaderComponent handleDrawerToggle={handleDrawerToggle}></HeaderComponent>
          <div className={styles.hBox}>
            
            <Hidden smUp implementation="js">
              <Drawer variant="temporary" anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="js">
              {!mobileOpen ? drawer : null}
            </Hidden>

            <div className={styles.vBox}>
              <D3EditorComponent editorD3Scene={editorD3Scene} />
              <div className={styles.optionsOverlay}>
                <OptionsOverlayComponent></OptionsOverlayComponent>
              </div>
              <Routes>
                <Route path="/import" element={<ClipboardImportDialog />} />
                <Route path="/export" element={<ClipboardExportDialog />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyDialog />} />
                <Route path="/" element={<FooterComponent />} />
                <Route path="/join-session/:id" element={<JoinSessionDialog />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
