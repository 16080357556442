import { IRasterNeighbour } from "../char-helpers/raster-neighbour"
import { IRasterPoint } from "../geometry/raster-point"

export class HandleSymbol {
    static readonly Circle = "circle"
    static readonly CenteredCircle = "centeredCircle"
    static readonly Limit = "limit"
    static readonly Square = "square"
}

export interface IHandleModel {
    id: string
    pos: IRasterPoint
    anchor: IRasterNeighbour
    shapeDirection: IRasterNeighbour
    symbol: string
}

