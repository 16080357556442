import { RasterPoint, IRasterPoint } from "../geometry/raster-point"
import { FrameCharLineStyle, FrameCharCornerStyle, FrameCharDashStyle, frameCharTraits } from "../char-helpers/frame-chars"
import { IRaster, IRasterPointItem, Raster } from "../drawing/raster"
import { IRasterNeighbour, RasterNeighbour } from "../char-helpers/raster-neighbour"
import { arrowHeads, ArrowStyle, getArrowForDirection, getArrowStyleStringAndDirForSample } from "../char-helpers/arrow-sets"
import { IEditingActionProvider } from "./editing-action-interfaces";
import { ToolInputModel, ToolOutputModel } from "../editing-tools/tool-model/tool-model"
import { IActionOptions } from "../editing-tools/tool-services/tool-options"
import { DrawingState } from "../app/store/reducers/drawing-reducer"
import { PathDrawingTool } from "../editing-tools/path-drawing-tool"


export interface LineStyleActionModel {
    lineStyle: FrameCharLineStyle
    cornerStyle: FrameCharCornerStyle
    dashStyle: FrameCharDashStyle
    arrowStyle: ArrowStyle
}

export class LineStyleActionProvider implements IEditingActionProvider {

    static getCurrentStyles(drawing: DrawingState): LineStyleActionModel {
        return drawing.actionHandlerStates.lineStyle
    }

    static defaultStyles: LineStyleActionModel = {
        cornerStyle: FrameCharCornerStyle.regular,
        lineStyle: FrameCharLineStyle.single,
        dashStyle: FrameCharDashStyle.regular,
        arrowStyle: ArrowStyle.none
    }

    public static providerName = "LineStyleActionProvider"
    private raster: IRaster<string>
    private cursorPosition: IRasterPoint
    private cornerStyle: FrameCharCornerStyle
    private lineStyle: FrameCharLineStyle
    private dashStyle: FrameCharDashStyle
    private arrowStyle: ArrowStyle

    constructor(private readonly inModel: ToolInputModel) {
        this.raster = inModel.drawing.characterRaster
        this.cursorPosition = inModel.drawing.cursorPos
        const currentStyles = LineStyleActionProvider.getCurrentStyles(inModel.drawing)
        this.cornerStyle = currentStyles.cornerStyle
        this.dashStyle = currentStyles.dashStyle
        this.lineStyle = currentStyles.lineStyle
        this.arrowStyle = currentStyles.arrowStyle
    }


    getOptions(): IActionOptions {
        const linesSelected = this.areLinesSelected()
        const arrowsSelected = this.areArrowsSelected()
        const isDrawingToolActive = this.inModel.drawing.currentlySelectedTool === PathDrawingTool.staticName
        const lineOptions = (linesSelected || isDrawingToolActive) ?
            [{
                options: [
                    {
                        id: "style.single", disabled: false, checked:
                            this.lineStyle === FrameCharLineStyle.single &&
                            this.cornerStyle === FrameCharCornerStyle.regular &&
                            this.dashStyle === FrameCharDashStyle.regular, label: "│"
                    },
                    { id: "style.thick", disabled: false, checked: this.lineStyle === FrameCharLineStyle.thick, label: "┃" },
                    { id: "style.double", disabled: false, checked: this.lineStyle === FrameCharLineStyle.double, label: "║" },
                    { id: "dash.double", disabled: false, checked: this.dashStyle === FrameCharDashStyle.double, label: "╎" },
                    { id: "dash.triple", disabled: false, checked: this.dashStyle === FrameCharDashStyle.triple, label: "┆" },
                    { id: "dash.quad", disabled: false, checked: this.dashStyle === FrameCharDashStyle.quad, label: "┊" },
                    { id: "corner.rounded", disabled: false, checked: this.cornerStyle === FrameCharCornerStyle.rounded, label: "╭╴" },
                ],
                actionHandlerName: LineStyleActionProvider.providerName,
            }] : []

        const arrowOptions = (arrowsSelected || isDrawingToolActive) ?
            [
                {
                    options: [
                        { id: "arrow.none", disabled: false, checked: this.arrowStyle === ArrowStyle.none, label: "-" },
                        { id: "arrow.ascii", disabled: false, checked: this.arrowStyle === ArrowStyle.ascii, label: ">" },
                        { id: "arrow.big", disabled: false, checked: this.arrowStyle === ArrowStyle.big, label: "►" },
                        { id: "arrow.small", disabled: false, checked: this.arrowStyle === ArrowStyle.small, label: "▸" },
                    ],
                    actionHandlerName: LineStyleActionProvider.providerName,
                }
            ] : []

        const options = [...lineOptions, ...arrowOptions]
        return { options }
    }

    areArrowsSelected() {
        const foundIndex = Raster.getCells(this.inModel.drawing.selection).findIndex(item =>
            getArrowStyleStringAndDirForSample(
                Raster.getAtPoint(this.inModel.drawing.characterRaster, item.point))[0] !== null)
        return foundIndex !== -1
    }

    areLinesSelected() {
        const foundIndex = Raster.getCells(this.inModel.drawing.selection).findIndex(item =>
            frameCharTraits.getByChar(
                Raster.getAtPoint(this.inModel.drawing.characterRaster, item.point)).lineStyleList.length > 0)
        return foundIndex !== -1
    }

    handleOption(id: string): boolean {
        switch (id) {
            case "corner.regular":
            case "style.single":
            case "dash.regular":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.lineStyle = FrameCharLineStyle.single;
                this.dashStyle = FrameCharDashStyle.regular;
                this.applyCurrentStyleToSelection()
                break
            case "corner.rounded":
                this.cornerStyle = FrameCharCornerStyle.rounded;
                this.lineStyle = FrameCharLineStyle.single;
                this.dashStyle = FrameCharDashStyle.regular;
                this.applyCurrentStyleToSelection()
                break
            case "style.thick":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.lineStyle = FrameCharLineStyle.thick;
                this.dashStyle = FrameCharDashStyle.regular;
                this.applyCurrentStyleToSelection()
                break
            case "style.double":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.lineStyle = FrameCharLineStyle.double;
                this.dashStyle = FrameCharDashStyle.regular;
                this.applyCurrentStyleToSelection()
                break
            case "dash.double":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.dashStyle = FrameCharDashStyle.double;
                this.lineStyle = FrameCharLineStyle.single;
                this.applyCurrentStyleToSelection()
                break
            case "dash.triple":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.dashStyle = FrameCharDashStyle.triple;
                this.lineStyle = FrameCharLineStyle.single;
                this.applyCurrentStyleToSelection()
                break
            case "dash.quad":
                this.cornerStyle = FrameCharCornerStyle.regular;
                this.dashStyle = FrameCharDashStyle.quad;
                this.lineStyle = FrameCharLineStyle.single;
                this.applyCurrentStyleToSelection()
                break
            case "arrow.none":
                this.arrowStyle = ArrowStyle.none
                this.applyCurrentStyleToSelection()
                break
            case "arrow.ascii":
                this.arrowStyle = ArrowStyle.ascii
                this.applyCurrentStyleToSelection()
                break
            case "arrow.big":
                this.arrowStyle = ArrowStyle.big
                this.applyCurrentStyleToSelection()
                break
            case "arrow.small":
                this.arrowStyle = ArrowStyle.small
                this.applyCurrentStyleToSelection()
                break
            default: return false
        }
        return true
    }

    getResultModel(): ToolOutputModel {
        return {
            drawing: {
                ...this.inModel.drawing,
                characterRaster: this.raster,
                cursorPos: this.cursorPosition,
                actionHandlerStates: {
                    ...this.inModel.drawing.actionHandlerStates,
                    lineStyle: {
                        arrowStyle: this.arrowStyle,
                        cornerStyle: this.cornerStyle,
                        lineStyle: this.lineStyle,
                        dashStyle: this.dashStyle,
                    }
                }
            }
        }
    }

    private applyCurrentStyleToSelection() {
        const updatedCells: IRasterPointItem<string>[] = []
        this.updateLineSegmentsWithCurrentStyle(updatedCells)
        this.updateArrowHeadsWithCurrentStyle(updatedCells)
        this.raster = Raster.updateWith(this.raster, Raster.fromCells(updatedCells))
    }

    private updateLineSegmentsWithCurrentStyle(updatedCells: IRasterPointItem<string>[]) {
        for (const cell of Raster.getCells(this.inModel.drawing.selection)) {
            const c = Raster.getAtPoint(this.raster, cell.point)
            const charTraits = frameCharTraits.getByChar(c)
            const targetStyles = charTraits.lineStyleList.map(l => this.getTargetStyleForSelectedCell(cell.point, l[0], l[1]))
            const candidates = frameCharTraits.all.filter(t => t.connectsExactlyToWithLineStyles(targetStyles))
                .filter(t => t.dashStyle !== FrameCharDashStyle.undefined ? t.dashStyle === this.dashStyle : true)
                .filter(t => t.cornerStyle !== FrameCharCornerStyle.undefined ? t.cornerStyle === this.cornerStyle : true)
            if (candidates.length === 1) {
                const updatedCellTraits = candidates[0]
                updatedCells.push({ point: cell.point, value: updatedCellTraits.char })
            }
        }
    }

    private updateArrowHeadsWithCurrentStyle(updatedCells: IRasterPointItem<string>[]) {
        let targetArrowString = ""
        switch (this.arrowStyle) {
            case ArrowStyle.ascii: targetArrowString = arrowHeads.ascii; break
            case ArrowStyle.big: targetArrowString = arrowHeads.big; break
            case ArrowStyle.small: targetArrowString = arrowHeads.small; break
        }
        for (const cell of Raster.getCells(this.inModel.drawing.selection)) {
            const c = Raster.getAtPoint(this.raster, cell.point)

            const [arrowString, dir] = getArrowStyleStringAndDirForSample(c)
            if (arrowString) {
                const targetArrow = getArrowForDirection(targetArrowString, dir)
                updatedCells.push({ point: cell.point, value: targetArrow })
            }
        }
    }

    private getTargetStyleForSelectedCell(p: IRasterPoint, d: IRasterNeighbour, currentLineStyle: FrameCharLineStyle): [IRasterNeighbour, FrameCharLineStyle] {
        const neighbourPoint = RasterPoint.plus(p, RasterNeighbour.offset(d, 1))
        const neighbourChar = Raster.getAtPoint(this.inModel.drawing.characterRaster, neighbourPoint)
        const isConnectedBack = frameCharTraits.getByChar(neighbourChar).connectsTo(RasterNeighbour.opposite(d))
        const isNeighbourSelected = Raster.getAtPoint(this.inModel.drawing.selection, neighbourPoint) === true
        return [d, isConnectedBack && isNeighbourSelected ? this.lineStyle : currentLineStyle];
    }

}



