import { EditorD3Scene } from "../app/character-raster-editor/editor-d3-scene";
import { IRaster, Raster } from '../drawing/raster';
import { IRasterPoint, RasterPoint } from "../geometry/raster-point";
import styles from './select-tool-preview.module.css';

export function previewBoxing(scene: EditorD3Scene, from: IRasterPoint, to: IRasterPoint) {
    const xMin = Math.min(scene.scaleX(from.x), scene.scaleX(to.x))
    const yMin = Math.min(scene.scaleY(from.y), scene.scaleY(to.y))
    const xMax = Math.max(scene.scaleX(from.x), scene.scaleX(to.x))
    const yMax = Math.max(scene.scaleY(from.y), scene.scaleY(to.y))
    return <rect x={xMin} y={yMin} width={xMax - xMin} height={yMax - yMin} className={styles.selectBox}></rect>
}

export function previewLasso(scene: EditorD3Scene, selection: IRaster<boolean>) {
    console.log("selectionSize (select-tool viz): "+Raster.getCells(selection).length)

    const cells = Raster.getCells(selection).filter(c => c.value).map(c => c.point)
    if (cells.length === 0) return

    const raster = Raster.fromCells(cells.map(p => ({point: p, value: true})))
    const cellBorderExpression = cells.map(p => getSelectionBoxSidesPathExpression(scene, raster, p)).join("")
    const cellExpression = cells.map(p => getSelectionCellsPathExpression(scene, raster, p)).join("")    
    return <g>
        <path className={styles.lassoCellSide} d={cellBorderExpression}></path>
        <path className={styles.lassoCellFill} d={cellExpression}></path>
    </g>
}

function getSelectionBoxSidesPathExpression(scene: EditorD3Scene, raster: IRaster<boolean>, p: IRasterPoint) {
    const left = !Raster.getAtPoint(raster, RasterPoint.plusXY(p,-1,0)) ? ` M ${scene.scaleX(p.x)} ${scene.scaleY(p.y)} L ${scene.scaleX(p.x)} ${scene.scaleY(p.y+1)}` : ""
    const right = !Raster.getAtPoint(raster, RasterPoint.plusXY(p,1,0)) ? ` M ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y)} L ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y+1)}` : ""
    const top = !Raster.getAtPoint(raster, RasterPoint.plusXY(p,0,-1)) ? ` M ${scene.scaleX(p.x)} ${scene.scaleY(p.y)} L ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y)}` : ""
    const bottom = !Raster.getAtPoint(raster, RasterPoint.plusXY(p,0,1)) ? ` M ${scene.scaleX(p.x)} ${scene.scaleY(p.y+1)} L ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y+1)}` : ""
    return left + right + top + bottom
}

function getSelectionCellsPathExpression(scene: EditorD3Scene, raster: IRaster<boolean>, p: IRasterPoint) {
    const e = ` M ${scene.scaleX(p.x)} ${scene.scaleY(p.y)} L ${scene.scaleX(p.x)} ${scene.scaleY(p.y+1)} L ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y+1)} L ${scene.scaleX(p.x+1)} ${scene.scaleY(p.y)} ${scene.scaleX(p.x)} ${scene.scaleY(p.y)}`
    return e
}