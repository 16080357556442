import * as React from 'react'
import { Link } from 'react-router-dom';
import styles from './footer-component.module.css'

export function FooterComponent() {
    return <div className={styles.footer}>
        <a onClick={() => popupCookieBanner()}>About Cookies</a> -
        <Link to="/privacy-policy">Privacy Policy</Link> -
        <Link to="/privacy-policy">Imprint</Link> -
        <a href="https://stats.uptimerobot.com/BKBoc2kl" >Uptime</a >
    </div >
}

function popupCookieBanner() {
    // FIXME: Implement cookies.
    throw new Error("not implemented")
}

