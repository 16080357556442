import * as React from 'react'
import { useSelector } from './store/app-react-redux'
import { useDispatch } from 'react-redux'
import { Raster } from '../drawing/raster'
import { useNavigate } from 'react-router-dom'
import { AppBar, Button, ButtonGroup, Icon, IconButton, Toolbar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import styles from './header-component.module.css'
import { CollaborationControls } from './collaboration-controls'
import { copy, paste, undo } from './character-raster-editor/drawing-actions'
import { editorCanvasSlice } from './store/reducers/editor-canvas-reducer'

export function HeaderComponent(props: { handleDrawerToggle: () => void }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const canUndo = useSelector(state => state.drawing.past.length > 0)
    const selection = useSelector(state => state.drawing.present.selection)
    const pxPerCellX = useSelector(state => state.canvas.pxPerCellX)
    const pxPerCellY = useSelector(state => state.canvas.pxPerCellY)
    const canCopy = !Raster.isEmpty(selection)
    const canZoomIn = pxPerCellX < 32
    const canZoomOut = pxPerCellX > 4

    return <React.Fragment>
        <AppBar position="sticky">
            <Toolbar variant="dense">
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={props.handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Button color="inherit" onClick={() => navigate("/import")}>Import</Button>
                <Button color="inherit" onClick={() => navigate("/export")}>Export</Button>
                <Button color="inherit" disabled={!canUndo} onClick={() => undo(dispatch)}><Icon>undo</Icon></Button>
                <Button color="inherit" disabled={!canCopy} onClick={() => copy()}><Icon>content_copy</Icon></Button>
                <Button color="inherit" onClick={() => paste(dispatch)}><Icon>content_paste</Icon></Button>
                <CollaborationControls></CollaborationControls>
                <ButtonGroup variant="text" aria-label="zoom button group">
                    <Button color="inherit" disabled={!canZoomIn} onClick={() => zoomIn(dispatch, pxPerCellX, pxPerCellY)}><Icon>zoom_in</Icon></Button>
                    <Button color="inherit" disabled={!canZoomOut} onClick={() => zoomOut(dispatch, pxPerCellX, pxPerCellY)}><Icon>zoom_out</Icon></Button>
                </ButtonGroup>
            </Toolbar>
        </AppBar>
    </React.Fragment>
}


function zoomIn(dispatch: React.Dispatch<any>, pxPerCellX: number, pxPerCellY: number) {
    const zoomInAction = editorCanvasSlice.actions.canvasResolutionChanged({ pxPerCellX: pxPerCellX * 4 / 3, pxPerCellY: pxPerCellY * 4 / 3 })
    dispatch(zoomInAction)
}

function zoomOut(dispatch: React.Dispatch<any>, pxPerCellX: number, pxPerCellY: number) {
    const zoomInAction = editorCanvasSlice.actions.canvasResolutionChanged({ pxPerCellX: pxPerCellX * 3 / 4, pxPerCellY: pxPerCellY * 3 / 4 })
    dispatch(zoomInAction)
}