import { ShapeTemplateModel } from "./shape-template-model";
import { RasterPoint } from "../geometry/raster-point";

// U+250x	─	━	│	┃	┄	┅	┆	┇	┈	┉	┊	┋	┌	┍	┎	┏
// U+251x	┐	┑	┒	┓	└	┕	┖	┗	┘	┙	┚	┛	├	┝	┞	┟
// U+252x	┠	┡	┢	┣	┤	┥	┦	┧	┨	┩	┪	┫	┬	┭	┮	┯
// U+253x	┰	┱	┲	┳	┴	┵	┶	┷	┸	┹	┺	┻	┼	┽	┾	┿
// U+254x	╀	╁	╂	╃	╄	╅	╆	╇	╈	╉	╊	╋	╌	╍	╎	╏
// U+255x	═	║	╒	╓	╔	╕	╖	╗	╘	╙	╚	╛	╜	╝	╞	╟
// U+256x	╠	╡	╢	╣	╤	╥	╦	╧	╨	╩	╪	╫	╬	╭	╮	╯
// U+257x	╰	╱	╲	╳	╴	╵	╶	╷	╸	╹	╺	╻	╼	╽	╾	╿
// ▀▄█▌▐░▒▓■□▪▫▬▲▴▸►▼▾◂◄◊○◌●◘◙◦⁰¹²³⁴⁵⁶⁷⁸⁹⁺₀₁₂₃₄₅₆₇₈₉₊₋₌₍₎←↑→↓↔↕↨

export const BaseTemplates = {
    getTemplates(): ShapeTemplateModel[] {
        const templates: ShapeTemplateModel[] = [
            {
                templateText: `\
  (\\_/)
=( ^.^ )=
 (")_(")`,
                pickPos: RasterPoint.xy(4, 2),
                cursorPos: RasterPoint.xy(4, 2)
            },
            {
                templateText: `\
┌────┐
│    │
└────┘`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
┏━━━━┓
┃    ┃
┗━━━━┛`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
╔════╗
║    ║
╚════╝`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
╭────╮
│    │
╰────╯`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
+----+
|    |
+----+`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
┏━┯━━┯━━┓
┠─┼──┼──┨
┃ │  │  ┃
┗━┷━━┷━━┛`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 2)
            },
            {
                templateText: `\
 ^
Y╎
 ╎
 ╎
╌┼╌╌╌╌╌╌╌╌>
 ╵      X`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(1, 5)
            },
            {
                templateText: `\
◄───╴`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
╶───►`,
                pickPos: RasterPoint.xy(4, 0),
                cursorPos: RasterPoint.xy(4, 0)
            },
            {
                templateText: `\
▲
│
╵`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
╷
│
▼`,
                pickPos: RasterPoint.xy(0, 2),
                cursorPos: RasterPoint.xy(0, 2)
            },
            {
                templateText: `\
╶─┐
◄─┘`,
                pickPos: RasterPoint.xy(0, 1),
                cursorPos: RasterPoint.xy(0, 1)
            },
            {
                templateText: `\
←`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
↑`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
→`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
↓`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
┌─────┐
│     │
└──┬──┘
   ┆
   ┆
   ┆
   ╵`,
                pickPos: RasterPoint.xy(3, 2),
                cursorPos: RasterPoint.xy(3, 2)
            },
            {
                templateText: `\
(")
─┰─
/ \\
 ╷
 ┆
 ┆
 ┆
 ╵`,
                pickPos: RasterPoint.xy(1, 3),
                cursorPos: RasterPoint.xy(1, 3)
            },
            {
                templateText: `\
┆
▒
▒
`,
                pickPos: RasterPoint.xy(0, 1),
                cursorPos: RasterPoint.xy(0, 1)
            },
            {
                templateText: `\
┌──────┐
│      │
├──────┴──────┐
│             │
│             │
└─────────────┘`,
                pickPos: RasterPoint.xy(0, 2),
                cursorPos: RasterPoint.xy(1, 1)
            },
            {
                templateText: `\
┌─────────────┐
│ «interface» │
│             │
│             │
└─────────────┘`,
                pickPos: RasterPoint.xy(0, 2),
                cursorPos: RasterPoint.xy(1, 1)
            },
            {
                templateText: `\
─o)─`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },
            {
                templateText: `\
─(o─`,
                pickPos: RasterPoint.xy(0, 0),
                cursorPos: RasterPoint.xy(0, 0)
            },


        ];
        return templates;
    },
};
