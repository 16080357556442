import { IRaster, Raster } from "../drawing/raster";
import { SmartDelete } from "../editing-tools/smart-delete";
import { ToolInputModel, ToolOutputModel } from "../editing-tools/tool-model/tool-model";
import { IActionOptions, IActionOptionGroup } from "../editing-tools/tool-services/tool-options";
import { IEditingActionProvider } from "./editing-action-interfaces";

export class DeleteSelectionActionProvider implements IEditingActionProvider {

    public static providerName = "deleteSelectionActionProvider"
    private raster: IRaster<string>
    private selection: IRaster<boolean>

    constructor(private inModel: ToolInputModel) {
        this.raster = inModel.drawing.characterRaster
        this.selection = inModel.drawing.selection
    }

    getOptions(): IActionOptions {
        const hasSelection = !Raster.isEmpty(this.selection)
        const options: IActionOptionGroup[] = [];
        if (hasSelection) {
            options.push(
                {
                    options: [
                        {
                            id: "delete-selection", disabled: !hasSelection, checked: false, icon: "delete"
                        },
                    ],
                    actionHandlerName: DeleteSelectionActionProvider.providerName,
                })
        }
        return { options }
    }

    handleOption(id: string): boolean {
        if (id === "delete-selection") {
            [this.raster, this.selection] = SmartDelete.deleteSelectionWithInterpolation(this.raster, this.selection)
            return true
        }
        return false;
    }

    getResultModel(): ToolOutputModel {
        return {
            drawing: {
                ...this.inModel.drawing,
                selection: this.selection,
                characterRaster: this.raster,
            }
        }
    }
}