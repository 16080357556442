export interface ClientToServerMessage {
	type: string
}

export interface ServerToClientMessage {
	type: string
}


export interface JoinSessionMessage extends ClientToServerMessage {
	sessionId: string
	myName: string,
	// if not null, the content will be applied and broadcastet to other participant. Only master should do this.
	initialCells: CellContent[]
}
export const joinSessionMessageType = "joinSession"


export interface Participant {
	name: string
	id: number
}

export interface ParticipantsUpdateMessage extends ServerToClientMessage {
    myId: number
	participants: Participant[]
}
export const participantsUpdateMessageType = "participants"

export interface MyNameUpdateMessage extends ClientToServerMessage {
	name: string
}
export const myNameUpdateMessageType = "updateMyName"


export interface PointerPosMessage extends ClientToServerMessage {
    x: number,
    y: number
}
export const pointerPosMessageType = "pointerPos"

export interface PointerPosUpdateMessage extends ServerToClientMessage {
    participantId: number
    x: number
    y: number
}
export const pointerPosUpdateMessageType = "pointerPos"

export interface CellContent {
	x: number,
	y: number,
	c: string
}

export interface ContentChangedMessage extends ClientToServerMessage {
	cells: CellContent[]
}
export const contentChangedMessageType = "contentChanged"

export interface ContentUpdateMessage extends ServerToClientMessage {
	cells: CellContent[],
	startFromScratch?: boolean
}
export const contentUpdateMessageType = "contentUpdated"