import './tool-icon.scss'
import SvgIconTemplate from './icon-template';
import SvgTextTool from './text-tool';
import SvgSelectTool from './select-tool';
import SvgDrawTool from './draw-tool';
import SvgPushMoveTool from './push-move-tool';
import SvgRoutingTool from './routing-tool';
import { ToolIconName } from '../../../editing-tools/iediting-tool';
import React from 'react';

export interface IToolIconProperties {
    iconName: ToolIconName
}

export function ToolIcon(properties: IToolIconProperties) {
    switch(properties.iconName) {
        case "pushMove": return (<SvgPushMoveTool></SvgPushMoveTool>)
        case "text": return (<SvgTextTool></SvgTextTool>)
        case "select": return (<SvgSelectTool></SvgSelectTool>)
        case "draw": return (<SvgDrawTool></SvgDrawTool>)
        case "routing": return (<SvgRoutingTool></SvgRoutingTool>)
        default: return (<SvgIconTemplate></SvgIconTemplate>)
    }
}
