import * as React from "react";

function SvgSelectTool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <path d="M13.62 2.013l.279 42.661 12.154-10.063L40.71 58.626l11.73-7.244L34.8 30.73l13.171-5.643z" />
    </svg>
  );
}

export default SvgSelectTool;
