import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PointerPosUpdateMessage } from "../../../services/messages";

export interface CollaborationState {
    sessionId: string | null
    isConnected: boolean
    participants: Participant[]
    myName: string,
}

const initialState: CollaborationState = {
    sessionId: undefined,
    isConnected: false,
    participants: [],
    myName: "nobody",
}

export interface Participant {
    name: string,
    id: number,
    pointerX: number,
    pointerY: number
}

export const collaborationSlice = createSlice({
    initialState,
    name: "Collaboration",
    reducers: {
        nameUpdated: (state, action: PayloadAction<string>) => {
            state = { ...state, myName: action.payload }
            return state
        },
        participantListUpdated: (state, action: PayloadAction<Participant[]>) => {
            state = { ...state, participants: action.payload }
            return state
        },
        connectionStateUpdated: (state, action: PayloadAction<boolean>) => {
            state = { ...state, isConnected: action.payload }
            return state
        },
        sessionIdDefined: (state, action: PayloadAction<string>) => {
            state = { ...state, sessionId: action.payload }
            return state
        },
        pointerPosOfParticipantUpdated: (state, action: PayloadAction<PointerPosUpdateMessage>) => {
            const updatedParticipants = state.participants.map(p => p.id === action.payload.participantId ? {...p, pointerX: action.payload.x, pointerY: action.payload.y } : p)
            state = { ...state, participants: updatedParticipants }
            return state
        }
    }
})
