import { RasterPoint, IRasterPoint } from "../geometry/raster-point"
import { IEditingTool } from "./iediting-tool"
import { CharRaster, IRaster, Raster } from "../drawing/raster"
import { EditingToolEventHandler, IEditingToolEventHandler } from "./iediting-tool-session"
import { IToolStates, ToolInputModel, ToolOutputModel } from "./tool-model/tool-model"
import { DrawingState, IDragState } from "../app/store/reducers/drawing-reducer"
import { DrawingController } from "../editor-model/drawing-controller"
import { BaseTemplates } from "../shape-templates/base-templates"
import { PointerEventType } from "../editor-model/pointer-event"
import { SelectTool } from "./select-tool"


export interface StencilToolModel {
    selectedStencilId?: number
}

const stencilToolName = "stencil"

export class StencilTool implements IEditingTool {
    static toolName = stencilToolName
    readonly name = stencilToolName
    readonly cursorName = "pointer"
    readonly iconName = "draw"
    readonly handleId = "stencil"
    readonly canHandleDragFromAnywhere = false

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.stencil) {
            state =
            {
                stencil: {}
            }
        }
        return state
    }

    makeHandler(input: ToolInputModel): IEditingToolEventHandler {
        if (!input.drawing.toolStates.stencil) {
            throw new Error("tried to construct handler without existing model")
        }
        return new StencilToolEventHandler(input)
    }

    static handleStencilSelectedAction(state: DrawingState, stencilId: number) {
        state = DrawingController.startEditingWithTool(state, stencilToolName)
        state = {
            ...state,
            toolStates: {
                stencil: {
                    selectedStencilId: stencilId
                }
            }
        }
        return state
    }

    static handleStencilDragStartedAction(state: DrawingState, stencilId: number) {
        state = DrawingController.handlePointerEvent(state, { pos: {x:0, y:0}, type: PointerEventType.down})
        state = {
            ...state,
            currentlySelectedTool: StencilTool.toolName,
            toolStates: {
                stencil: {
                    selectedStencilId: stencilId
                }
            },
        }
        return state
    }

    static handleStencilDragMoveAction(state: DrawingState, cellPos: IRasterPoint) {
        state = DrawingController.handlePointerEvent(state, { pos: cellPos, type: PointerEventType.move})
        return state
    }

    static handleStencilDroppedAction(state: DrawingState, cellPos: IRasterPoint) {
        // Works around some wrong coordinate being reported right before dropping. Would not be necessary if the position of the drop itself was relevant.
        state = DrawingController.handlePointerEvent(state, { pos: cellPos, type: PointerEventType.move}) 
        
        state = DrawingController.handlePointerEvent(state, { pos: cellPos, type: PointerEventType.up})
        state = DrawingController.startEditingWithTool(state, SelectTool.toolName)
        return state
    }

}

export class StencilToolEventHandler extends EditingToolEventHandler {

    private raster: IRaster<string>
    private cursorPosition: IRasterPoint

    constructor(private readonly inModel: ToolInputModel) {
        super()
        this.raster = inModel.drawing.characterRaster
        this.cursorPosition = inModel.drawing.cursorPos
    }

    handleDrag(event: IDragState): boolean {
        const from = RasterPoint.fromString(event.draggedCellKey)
        const to = RasterPoint.plus(from, event.dragOffset)
        const toolState = this.inModel.drawing.toolStates.stencil
        if (toolState.selectedStencilId == undefined) {
            return false
        }

        const stencil = BaseTemplates.getTemplates()[toolState.selectedStencilId]
        const stencilRaster = CharRaster.readFromString(stencil.templateText)
        const translatedStencil = Raster.translate(stencilRaster, to.x - stencil.pickPos.x, to.y - stencil.pickPos.y)
        this.raster = Raster.updateWith(this.raster, translatedStencil)

        this.cursorPosition = to
        return true
    }

    startEditingAt(pos: IRasterPoint) { return }

    canStartEditingAt(pos: IRasterPoint): boolean {
        const hasStencilSelected = this.inModel.drawing.toolStates.stencil?.selectedStencilId != undefined ? true : false
        return hasStencilSelected
    }

    getResultModel(): ToolOutputModel {
        return {
            drawing: {
                ...this.inModel.drawing,
                characterRaster: this.raster,
                cursorPos: this.cursorPosition,
                toolStates: {
                    stencil: {
                        ...this.inModel.drawing.toolStates.stencil
                    }
                }
            }
        }
    }
}

