import { IEditingTool } from "./iediting-tool";
import { TextEditingTool } from "./text-editing-tool";
import { DrawingState } from "../app/store/reducers/drawing-reducer";
import { SelectTool } from "./select-tool";
// import { DrawingTool } from "./drawing-tool";
import { PushMoveTool } from "./push-move-tool/push-move-tool";
import { StencilTool } from "./stencil-tool";
import { RoutingTool } from "./routing-tool/routing-tool";
import { PathDrawingTool } from "./path-drawing-tool";



export class EditingToolCollection {

    private readonly toolMapByName = new Map<string, IEditingTool>()
    private readonly allEditingTools = new Array<IEditingTool>()

    // readonly lineEditingTool: LineEditingTool
    readonly textEditingTool: TextEditingTool
    // readonly boxEditingTool: BoxEditingTool
    readonly selectTool: SelectTool
    // readonly drawingTool: DrawingTool
    // readonly edgeMoveTool: EdgeMoveTool
    readonly pushMoveTool: PushMoveTool
    readonly stencilTool: StencilTool
    readonly routingTool: RoutingTool
    readonly pathDrawingTool: PathDrawingTool

    constructor() {

        this.selectTool = this.addTool(new SelectTool())
        this.pathDrawingTool = this.addTool(new PathDrawingTool())
        this.pushMoveTool = this.addTool(new PushMoveTool())
        // this.drawingTool = this.addTool(new DrawingTool())
        this.textEditingTool = this.addTool(new TextEditingTool())
        this.stencilTool = this.addTool(new StencilTool())
        this.routingTool = this.addTool(new RoutingTool())
        // FIXME: Make this a curved-line-editing tool
        //this.lineEditingTool = this.addTool(new LineEditingTool(toolDependencies))
        // FIXME: Remove these tools once they are completely obsolete
        // this.boxEditingTool = this.addTool(new BoxEditingTool(toolDependencies))
        // this.edgeMoveTool = this.addTool(new EdgeMoveTool(toolDependencies))
    }

    getToolByName(name: string): IEditingTool {
        return this.toolMapByName.get(name) as IEditingTool
    }

    getActiveTool(drawingState: DrawingState): IEditingTool {
        const tool = this.getToolByName(drawingState.currentlySelectedTool)
        return tool
    }

    get allTools(): ReadonlyArray<IEditingTool> {
        return this.allEditingTools
    }

    private addTool<T extends IEditingTool>(tool: T): T {
        const name = tool.name;
        if (this.toolMapByName.has(name)) {
            throw new Error("Duplicate tool name: " + name);
        }
        this.allEditingTools.push(tool)
        this.toolMapByName.set(name, tool)
        return tool
    }
}

let editingToolCollection :EditingToolCollection

export function getEditingToolCollection() {
    if(!editingToolCollection) {
        editingToolCollection = new EditingToolCollection()
    }
    return editingToolCollection
}

