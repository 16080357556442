import * as React from 'react'
import './handle-bar.scss'
import { getEditorCanvasHelpers } from '../../../app/store/reducers/editor-canvas-reducer'
import { useSelector } from '../../../app/store/app-react-redux'
import { HandleBarButton } from './handle-bar-button'
import { EditorD3Scene } from '../editor-d3-scene'
import { makeSelectableToolNamesSelector } from '../../../editor-model/selectors/selectable-tools-selector'
import { getEditingToolCollection } from '../../../editing-tools/editing-tool-collection'
import { DrawingState } from '../../store/reducers/drawing-reducer'


export interface IHandleBarProperties {
    d3Scene: EditorD3Scene,
    stateAfterDragSelector: (state:DrawingState) => DrawingState
}


export function HandleBar(properties: IHandleBarProperties) {
    const activeToolName = useSelector(state => state.drawing.present.currentlySelectedTool)
    const selectableToolsSelector = React.useMemo(makeSelectableToolNamesSelector, [])
    const selectableTools = useSelector(state => selectableToolsSelector(state.drawing.present))
    const effectiveCursorPosition = useSelector(state => properties.stateAfterDragSelector(state.drawing.present).cursorPos)
    const canvas = useSelector(state => state.canvas)
    const canvasHelper = getEditorCanvasHelpers(canvas)
    const translatePos = effectiveCursorPosition
    const translation = `translate(${canvasHelper.cellToElementX(translatePos.x + 0.5)},${canvasHelper.cellToElementY(translatePos.y + 0.5)}) translate(0,0)`
    const buttonSize = 25
    const buttonOffset = 29

    const buttonRow = selectableTools.map((t, index) => (
        <g key={"button" + index} transform={`translate(${0.75*buttonOffset},${index * buttonOffset + 1.5*buttonSize})`}>
            <HandleBarButton
                iconName={getEditingToolCollection().getToolByName(t).iconName}
                handleId={getEditingToolCollection().getToolByName(t).handleId}
                toolName={getEditingToolCollection().getToolByName(t).name}
                isActive={t === activeToolName}
                buttonSize={buttonSize}
                d3Scene={properties.d3Scene} />
        </g>))

    const toolCount = selectableTools.length
    const w = toolCount * buttonOffset

    return <g transform={translation}>
        <path className="button-row-background" d={`M 0 0 l ${buttonOffset/4} ${buttonSize} v ${w} h ${buttonOffset} v ${-w} L 0 0`}></path>
        {buttonRow}
    </g>;
}

