import * as React from 'react'
import { useSelector } from './store/app-react-redux'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, TextField } from '@material-ui/core'
import { collaborationService } from '../services/collaborationservice'
import { collaborationSlice } from './store/reducers/collaboration-reducer'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import QRCode from 'qrcode'

export function CollaborationControls() {
    const dispatch = useDispatch()
    const myName = useSelector(s => s.collaboration.myName)
    const [qrCodeUrl, setQrCodeUrl] = React.useState("")
    const [newSessionId] = React.useState(createSessionId())
    const [open, setOpen] = React.useState(false);

    const isConnected = useSelector(s => s.collaboration.isConnected)
    const participants = useSelector(s => s.collaboration.participants)
    const firstParticipants = participants.filter((s, n) => n < 3).map(p => p.name).join(", ")

    let dialog: JSX.Element = null
    if (open) {
        const handleClose = () => {
            setOpen(false);
        };

        const sharingLinkPath = "/join-session/" + newSessionId
        const sharingLinkUrl = window.location.origin + sharingLinkPath
        QRCode.toDataURL(sharingLinkUrl).then(url => setQrCodeUrl(url))

        dialog = <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">Edit with Friends</DialogTitle>
            <DialogContent>
                <form noValidate autoComplete="off">
                    <TextField id="my-name" label="Your name" value={myName} onChange={(event) => updateMyName(dispatch, event.target.value)} />
                </form>
                <p>
                    Share this link to edit the drawing together:
                </p>
                <TextField contentEditable={false} value={sharingLinkUrl} />
                <IconButton size='small' onClick={() => window.open(sharingLinkUrl, "_blank")}><OpenInNewOutlinedIcon /></IconButton>
                <IconButton size='small' onClick={() => copySharingLink(sharingLinkUrl)}><FileCopyOutlinedIcon /></IconButton>
                <div><img src={qrCodeUrl} alt="qrcode"/></div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isConnected} onClick={() => { collaborationService.connect(newSessionId, true); handleClose(); }} color="primary">Start Session</Button>
                <Button disabled={!isConnected} onClick={() => { collaborationService.disconnect(); handleClose(); }} color="primary">Leave Session</Button>
            </DialogActions>
        </Dialog>
    }

    return <div>
        <Button color={isConnected ? "secondary" : "inherit"} variant={isConnected ? "contained" : "text"} onClick={() => setOpen(true)}><Icon>people</Icon></Button> <small>{firstParticipants}</small>
        {dialog}
    </div >
}

export function JoinSessionDialog() {
    const navigate = useNavigate()
    const myInitialName = useSelector(s => s.collaboration.myName)
    const [myName, setMyName] = React.useState(myInitialName)
    const dispatch = useDispatch()
    const parameters = useParams()
    const sessionId = parameters['id']
    return <Dialog open={true} onClose={() => handleJoinDialogClose(navigate)}>
        <DialogTitle id="alert-dialog-title">Join an Editing Session</DialogTitle>
        <DialogContent>
            <form noValidate autoComplete="off">
                <TextField id="my-name" label="Name" value={myName} onChange={event => setMyName(event.target.value)} />
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { joinSession(dispatch, sessionId, myName); updateMyName(dispatch, myName); handleJoinDialogClose(navigate); }} color="primary">Join</Button>
        </DialogActions>
    </Dialog>
}

function copySharingLink(sharingLinkUrl: string) {
    navigator.clipboard.writeText(sharingLinkUrl)
    console.log("copyied sharing url to clipboard:", sharingLinkUrl)
}

function joinSession(dispatch: React.Dispatch<any>, sessionId: string, myName: string) {
    console.log("Joining session", sessionId)
    const action = collaborationSlice.actions.nameUpdated(myName)
    dispatch(action)
    collaborationService.connect(sessionId, false)
}

function handleJoinDialogClose(navigate: NavigateFunction) {
    navigate("/")
}


function updateMyName(dispatch: React.Dispatch<any>, newName: string) {
    const action = collaborationSlice.actions.nameUpdated(newName)
    dispatch(action)
}

function createSessionId(): string {
    const newSessionId = new Uint8Array(32)
    if(window?.crypto?.getRandomValues) {
        window.crypto.getRandomValues(newSessionId)
    } else {
        for(let n = 0; n< 32; ++n) {
            newSessionId[n] = Math.random()*256
        }
    }
    return Buffer.from(newSessionId).toString('hex')
}
