import { IRasterPoint } from "../geometry/raster-point";
import { ToolOutputModel } from "./tool-model/tool-model";
import { IDragState, KeyboardEventPayload } from "../app/store/reducers/drawing-reducer";
import { IInteractionHandleModel, InteractionHandleModel } from "./tool-services/handle-service";
import { IToolOptions } from "./tool-services/tool-options";
import { EditorD3Scene } from "../app/character-raster-editor/editor-d3-scene";

export interface IEditingToolEventHandler {
    canStartEditingAt(pos: IRasterPoint): boolean
    startEditingAt(pos: IRasterPoint): void
    handleKeyEvent(event: KeyboardEventPayload): boolean
    handleDoubleClick(pos: IRasterPoint): boolean
    handleDrag(event: IDragState): boolean
    initializeDrag()
    handleToolOption(id: string): boolean
    getResultModel(): ToolOutputModel
    getDisplayedHandles(): IInteractionHandleModel
    getSvgBottomLayer(scene: EditorD3Scene): JSX.Element | null
    getSvgDragLayer(scene: EditorD3Scene, event: IDragState): JSX.Element
    getToolOptions(): IToolOptions
}

export abstract class EditingToolEventHandler implements IEditingToolEventHandler {

    abstract canStartEditingAt(pos: IRasterPoint): boolean
    abstract startEditingAt(pos: IRasterPoint): void
    abstract getResultModel(): ToolOutputModel

    handleKeyEvent(event: KeyboardEventPayload) { return false }
    handleDoubleClick(pos: IRasterPoint) { return false }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    initializeDrag() {}
    handleDrag(event: IDragState): boolean { return false }
    getDisplayedHandles(): IInteractionHandleModel { return InteractionHandleModel.withNoHandles() }
    getSvgBottomLayer(scene: EditorD3Scene): JSX.Element { return }
    getSvgDragLayer(scene: EditorD3Scene, event: IDragState): JSX.Element { return }
    getToolOptions(): IToolOptions { return { options: [] } }
    handleToolOption(id: string):boolean { return false }
}

