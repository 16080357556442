/* eslint-disable unicorn/no-array-reduce */
import { RasterBox } from "../geometry/raster-box";
import { IRasterPoint } from "../geometry/raster-point";

export interface IRaster<VALUE_TYPE> {
    [xy: string]: VALUE_TYPE | null
}

export interface IRasterPointItem<V> {
    point: IRasterPoint
    value: V | null
}

export const Raster = {
    getAtPoint<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, pos: IRasterPoint): VALUE_TYPE | null {
        return raster[Raster.makeKey(pos.x, pos.y)]
    },

    getAt<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, x: number, y: number): VALUE_TYPE | null {
        return raster[Raster.makeKey(x, y)]
    },

    setAtPoint<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, pos: IRasterPoint, c: VALUE_TYPE): IRaster<VALUE_TYPE> {
        return Object.freeze(Raster.setAt(raster, pos.x, pos.y, c))
    },

    setAtPoints<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, values: [IRasterPoint, VALUE_TYPE | null][]): IRaster<VALUE_TYPE> {
        const newRaster =  { ...raster }
        for(const [pos, c] of values) {
            newRaster[Raster.makeKey(pos.x, pos.y)] = c
        }
        return Object.freeze(newRaster)
    },

    setAt<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, x: number, y: number, c: VALUE_TYPE): IRaster<VALUE_TYPE> {
        return { ...raster, [Raster.makeKey(x, y)]: c }
    },

    nullAt<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, x: number, y: number): IRaster<VALUE_TYPE> {
        return { ...raster, [Raster.makeKey(x, y)]: undefined }
    },

    removeAt<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, x: number, y: number): IRaster<VALUE_TYPE> {
        const removed = { ...raster }
        delete removed[Raster.makeKey(x,y)]
        return removed
    },

    getBounds(raster: IRaster<any>) : RasterBox | null {
        const cells = Raster.getCells(raster)
        return cells.length > 0 ? cells.map(v => new RasterBox(v.point, v.point)).reduce((a, b) => a.getUnitedWith(b)) : undefined;
    },

    makeKey(x: number, y: number): string {
        return x + "/" + y
    },

    empty<VALUE_TYPE>(): IRaster<VALUE_TYPE> {
        return {}
    },

    isEmpty<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>) {
        return Object.keys(raster).length === 0
    },

    parseKey(key: string): IRasterPoint {
        const xy = key.split("/")
        return { x: Number.parseInt(xy[0]), y: Number.parseInt(xy[1]) }
    },

    isExplicitlyDeleted<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, pos: IRasterPoint): boolean {
        return Raster.getAtPoint(raster, pos) === null
    },

    forEachCell<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, f: (pos: IRasterPoint, value: VALUE_TYPE | null) => void) {
        for (const key of Object.keys(raster))  f(Raster.parseKey(key), raster[key])
    },

    getCells<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>): IRasterPointItem<VALUE_TYPE>[] {
        return Object.keys(raster).map(key => ({ point: Raster.parseKey(key), value: raster[key] }))
    },

    fromCells<VALUE_TYPE>(cells: IRasterPointItem<VALUE_TYPE>[]): IRaster<VALUE_TYPE> {
        const values = [...(cells.map(c => [c.point, c.value]) as [IRasterPoint, VALUE_TYPE | null][])]
        return Raster.setAtPoints(Raster.empty<VALUE_TYPE>(), values)
    },

    filter<VALUE_TYPE>(raster: IRaster<VALUE_TYPE>, includeFunction: (key: IRasterPoint, value: VALUE_TYPE|null) => boolean): IRaster<VALUE_TYPE> {
        const filtered = Object.keys(raster)
            .filter(key => {
                const pos = Raster.parseKey(key)
                const shouldInclude = includeFunction(pos, Raster.getAtPoint(raster, pos))
                return shouldInclude
            })
            .reduce((object, key) => {
                object[key] = Raster.getAtPoint(raster, Raster.parseKey(key));
                return object;
            }, Raster.empty<VALUE_TYPE>());
        return filtered
    },

    /// Update rasterToUpdate with all values in 'modifications'. When a value in modification is null, it is removed from 'rasterToUpdate'
    updateWith<VALUE_TYPE>(rasterToUpdate: IRaster<VALUE_TYPE>, modifications: IRaster<VALUE_TYPE>) {
        const updatedRaster = Raster.filter({ ...rasterToUpdate, ...modifications }, (pos, value) => value != undefined)
        return updatedRaster
    },

    diff<VALUE_TYPE>(rasterA: IRaster<VALUE_TYPE>, rasterB: IRaster<VALUE_TYPE>) {
        const deleteRasterA = Raster.fromCells(Raster.getCells(rasterA).map(c => ({...c, value: undefined})))
        const diff = Raster.filter({...deleteRasterA, ...rasterB}, (k, v) => Raster.getAtPoint(rasterA, k) !== v)
        return diff
    },

    translate<VALUE_TYPE>(rasterToUpdate: IRaster<VALUE_TYPE>, dx: number, dy: number): IRaster<VALUE_TYPE> {
        const updatedCells : IRasterPointItem<VALUE_TYPE>[] = Raster.getCells(rasterToUpdate)
            .map(cell => ({point: {x: cell.point.x + dx, y: cell.point.y + dy}, value: cell.value}))
        const updatedRaster = Raster.fromCells(updatedCells)
        return updatedRaster
    },

    mapItems<FROM_TYPE, TO_TYPE>(inRaster: IRaster<FROM_TYPE>, transform: (from: FROM_TYPE, pos: IRasterPoint) => TO_TYPE) : IRaster<TO_TYPE> {
        const mappedItems : IRasterPointItem<TO_TYPE>[] = Raster.getCells(inRaster).map(item => ({ point: item.point, value: transform(item.value, item.point)}))
        const mappedRaster = Raster.fromCells(mappedItems);
        return mappedRaster
    },

    // static fromString(val: string): CharacterRaster {
    //     const raster = new CharacterRaster()
    //     raster.readFromString(val)
    //     return raster
    // }


    // deleteChar(pos: IRasterPoint) {
    //     if (this.keepExplicitlyDeletedChars) {
    //         this.charMap.set(pos, "")
    //     } else {
    //         this.resetChar(pos)
    //     }
    // }

    // isExplicitlyDeleted(pos: IRasterPoint): boolean {
    //     return this.charMap.get(pos) === ""
    // }

    // resetChar(pos: IRasterPoint) {
    //     this.charMap.delete(pos)
    // }

    // applyChar(pos: IRasterPoint, c: string) {
    //     if (c === null) {
    //         this.resetChar(pos)
    //     } else if (c === "") {
    //         this.deleteChar(pos)
    //     } else {
    //         this.setChar(pos, c)
    //     }
    // }

    // applyChange(change: CharacterRasterChange) {
    //     this.applyChar(change.pos, change.c)
    // }

    // getChar(pos: IRasterPoint): string {
    //     return this.charMap.get(pos);
    // }

    // getContents(): CharacterRasterItem[] {
    //     const contents = this.charMap.toArray().map(item => ({ pos: item.point, c: item.value }))
    //     return contents
    // }

    // applyContents(items: CharacterRasterItem[]) {
    //     for (const item of items) {
    //         this.applyChar(item.pos, item.c)
    //     }
    // }

    // getAsString(): string {
    //     const charactersInMap = this.charMap.toArray()
    //     if (charactersInMap.length === 0) {
    //         return ""
    //     }

    //     // sort the item top-down left to right
    //     const sortedCharactersInMap = charactersInMap.sort((a, b) => {
    //         if (a.point.y < b.point.y) {
    //             return -1
    //         } else if (a.point.y > b.point.y) {
    //             return 1
    //         } else if (a.point.x < b.point.x) {
    //             return -1
    //         } else if (a.point.x > b.point.x) {
    //             return 1
    //         } else {
    //             return 0
    //         }
    //     })

    //     const minXPoint = charactersInMap.reduce((a, b) => a.point.x < b.point.x ? a : b)
    //     const minYPoint = charactersInMap.reduce((a, b) => a.point.y < b.point.y ? a : b)
    //     const minX = Math.min(0, minXPoint.point.x)
    //     const minY = Math.min(0, minYPoint.point.y)
    //     console.log("minx = " + minX + " minY = " + minY)

    //     let lastX = minX - 1
    //     let lastY = minY - 1
    //     const filledArray = new Array<string>()
    //     for (const item of sortedCharactersInMap) {
    //         while (lastY < item.point.y - 1) {
    //             filledArray.push("\n")
    //             lastX = minX - 1
    //             ++lastY
    //         }
    //         while (lastX < item.point.x - 1) {
    //             filledArray.push(" ")
    //             ++lastX
    //         }

    //         let c = item.value[0]
    //         if (c === null || c === "" || c === undefined) {
    //             c = " "
    //         }
    //         filledArray.push(c)
    //         ++lastX
    //     }

    //     const rasterString = filledArray.join("").split("\n").map(s => s.replace(/~+$/, '')).join("\n")
    //     console.log("Copied string:\n" + rasterString)
    //     return rasterString
    // }

    // readFromString(val: string) {
    //     this.charMap.clear()
    //     const lines = val.split("\r").join("").split("\n")
    //     for (let y = 0; y < lines.length; ++y) {
    //         const line = lines[y]
    //         for (let x = 0; x < line.length; ++x) {
    //             const char = line[x]
    //             if (char !== " ") {
    //                 this.setChar(RasterPoint.xy(x, y), line[x])
    //             }
    //         }
    //     }
    // }

    // clear() {
    //     this.charMap.clear()
    // }
};

export const CharRaster = {

    isVisiblyEmpty(raster: IRaster<string>, pos: IRasterPoint): boolean {
        const c = Raster.getAtPoint(raster, pos);
        return c == undefined || c === "" || c === " "
    },

    isVisiblyEmptyAt(raster: IRaster<string>, x: number, y: number): boolean {
        const c = Raster.getAt(raster, x, y);
        return c == undefined || c === "" || c === " "
    },

    getAsString(raster: IRaster<string>, useNonbreakingSpaces?: boolean) {
        const spaceChar = useNonbreakingSpaces === true ? " " /* U+00A0 */ : " "
        const charactersInMap = Raster.getCells(raster)
        if (charactersInMap.length === 0) {
            return ""
        }

        // sort the item top-down left to right
        const sortedCharactersInMap = charactersInMap.sort((a, b) => {
            if (a.point.y < b.point.y) {
                return -1
            } else if (a.point.y > b.point.y) {
                return 1
            } else if (a.point.x < b.point.x) {
                return -1
            } else if (a.point.x > b.point.x) {
                return 1
            } else {
                return 0
            }
        })

        const minXPoint = charactersInMap.reduce((a, b) => a.point.x < b.point.x ? a : b)
        const minYPoint = charactersInMap.reduce((a, b) => a.point.y < b.point.y ? a : b)
        const minX = Math.min(0, minXPoint.point.x)
        const minY = Math.min(0, minYPoint.point.y)

        let lastX = minX - 1
        let lastY = minY - 1
        const filledArray = new Array<string>()
        for (const item of sortedCharactersInMap) {
            while (lastY < item.point.y - 1) {
                filledArray.push("\n")
                lastX = minX - 1
                ++lastY
            }
            while (lastX < item.point.x - 1) {
                filledArray.push(spaceChar)
                ++lastX
            }

            let c = (item.value??"")[0]
            if (c === null || c === "" || c === undefined) {
                c = " "
            }
            filledArray.push(c)
            ++lastX
        }

        const rasterString = filledArray.join("").split("\n").map(s => s.replace(/~+$/, '')).join("\n")
        return rasterString
    },

    readFromString(value: string): IRaster<string> {
        let raster: IRaster<string> = {}
        const lines = value.split("\r").join("").split("\n")
        for (const [y, line] of lines.entries()) {
            // eslint-disable-next-line unicorn/no-for-loop
            for (let x = 0; x < line.length; ++x) {
                const char = line[x]
                if (char !== " " && char !== " " /* &nbsp; */) {
                    raster = Raster.setAt(raster, x, y, line[x])
                }
            }
        }
        return raster
    },
};