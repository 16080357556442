import { DrawingState } from "../app/store/reducers/drawing-reducer";
import { IActionOptionGroup, IActionOptions } from "../editing-tools/tool-services/tool-options"
import { DeleteSelectionActionProvider } from "./delete-selection-action"
import { IEditingActionProvider } from "./editing-action-interfaces";
import { LineStyleActionProvider } from "./line-style-action-provider";



export class EditingActionCollection {

    invokeAction(drawing: DrawingState, providerName: string, optionId: string): DrawingState {
        let actionProvider: IEditingActionProvider = null
        switch (providerName) {
            case DeleteSelectionActionProvider.providerName:
                actionProvider = new DeleteSelectionActionProvider({ drawing })
                break
            case LineStyleActionProvider.providerName:
                actionProvider = new LineStyleActionProvider({ drawing })
                break
            default:
                break
        }

        if (actionProvider) {
            actionProvider.handleOption(optionId)
            const resultModel = actionProvider.getResultModel()
            return resultModel.drawing
        } else {
            return drawing
        }
    }

    getActionOptions(drawing: DrawingState): IActionOptions {
        let allOptions: IActionOptionGroup[] = []
        for (const actionProvider of [new DeleteSelectionActionProvider({ drawing }),
        new LineStyleActionProvider({ drawing })]) {
            const actionProviderOptions = actionProvider.getOptions()
            allOptions = [...allOptions, ...actionProviderOptions.options]
        }

        return {
            options: allOptions
        }
    }
}

let editingActionCollection: EditingActionCollection

export function getEditingActionCollection() {
    if (!editingActionCollection) {
        editingActionCollection = new EditingActionCollection()
    }
    return editingActionCollection
}

