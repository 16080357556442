import { IRasterPoint } from "../geometry/raster-point"


export interface IRasterNeighbour {
    fx: number
    fy: number
}

export type RasterNeighbourName = "C" | "L" | "B" | "R" | "T" | "TL" | "BL" | "BR" | "TR"

export class RasterNeighbour {
    static readonly Center: IRasterNeighbour = { fx: 0, fy: 0 }
    static readonly Left: IRasterNeighbour = { fx: -1, fy: 0 }
    static readonly Right: IRasterNeighbour = { fx: 1, fy: 0 }
    static readonly Top: IRasterNeighbour = { fx: 0, fy: -1 }
    static readonly Bottom: IRasterNeighbour = { fx: 0, fy: 1 }
    static readonly BottomLeft: IRasterNeighbour = { fx: -1, fy: 1 }
    static readonly BottomRight: IRasterNeighbour = { fx: 1, fy: 1 }
    static readonly TopLeft: IRasterNeighbour = { fx: -1, fy: -1 }
    static readonly TopRight: IRasterNeighbour = { fx: 1, fy: -1 }

    public static opposite(neighbour: IRasterNeighbour): IRasterNeighbour {
        switch (neighbour) {
            case RasterNeighbour.Center: return RasterNeighbour.Center
            case RasterNeighbour.Left: return RasterNeighbour.Right
            case RasterNeighbour.Right: return RasterNeighbour.Left
            case RasterNeighbour.Top: return RasterNeighbour.Bottom
            case RasterNeighbour.Bottom: return RasterNeighbour.Top
            case RasterNeighbour.TopLeft: return RasterNeighbour.BottomRight
            case RasterNeighbour.TopRight: return RasterNeighbour.BottomLeft
            case RasterNeighbour.BottomLeft: return RasterNeighbour.TopRight
            case RasterNeighbour.BottomRight: return RasterNeighbour.TopLeft
        }
    }

    public static leftTurn90Degrees(neighbour: IRasterNeighbour): IRasterNeighbour {
        switch (neighbour) {
            case RasterNeighbour.Center: return RasterNeighbour.Center
            case RasterNeighbour.Left: return RasterNeighbour.Bottom
            case RasterNeighbour.Bottom: return RasterNeighbour.Right
            case RasterNeighbour.Right: return RasterNeighbour.Top
            case RasterNeighbour.Top: return RasterNeighbour.Left
            case RasterNeighbour.TopLeft: return RasterNeighbour.BottomLeft
            case RasterNeighbour.BottomLeft: return RasterNeighbour.BottomRight
            case RasterNeighbour.BottomRight: return RasterNeighbour.TopRight
            case RasterNeighbour.TopRight: return RasterNeighbour.TopLeft
        }
    }

    static asString(neighbour: IRasterNeighbour): RasterNeighbourName {
        switch (neighbour) {
            case RasterNeighbour.Center: return "C"
            case RasterNeighbour.Left: return "L"
            case RasterNeighbour.Bottom: return "B"
            case RasterNeighbour.Right: return "R"
            case RasterNeighbour.Top: return "T"
            case RasterNeighbour.TopLeft: return "TL"
            case RasterNeighbour.BottomLeft: return "BL"
            case RasterNeighbour.BottomRight: return "BR"
            case RasterNeighbour.TopRight: return "TR"
        }
        throw new Error("invalid neighbour" + neighbour.fx + "/" + neighbour.fy)
    }

    static fromString(name: string): IRasterNeighbour {
        switch (name) {
            case "C": return RasterNeighbour.Center
            case "L": return RasterNeighbour.Left
            case "B": return RasterNeighbour.Bottom
            case "R": return RasterNeighbour.Right
            case "T": return RasterNeighbour.Top
            case "TL": return RasterNeighbour.TopLeft
            case "BL": return RasterNeighbour.BottomLeft
            case "BR": return RasterNeighbour.BottomRight
            case "TR": return RasterNeighbour.TopRight
        }
        throw new Error("invalid neighbour name " + name)
    }


    public static rightTurn90Degrees(neighbour: IRasterNeighbour): IRasterNeighbour {
        return RasterNeighbour.opposite(RasterNeighbour.leftTurn90Degrees(neighbour))
    }

    public static offset(direction: IRasterNeighbour, n: number): IRasterPoint {
        return { x: direction.fx * n, y: direction.fy * n }
    }

    public static getManhattanOffsetInDirection(a: IRasterPoint, b: IRasterPoint, dir: IRasterNeighbour) : number{
        const dx = (b.x-a.x)*dir.fx 
        const dy = (b.y-a.y)*dir.fy
        return dx + dy
    }

    public static fromOffset(offset: IRasterPoint): IRasterNeighbour {
        if (offset.x < 0) {
            if (offset.y < 0) {
                return RasterNeighbour.TopLeft
            } else if (offset.y > 0) {
                return RasterNeighbour.BottomLeft
            } else {
                return RasterNeighbour.Left
            }
        } else if (offset.x > 0) {
            if (offset.y < 0) {
                return RasterNeighbour.TopRight
            } else if (offset.y > 0) {
                return RasterNeighbour.BottomRight
            } else {
                return RasterNeighbour.Right
            }

        } else {
            if (offset.y < 0) {
                return RasterNeighbour.Top
            } else if (offset.y > 0) {
                return RasterNeighbour.Bottom
            } else {
                return RasterNeighbour.Center
            }
        }
    }

    static FourDirections = [RasterNeighbour.Top, RasterNeighbour.Left, RasterNeighbour.Right, RasterNeighbour.Bottom]

    static AllDirections = [RasterNeighbour.TopLeft, RasterNeighbour.Top, RasterNeighbour.TopRight,
    RasterNeighbour.Left, RasterNeighbour.Right,
    RasterNeighbour.BottomLeft, RasterNeighbour.Bottom, RasterNeighbour.BottomRight]

    static AllDirectionsWithCenter = [RasterNeighbour.TopLeft, RasterNeighbour.Top, RasterNeighbour.TopRight,
    RasterNeighbour.Left, RasterNeighbour.Center, RasterNeighbour.Right,
    RasterNeighbour.BottomLeft, RasterNeighbour.Bottom, RasterNeighbour.BottomRight]
}
