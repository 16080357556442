import { RasterNeighbourName } from './raster-neighbour'

export enum ArrowStyle { none, ascii, big, small }

export const arrowHeads = {
    ascii: "<^v>",
    big: "◄▲▼►",
    small: "◂▴▾▸",
}

export function tryGetArrowStringForStyle(style: ArrowStyle) {
    switch(style) {
        case ArrowStyle.ascii: return arrowHeads.ascii
        case ArrowStyle.big: return arrowHeads.big
        case ArrowStyle.small: return arrowHeads.small
    }
    return
}

export function getArrowForDirection(arrowString: string, direction: RasterNeighbourName) {
    if(!arrowString) return
    if(arrowString.length === 1) return arrowString
    if(arrowString.length === 4) {
        switch(direction) {
            case "B": return arrowString[2]
            case "T": return arrowString[1]
            case "L": return arrowString[0]
            case "R": return arrowString[3]
            default: return
        }
    }
    return
}

export function getArrowStyleStringAndDirForSample(sampleChar: string) : [string, RasterNeighbourName] {
    for(const arrowHeadStyle in arrowHeads) {
        const arrowHeadString = arrowHeads[arrowHeadStyle]
        const arrowIndex = arrowHeadString.indexOf(sampleChar)
        switch(arrowIndex) {
            case 2: return [arrowHeadString, "B"]
            case 1: return [arrowHeadString, "T"]
            case 0: return [arrowHeadString, "L"]
            case 3: return [arrowHeadString, "R"]
        }
    }
    return [null, null]
}

export function getArrowStyleStringForSample(sampleChar: string, adjacentLineCharDirection: RasterNeighbourName) {
    for(const arrowHeadStyle in arrowHeads) {
        const arrowHeadString = arrowHeads[arrowHeadStyle]
        if(arrowHeadString.indexOf(sampleChar) > 0 && getArrowForDirection(arrowHeadString, adjacentLineCharDirection) === sampleChar) {
                return arrowHeadString
            }
    }
    for(const undirectedLineHead of undirectedLineHeads) {
        if(undirectedLineHead === sampleChar) {
            return undirectedLineHead
        }
    }
    return
}


export const singleArrows = [
    "←↑↓→",
]

export const undirectedLineHeads = [
    "◌",
    "○",
    "●",
    "◦",
    "•",
    "◊",
    "♦",
]
