import { createSelector } from "reselect";
import { DrawingState } from "../../app/store/reducers/drawing-reducer";
import { getEditingToolCollection } from "../../editing-tools/editing-tool-collection";
import { IEditingTool } from "../../editing-tools/iediting-tool";
import { ToolInputModel } from "../../editing-tools/tool-model/tool-model";

// FIXME: Restructure so that is does not depends on drawing as a whole - changes too often
export const makeSelectableToolNamesSelector = () => createSelector(
    [(drawing: DrawingState) => drawing],
    (drawing) => getSelectableToolNames(drawing)
)

export function getSelectableToolNames(drawing: DrawingState): string[] {
    const selectableToolNames = getEditingToolCollection().allTools
        .filter(tool => isToolSelectabe(tool, drawing))
        .map(tool => tool.name)
    return selectableToolNames
}

function isToolSelectabe(tool: IEditingTool, drawing: DrawingState): boolean {
    const cursorPos = drawing.cursorPos
    const toolInputModel: ToolInputModel = {
        drawing: {...drawing, toolStates: tool.getInitializedState(drawing.toolStates)}
    }
    const handler = tool.makeHandler(toolInputModel)
    let isToolSelectable = false
    if (cursorPos) {
        isToolSelectable = handler.canStartEditingAt(cursorPos)
    }
    return isToolSelectable
}