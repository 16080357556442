import * as React from 'react'
import { getEditingToolCollection } from '../editing-tools/editing-tool-collection'
import { useSelector } from './store/app-react-redux'
import { useDispatch } from 'react-redux'
import { drawingSlice } from './store/reducers/drawing-reducer'
import { Button, ButtonGroup, Icon, Toolbar } from '@material-ui/core'
import styles from './options-overlay.module.css'
import { getEditingActionCollection } from '../editing-actions/editing-action-collection'

export function OptionsOverlayComponent() {
    const dispatch = useDispatch()
    const drawing = useSelector(state => state.drawing.present)
    const toolOptions = getEditingToolCollection().getActiveTool(drawing)?.makeHandler({ drawing: drawing }).getToolOptions()
    const actionOptions = getEditingActionCollection().getActionOptions(drawing)
    const toolOptionList = toolOptions?.options ?? []
    const actionOptionList = actionOptions?.options ?? []
    const optionsForTools = toolOptionList.map((optionGroup, groupIndex) => <Toolbar variant="dense">
        <ButtonGroup className={styles.buttonGroup} size="small" key={groupIndex}>
            {
                optionGroup.options.map(option => <Button
                    variant="contained"
                    color={option.checked ? "secondary" : "default"}
                    onClick={() => handleToolOptionClick(dispatch, option.id)}
                    disabled={option.disabled ? true : false}
                    key={option.id}>{option.label??<Icon>{option.icon}</Icon>}</Button>)
            }
        </ButtonGroup></Toolbar>)
    const optionsForActions = actionOptionList.map((optionGroup, groupIndex) => <Toolbar variant="dense">
        <ButtonGroup className={styles.buttonGroup} size="small" key={groupIndex}>
            {
                optionGroup.options.map(option => <Button
                    variant="contained"
                    color={option.checked ? "secondary" : "default"}
                    onClick={() => handleActionOptionClick(dispatch, optionGroup.actionHandlerName, option.id)}
                    disabled={option.disabled ? true : false}
                    key={option.id}>{option.label??<Icon>{option.icon}</Icon>}</Button>)
            }
        </ButtonGroup></Toolbar>)

    return <React.Fragment>
        <div className={styles.background}>
            <div>{optionsForTools}</div>
            <div>{optionsForActions}</div>
        </div>
    </React.Fragment>
}


function handleToolOptionClick(dispatch: React.Dispatch<any>, id: string) {
    const toolButtonClickAction = drawingSlice.actions.toolOptionClicked(id)
    dispatch(toolButtonClickAction)
}

function handleActionOptionClick(dispatch: React.Dispatch<any>, providerName: string, optionId: string) {
    const toolButtonClickAction = drawingSlice.actions.actionOptionClicked({ providerName, optionId })
    dispatch(toolButtonClickAction)
}


