import { FrameCharCornerStyle, frameCharTraits, FrameCharTraitsIndex } from "../char-helpers/frame-chars";
import { LineContinuation } from "../char-helpers/line-continuation";
import { RasterNeighbour, IRasterNeighbour } from "../char-helpers/raster-neighbour";
import { IRaster, IRasterPointItem, Raster } from "../drawing/raster";
import { IRasterPoint, RasterPoint } from "../geometry/raster-point";

export class SmartDelete {
    public static deleteSelectionWithInterpolation(raster: IRaster<string>, selection: IRaster<boolean>): [IRaster<string>, IRaster<boolean>] {
        const updates = Raster.getCells(selection).map(cell => ({ point: cell.point, value: SmartDelete.getInterpolationForDeletion(raster, selection, cell.point) }))

        const hiddenLineInterpolation = SmartDelete.interpolateHiddenLines(raster, selection)
        const combinedUpdateChars = [...updates, ...hiddenLineInterpolation]
        const combinedUpdateSelection = combinedUpdateChars.map(update => ({ point: update.point, value: update.value ? true : undefined }))

        return [Raster.updateWith(raster, Raster.fromCells(combinedUpdateChars)), Raster.updateWith(selection, Raster.fromCells(combinedUpdateSelection))]
    }

    public static getInterpolationForDeletion(raster: IRaster<string>, selection: IRaster<boolean>, pos: IRasterPoint): string {
        const char = Raster.getAtPoint(raster, pos)
        const cTraits = frameCharTraits.getByChar(char)
        const lineDirectoriesToUnselectedCell = [...cTraits.lineStyles]
            .filter(([n, s]) => {
                const isNeighbourSelected = Raster.getAtPoint(selection, RasterPoint.plus(pos, RasterNeighbour.offset(n, 1))) === true
                const neighbourTraits = frameCharTraits.getByChar(Raster.getAtPoint(raster, RasterPoint.plus(pos, RasterNeighbour.offset(n, 1))))
                const isNeighbourConnectedToPos = neighbourTraits.connectsTo(RasterNeighbour.opposite(n))
                const keepConnectionToNeighbour = !isNeighbourSelected && isNeighbourConnectedToPos
                return keepConnectionToNeighbour
            })
        if (lineDirectoriesToUnselectedCell.length === 0) {
            return undefined
        }
        const interpolatedTraits = frameCharTraits.all.find(ct => ct.connectsExactlyToWithLineStyles(lineDirectoriesToUnselectedCell))
        return interpolatedTraits && char !== interpolatedTraits.char ? interpolatedTraits.char : undefined;
    }

    public static interpolateHiddenLines(raster: IRaster<string>, selection: IRaster<boolean>): IRasterPointItem<string>[] {
        const vertical = SmartDelete.interpolateHiddenLinesWithDirection(raster, selection, RasterNeighbour.Bottom)
        const horizontal = SmartDelete.interpolateHiddenLinesWithDirection(raster, selection, RasterNeighbour.Right)
        return [...vertical, ...horizontal]
    }

    private static interpolateHiddenLinesWithDirection(raster: IRaster<string>, selection: IRaster<boolean>, interpolationDir: IRasterNeighbour): IRasterPointItem<string>[] {

        const cutoffFromCells = SmartDelete.findAdjacentCellsWithCutoffLine(raster, selection, RasterNeighbour.opposite(interpolationDir))
        const cutoffToCells = SmartDelete.findAdjacentCellsWithCutoffLine(raster, selection, interpolationDir)
        // FIXME: Select axis in sort algorithm for horizontal and pair selection

        const fromItems = cutoffFromCells.map(p => ({ point: p, first: true }))
        const toItems = cutoffToCells.map(p => ({ point: p, first: false }))
        const normalDir = RasterNeighbour.rightTurn90Degrees(interpolationDir)
        const items = [...fromItems, ...toItems].sort((a, b) => {
            if (RasterNeighbour.getManhattanOffsetInDirection(a.point, b.point, normalDir) !== 0) {
                return RasterNeighbour.getManhattanOffsetInDirection(a.point, b.point, normalDir) < 0 ? -1 : 1
            } else if (a.first !== b.first) {
                return a.first ? -1 : 1
            } else if (RasterNeighbour.getManhattanOffsetInDirection(a.point, b.point, interpolationDir) !== 0) {
                return RasterNeighbour.getManhattanOffsetInDirection(a.point, b.point, interpolationDir) < 0 ? -1 : 1
            } else {
                return 0
            }
        });

        const fromToPairs: [IRasterPoint, IRasterPoint][] = []
        if (items.length > 0) {
            let prevItem = items[0]
            for (const curItem of items.slice(1)) {
                const offsetInInterpolationDir = RasterNeighbour.getManhattanOffsetInDirection(prevItem.point, curItem.point, interpolationDir)
                const offsetInNormalDir = RasterNeighbour.getManhattanOffsetInDirection(prevItem.point, curItem.point, normalDir)
                if (offsetInNormalDir === 0 && prevItem.first && !curItem.first && offsetInInterpolationDir > 0) {
                    fromToPairs.push([prevItem.point, curItem.point])
                }
                prevItem = curItem
            }
        }

        const interpolatedCells: IRasterPointItem<string>[] = []
        for (const fromToPair of fromToPairs) {
            // Get styles of opposing path end. We connect them if the style is identical and there are only selected or empty cells in between.
            const fromChar = Raster.getAtPoint(raster, fromToPair[0])
            const toChar = Raster.getAtPoint(raster, fromToPair[1])
            let continuations = frameCharTraits.forAll
            continuations = continuations.withNeighbourCount(2)
            continuations = continuations.withCompatibleConnectionStyleFor(toChar, interpolationDir)
            continuations = continuations.withCompatibleConnectionStyleFor(fromChar, RasterNeighbour.opposite(interpolationDir))

            if (continuations.chars.length > 0) {
                const interpolatedChar = continuations.chars[0].char
                for (let n = 1; n < RasterPoint.manhattanDistTo(fromToPair[0], fromToPair[1]); ++n) {
                    const interpolatedCharPos = RasterPoint.plus(fromToPair[0], RasterNeighbour.offset(interpolationDir, n))
                    interpolatedCells.push({ point: interpolatedCharPos, value: interpolatedChar })
                }
            }
        }

        return interpolatedCells
    }

    private static findAdjacentCellsWithCutoffLine(raster: IRaster<string>, selection: IRaster<boolean>, dir: IRasterNeighbour) {
        const selectionCells = Raster.getCells(selection)
        const pairs = selectionCells.map(c => {
            const selectedChar = Raster.getAtPoint(raster, c.point)
            const neighbourPoint = RasterPoint.plus(c.point, RasterNeighbour.offset(dir, 1))
            const neighbourChar = Raster.getAtPoint(raster, neighbourPoint)
            return {
                selected: { point: c.point, value: selectedChar },
                neighbour: { point: neighbourPoint, value: neighbourChar }
            }
        })
            .filter(p => Raster.getAtPoint(selection, p.neighbour.point) !== true)
            .filter(p => {
                const selectedTraits = frameCharTraits.getByChar(p.selected.value)
                const neighbourTraits = frameCharTraits.getByChar(p.neighbour.value)
                const isSelectedConnectedToNeighbour = selectedTraits.connectsTo(dir)
                const isNeighbourConnectedToSelected = neighbourTraits.connectsTo(RasterNeighbour.opposite(dir))
                const isCutoff = isNeighbourConnectedToSelected && !isSelectedConnectedToNeighbour
                return isCutoff
            })

        const cutoffPositions = pairs.map(p => p.neighbour.point)
        return cutoffPositions
    }
}
