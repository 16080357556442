export interface IRasterPoint {
    readonly x: number,
    readonly y: number
}

export class RasterPoint {

    static readonly origin: IRasterPoint = {
        x: 0, y: 0
    }

    static xy(x: number, y: number): IRasterPoint {
        return { x: Math.round(x), y: Math.round(y) }
    }

    static manhattanDistTo(a: IRasterPoint, b: IRasterPoint) {
        return Math.abs(b.x - a.x) + Math.abs(b.y - a.y)
    }

    static getLength(p: IRasterPoint): number {
        return Math.sqrt(p.x * p.x + p.y * p.y)
    }

    static fromString(s: string): IRasterPoint {
        const parts = s.split("/")
        if (parts.length !== 2) throw new Error("Invalid point format")
        const x = Number.parseInt(parts[0])
        const y = Number.parseInt(parts[1])
        return { x, y }
    }

    static toString(p: IRasterPoint) {
        return p.x + "/" + p.y
    }

    static equals(a: IRasterPoint, b: IRasterPoint): boolean {
        return a && b && a.x === b.x && a.y === b.y
    }

    static clone(p: IRasterPoint): IRasterPoint {
        return { x: p.x, y: p.y }
    }

    static plus(a: IRasterPoint, b: IRasterPoint): IRasterPoint {
        return { x: a.x + b.x, y: a.y + b.y }
    }

    static plusXY(a: IRasterPoint, x: number, y: number): IRasterPoint {
        return { x: a.x + x, y: a.y + y }
    }

    static minus(a: IRasterPoint, b: IRasterPoint): IRasterPoint {
        return { x: a.x - b.x, y: a.y - b.y }
    }

    static null(): IRasterPoint {
        return { x: 0, y: 0 }
    }

    static lineBresenham(a: IRasterPoint, b: IRasterPoint): IRasterPoint[] {
        let x0 = a.x
        let y0 = a.y
        const x1 = b.x
        const y1 = b.y
        const points: IRasterPoint[] = []
        const dx = Math.abs(x1 - x0)
        const sx = x0 < x1 ? 1 : -1;
        const dy = -Math.abs(y1 - y0)
        const sy = y0 < y1 ? 1 : -1;
        let err = dx + dy

        // eslint-disable-next-line no-constant-condition
        while (true) {
            points.push({ x: x0, y: y0 })
            if (x0 == x1 && y0 == y1) break;
            const e2 = 2 * err;
            if (e2 > dy) { err += dy; x0 += sx; }
            if (e2 < dx) { err += dx; y0 += sy; }
        }

        return points
    }
}
