import { ArrowStyle, getArrowForDirection, tryGetArrowStringForStyle } from '../../char-helpers/arrow-sets';
import { LineContinuation } from '../../char-helpers/line-continuation';
import { FrameCharCornerStyle, FrameCharDashStyle, FrameCharLineStyle, frameCharTraits, FrameCharTraitsIndex } from "../../char-helpers/frame-chars";
import { IRasterNeighbour, RasterNeighbour } from "../../char-helpers/raster-neighbour";
import { IRaster, IRasterPointItem, Raster } from "../../drawing/raster";
import { IRasterPoint, RasterPoint } from "../../geometry/raster-point";


export class ConnectorRenderer {
    dirChars = new Map<string, string>()
    oneNeighbourCharIndexes: FrameCharTraitsIndex
    twoNeighbourCharIndexes: FrameCharTraitsIndex

    constructor(private lineStyle: FrameCharLineStyle,
        private dashStyle: FrameCharDashStyle,
        private cornerStyle: FrameCharCornerStyle,
        private headArrowStyle: ArrowStyle,
        private tailArrowStyle: ArrowStyle) {
        this.oneNeighbourCharIndexes = frameCharTraits.forAll.withNeighbourCount(1)
        this.twoNeighbourCharIndexes = frameCharTraits.forAll.withNeighbourCount(2)

        this.dirChars.set(makePathKey2(RasterNeighbour.Left, RasterNeighbour.Right),
            this.getCharForDirections(RasterNeighbour.Left, RasterNeighbour.Right, lineStyle, dashStyle, FrameCharCornerStyle.undefined))
        this.dirChars.set(makePathKey2(RasterNeighbour.Top, RasterNeighbour.Bottom),
            this.getCharForDirections(RasterNeighbour.Top, RasterNeighbour.Bottom, lineStyle, dashStyle, FrameCharCornerStyle.undefined))
        this.dirChars.set(makePathKey2(RasterNeighbour.Left, RasterNeighbour.Top),
            this.getCharForDirections(RasterNeighbour.Left, RasterNeighbour.Top, lineStyle, FrameCharDashStyle.undefined, cornerStyle))
        this.dirChars.set(makePathKey2(RasterNeighbour.Left, RasterNeighbour.Bottom),
            this.getCharForDirections(RasterNeighbour.Left, RasterNeighbour.Bottom, lineStyle, FrameCharDashStyle.undefined, cornerStyle))
        this.dirChars.set(makePathKey2(RasterNeighbour.Right, RasterNeighbour.Top),
            this.getCharForDirections(RasterNeighbour.Right, RasterNeighbour.Top, lineStyle, FrameCharDashStyle.undefined, cornerStyle))
        this.dirChars.set(makePathKey2(RasterNeighbour.Right, RasterNeighbour.Bottom),
            this.getCharForDirections(RasterNeighbour.Right, RasterNeighbour.Bottom, lineStyle, FrameCharDashStyle.undefined, cornerStyle))

        this.dirChars.set(makePathKey1(RasterNeighbour.Left),
            this.getCharForSingleDirection(RasterNeighbour.Left, lineStyle, dashStyle))
        this.dirChars.set(makePathKey1(RasterNeighbour.Right),
            this.getCharForSingleDirection(RasterNeighbour.Right, lineStyle, dashStyle))
        this.dirChars.set(makePathKey1(RasterNeighbour.Top),
            this.getCharForSingleDirection(RasterNeighbour.Top, lineStyle, dashStyle))
        this.dirChars.set(makePathKey1(RasterNeighbour.Bottom),
            this.getCharForSingleDirection(RasterNeighbour.Bottom, lineStyle, dashStyle))
    }

    private getCharForDirections(neighbour1: IRasterNeighbour, neighbour2: IRasterNeighbour, lineStyle: FrameCharLineStyle, dashStyle: FrameCharDashStyle, cornerStyle: FrameCharCornerStyle): string {
        const segmentCandidates = this.twoNeighbourCharIndexes.withNeighbour(neighbour1).withNeighbour(neighbour2).withCornerStyle(cornerStyle).withDashStyle(dashStyle);
        const result = segmentCandidates.chars.find(t => t.lineStyles.get(neighbour1) === lineStyle && t.lineStyles.get(neighbour2) === lineStyle)
        return result ? result.char : "x";
    }

    private getCharForSingleDirection(neighbour1: IRasterNeighbour, lineStyle: FrameCharLineStyle, dashStyle: FrameCharDashStyle): string {
        const segment = this.oneNeighbourCharIndexes.withNeighbour(neighbour1)
        const charsWithStyle = segment.chars.filter(c => c.lineStyleList[0][1] === lineStyle)
        return charsWithStyle.length === 1 ? charsWithStyle[0].char : this.getCharForDirections(neighbour1, RasterNeighbour.opposite(neighbour1), lineStyle, dashStyle, FrameCharCornerStyle.undefined);
    }

    renderPathToRaster(path: IRasterPoint[], raster: IRaster<string>): IRaster<string> {
        if (path.length < 2) {
            return Raster.empty<string>()
        }

        let updatedRaster = raster

        for (let index = 0; index < path.length; ++index) {
            let previousDirection: IRasterNeighbour
            let nextDirection: IRasterNeighbour
            if (index > 0) {
                const previousOffset = RasterPoint.minus(path[index - 1], path[index])
                previousDirection = RasterNeighbour.fromOffset(previousOffset)
            }
            if (index < path.length - 1) {
                const nextOffset = RasterPoint.minus(path[index + 1], path[index])
                nextDirection = RasterNeighbour.fromOffset(nextOffset)
            }

            let c: string
            if (index === 0) {
                if (this.tailArrowStyle === ArrowStyle.none) {
                    const lineStartContinuation = new LineContinuation(updatedRaster, path[index], this.cornerStyle)
                    const continuationChar = this.dirChars.get(makePathKey2(RasterNeighbour.opposite(nextDirection), nextDirection))
                    c = lineStartContinuation.getCenterCharWhenContinuingTo(nextDirection, continuationChar)
                }
            } else if (index === 1 && path.length > 2 && this.tailArrowStyle !== ArrowStyle.none) {
                // Add starting arrow before target point (pointing TO target point)
                const arrowDir = RasterNeighbour.asString(RasterNeighbour.opposite(nextDirection))
                const arrowString = tryGetArrowStringForStyle(this.tailArrowStyle)
                c = getArrowForDirection(arrowString, arrowDir)
            } else if (index === path.length - 2 && path.length > 2 && this.headArrowStyle !== ArrowStyle.none) {
                // Add end arrow before target point (pointing TO target point)
                const arrowDir = RasterNeighbour.asString(RasterNeighbour.opposite(previousDirection))
                const arrowString = tryGetArrowStringForStyle(this.headArrowStyle)
                c = getArrowForDirection(arrowString, arrowDir)
            } else if (index === path.length - 1) {
                if (this.headArrowStyle === ArrowStyle.none) {
                    const lineStartContinuation = new LineContinuation(updatedRaster, path[index], this.cornerStyle)
                    const continuationChar = this.dirChars.get(makePathKey2(RasterNeighbour.opposite(previousDirection), previousDirection))
                    c = lineStartContinuation.getCenterCharWhenContinuingTo(previousDirection, continuationChar)
                }
            } else {
                c = this.dirChars.get(makePathKey2(previousDirection, nextDirection))
            }

            if (c) {
                updatedRaster = Raster.setAt(updatedRaster, path[index].x, path[index].y, c)
            }
        }

        // if(cells.length > 1) {
        //     const headDirection = RasterNeighbour.asString(RasterNeighbour.fromOffset(RasterPoint.minus(cells[1].point, cells[0].point)))
        //     const headChar = getArrowForDirection(this.headChars, headDirection)
        //     if(headChar) {
        //         cells[0].value = headChar
        //     }
        //     const n = cells.length
        //     const tailDirection = RasterNeighbour.asString(RasterNeighbour.fromOffset(RasterPoint.minus(cells[n-2].point, cells[n-1].point)))
        //     const tailChar = getArrowForDirection(this.tailChars, tailDirection)
        //     if(tailChar) {
        //         cells[n-1].value = tailChar
        //     }
        // }

        return updatedRaster
    }
}

function makePathKey2(a: IRasterNeighbour, b: IRasterNeighbour): string {
    const sa = RasterNeighbour.asString(a)
    const sb = RasterNeighbour.asString(b)
    return sa < sb ? sa + "/" + sb : sb + "/" + sa;
}

function makePathKey1(a: IRasterNeighbour): string {
    const sa = RasterNeighbour.asString(a)
    return sa
}
