import { Dispatch } from '@reduxjs/toolkit'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '../../../app/store/app-react-redux'
import { drawingSlice } from '../../../app/store/reducers/drawing-reducer'
import { ToolIconName } from '../../../editing-tools/iediting-tool'
import { IRasterPoint, RasterPoint } from '../../../geometry/raster-point'
import { EditorD3Scene } from '../editor-d3-scene'
import { ToolIcon } from '../icons/tool-icon'
import './handle-bar-button.scss'

export interface IHandleBarButtonProperties {
    iconName: ToolIconName
    toolName: string
    handleId: string
    isActive: boolean
    buttonSize: number
    d3Scene: EditorD3Scene
}

export function HandleBarButton(properties: IHandleBarButtonProperties) {
    const [dragDetectStartPos, setDragDetectStartPos] = React.useState<IRasterPoint>() // defined as long as a drag is awaited
    const dispatch = useDispatch()
    const circleClass = properties.isActive ? "active" : "inactive"
    const r = properties.buttonSize / 2
    const iconSize = 64
    const iconTransform = `scale(0.8) translate(-${r},-${r}) scale(${2 * r / iconSize})`
    const cellWidth = useSelector(state => state.canvas.pxPerCellX)

    return (<g><circle cx="0" cy="0" r={r} className={circleClass}></circle>
        <g transform={iconTransform}><ToolIcon iconName={properties.iconName}></ToolIcon></g>
        <circle cx="0" cy="0" r={r} className="circle-active-overlay"
            onPointerDown={(event) => onPointerDown(event, dispatch, properties, dragDetectStartPos, setDragDetectStartPos)}
            onPointerUp={(event) => onPointerUp(event, dispatch, properties, dragDetectStartPos, setDragDetectStartPos)}
            onPointerMove={(event) => onPointerMove(event, dispatch, cellWidth, properties, dragDetectStartPos, setDragDetectStartPos)}></circle></g>)
}

function onPointerDown(event: React.PointerEvent, dispatch: Dispatch<any>, properties: IHandleBarButtonProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint) => void) {
    const clientClickPos = RasterPoint.xy(event.clientX, event.clientY)
    setDragDetectStartPos(clientClickPos)

    event.stopPropagation()
    event.preventDefault()

    event.currentTarget.setPointerCapture(event.pointerId)
    console.log("onPointerDown on button")
}

function onPointerMove(event: React.PointerEvent, dispatch: Dispatch<any>, minDragDistance: number, properties: IHandleBarButtonProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint?) => void) {
    if (dragDetectStartPos) {
        const currentPos = RasterPoint.xy(event.clientX, event.clientY)
        const currentDist = RasterPoint.getLength(RasterPoint.minus(currentPos, dragDetectStartPos))
        if (currentDist > minDragDistance) {
            const action = drawingSlice.actions.startDragOfHandleBarHandle({ handleId: properties.handleId, clickPosF: properties.d3Scene.clientToCellCoordinates(currentPos.x, currentPos.y) }) // fIXME: currentPos should be the start mousedownposition instead.
            dispatch(action)
            setDragDetectStartPos()
        }
    }
    // Note that we do not stop event propagation, so the canvas will handle the event and update the drag state. FIXME: Make this logic work together more cleanly.
}

function onPointerUp(event: React.PointerEvent, dispatch: Dispatch<any>, properties: IHandleBarButtonProperties, dragDetectStartPos: IRasterPoint, setDragDetectStartPos: (IRasterPoint?) => void) {
    event.currentTarget.releasePointerCapture(event.pointerId)

    if (dragDetectStartPos) {
        event.stopPropagation()
        event.preventDefault()

        const action = drawingSlice.actions.selectedToolChanged({ newToolName: properties.toolName })
        dispatch(action)
        console.log("onClickButton")
    } else {
        // Let the canvas handle the event. FIXME: Make this logic less convoluted
    }
    setDragDetectStartPos()
}

