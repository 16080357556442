import { IRasterPoint, RasterPoint } from "../../geometry/raster-point"
import { IEditingTool } from "../iediting-tool"
import { PushMove, PushMoveState, PushCellsStackItem } from "./push-move"
import { CharRaster, IRaster, Raster } from "../../drawing/raster"
import { IToolStates, ToolInputModel, ToolOutputModel } from "../tool-model/tool-model"
import { EditingToolEventHandler, IEditingToolEventHandler } from "../iediting-tool-session"
import { IDragState } from "../../app/store/reducers/drawing-reducer"

export interface PushMoveToolModel {
    pushMoveState: PushMoveState
}

export class PushMoveTool implements IEditingTool {
    readonly name = "pushMove"
    readonly iconName = "pushMove"
    readonly cursorName = "grab"
    readonly handleId = "pushMove"
    readonly canHandleDragFromAnywhere = true

    getInitializedState(state: IToolStates): IToolStates {
        if (!state.pushMove?.pushMoveState?.pushPath) {
            state = {
                pushMove: {
                    pushMoveState: {
                        nextId: 0,
                        pushPath: []
                    }
                }
            }
        }
        return state
    }

    makeHandler(input: ToolInputModel): IEditingToolEventHandler {
        return new PushMoveToolEventHandler(input)
    }

}

export class PushMoveToolEventHandler extends EditingToolEventHandler {

    private nextId: number
    private pushPath: PushCellsStackItem[]
    private raster: IRaster<string>
    private selection: IRaster<boolean>
    private cursorPos: IRasterPoint

    constructor(private readonly inModel: ToolInputModel) {
        super()
        const drawing = inModel.drawing
        this.nextId = drawing.toolStates.pushMove.pushMoveState.nextId
        this.pushPath = drawing.toolStates.pushMove.pushMoveState.pushPath
        this.raster = drawing.characterRaster
        this.cursorPos = drawing.cursorPos
        this.selection = drawing.selection
    }

    handleDrag(event: IDragState): boolean {
        const toolState = this.inModel.drawing.toolStates.pushMove
        let cellsToMove = Raster.getCells(this.inModel.drawing.selection).map(cell => cell.point)
        if(cellsToMove.length === 0) {
            cellsToMove = [this.inModel.drawing.cursorPos]
        }
        const pushMoveHandler = new PushMove(this.inModel.drawing.characterRaster, cellsToMove, [], toolState.pushMoveState)

        pushMoveHandler.moveTo(event.dragOffset)
        this.nextId = pushMoveHandler.nextId
        this.pushPath = pushMoveHandler.pushPath
        this.cursorPos = RasterPoint.plus(this.cursorPos, event.dragOffset)
        this.raster = pushMoveHandler.renderChanges(this.raster)
        this.selection = Raster.fromCells(Raster.getCells(this.inModel.drawing.selection).map(cell => ({...cell, point: RasterPoint.plus(cell.point, event.dragOffset)})))

        return true
    }

    canStartEditingAt(pos: IRasterPoint): boolean {
        if (CharRaster.isVisiblyEmpty(this.inModel.drawing.characterRaster, pos) ) {
            return false
        }
        return true        
    }

    startEditingAt(pos: IRasterPoint): boolean {
        this.pushPath = []
        return this.canStartEditingAt(pos)
    }

    getResultModel(): ToolOutputModel {
        return {
            ...this.inModel,
            drawing: {
                ...this.inModel.drawing,
                characterRaster: this.raster,
                cursorPos: this.cursorPos,
                selection: this.selection,
                toolStates: {
                    ...this.inModel.drawing.toolStates,
                    pushMove: {
                        ...this.inModel.drawing.toolStates.pushMove,
                        pushMoveState: {
                            nextId: this.nextId,
                            pushPath: this.pushPath
                        }
                    }
                }
            }
        }
    }

}

