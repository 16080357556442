import { IRasterPoint } from "../geometry/raster-point"
import { globalStore } from '../app/store/store'
import { collaborationSlice, Participant } from "../app/store/reducers/collaboration-reducer"
import {
    CellContent,
    ContentChangedMessage,
    contentChangedMessageType,
    ContentUpdateMessage,
    contentUpdateMessageType,
    JoinSessionMessage,
    joinSessionMessageType,
    MyNameUpdateMessage,
    myNameUpdateMessageType,
    ParticipantsUpdateMessage,
    participantsUpdateMessageType,
    PointerPosMessage,
    pointerPosMessageType,
    PointerPosUpdateMessage,
    pointerPosUpdateMessageType,
    ServerToClientMessage,
} from "./messages"
import { drawingSlice, IUpdateDocumentPayload } from "../app/store/reducers/drawing-reducer"
import { IRaster, Raster } from "../drawing/raster"




export interface CollaborationEventHandler {
    onParticipantsUpdated(participants: string[])
}

export class CollaborationService {
    //serviceUrl = "ws://localhost:80"
    serviceUrl = "wss://api.axis-and-arrows.art"
    websocket: WebSocket = undefined
    store = globalStore
    latestServerRasterState: IRaster<string> = Raster.empty<string>()
    sendContentOnNextOpen = false

    constructor() {
        const lastState = { lastRaster: undefined }
        globalStore.subscribe(() => {
            const raster = globalStore.getState().drawing.present.characterRaster
            if (lastState.lastRaster !== raster) {
                lastState.lastRaster = raster
                this.collaborationUpdateContent(raster)
            }
        })
    }

    public connect(sessionId: string, shareCurrentContent: boolean) {
        if (this.store.getState().collaboration.sessionId !== sessionId) {
            const action = collaborationSlice.actions.sessionIdDefined(sessionId)
            this.store.dispatch(action)
            const storedSessionId = this.store.getState().collaboration.sessionId
            console.log("sessionId", storedSessionId)
        }
        if (this.websocket) {
            this.websocket.close()
        }
        this.sendContentOnNextOpen = shareCurrentContent
        this.websocket = new WebSocket(this.serviceUrl)
        this.websocket.addEventListener('message', (event => this.handleMessage(event)))
        this.websocket.addEventListener('error', (event => this.handleError(event)))
        this.websocket.addEventListener('close', (event => this.handleClose(event)))
        this.websocket.addEventListener('open', (event => this.handleOpen(event)))
    }

    public disconnect() {
        if (this.store.getState().collaboration.sessionId) {
            const action = collaborationSlice.actions.sessionIdDefined()
            this.store.dispatch(action)
        }
        if (this.websocket) {
            this.websocket.close()
            this.websocket = undefined
        }
        this.latestServerRasterState = Raster.empty<string>()
    }

    private handleMessage(event: MessageEvent) {
        const data = event.data
        const message = JSON.parse(data) as ServerToClientMessage
        switch (message?.type) {
            case undefined:
                break;
            case participantsUpdateMessageType:
                this.handleParticipantsUpdate(message as ParticipantsUpdateMessage)
                break;
            case pointerPosUpdateMessageType:
                this.handleParticipantPointerPosUpdate(message as PointerPosUpdateMessage)
                break
            case contentUpdateMessageType:
                this.handleServerContentChanged(message as ContentUpdateMessage)
                break
            default:
                console.warn("Received unknown server message:", message.type)
                break;
        }
    }

    private handleParticipantsUpdate(message: ParticipantsUpdateMessage) {
        const participants: Participant[] = message.participants.map(p => ({ name: p.name, id: p.id, pointerX: undefined, pointerY: undefined }))
        const action = collaborationSlice.actions.participantListUpdated(participants)
        this.store.dispatch(action)
    }

    private handleParticipantPointerPosUpdate(message: PointerPosUpdateMessage) {
        const action = collaborationSlice.actions.pointerPosOfParticipantUpdated(message)
        this.store.dispatch(action)
    }

    private handleServerContentChanged(message: ContentUpdateMessage) {
        let diff: IRaster<string>
        if (message.startFromScratch) {
            const currentRaster = this.store.getState().drawing.present.characterRaster
            const newTargetState = Raster.fromCells(message.cells.map(c => ({ point: { x: c.x, y: c.y }, value: c.c })))
            diff = Raster.diff(currentRaster, newTargetState)
        } else {
            diff = Raster.fromCells(message.cells.map(c => ({ point: { x: c.x, y: c.y }, value: c.c })))
        }

        if (!Raster.isEmpty(diff)) {
            this.latestServerRasterState = Raster.updateWith(this.latestServerRasterState, diff)
            const update: IUpdateDocumentPayload = {
                rasterModifications: diff
            }
            const action = drawingSlice.actions.documentUpdated(update)
            this.store.dispatch(action)
        }
    }


    private handleError(event: Event) {
        console.error("WS error:", event)
    }

    private handleClose(event: CloseEvent) {
        console.log("connection to collaboration service closed")
        const stateAction = collaborationSlice.actions.connectionStateUpdated(false)
        this.store.dispatch(stateAction)
        const participantsAction = collaborationSlice.actions.participantListUpdated([])
        this.store.dispatch(participantsAction)
    }

    private handleOpen(event: Event) {
        console.log("connection to collaboration service established")
        const action = collaborationSlice.actions.connectionStateUpdated(true)
        this.store.dispatch(action)
        const sessionId = this.store.getState().collaboration.sessionId
        const myName = this.store.getState().collaboration.myName
        if (!sessionId) {
            this.websocket.close()
        } else {
            let initialCells: CellContent[]
            if (this.sendContentOnNextOpen) {
                const currentRasterCells = Raster.getCells(this.store.getState().drawing.present.characterRaster)
                initialCells = currentRasterCells.map(cell => ({ x: cell.point.x, y: cell.point.y, c: cell.value }))
                this.sendContentOnNextOpen = false
            }
            const message: JoinSessionMessage = {
                type: joinSessionMessageType,
                sessionId: sessionId,
                myName: myName,
                initialCells: initialCells
            }
            this.websocket.send(JSON.stringify(message))
        }
    }

    public sendPointerPosition(pos: IRasterPoint) {
        if (this.isConnected()) {
            const message: PointerPosMessage = {
                type: pointerPosMessageType,
                x: pos.x,
                y: pos.y
            }
            this.websocket.send(JSON.stringify(message))
        }
    }

    public sendMyNameUpdate(name: string) {
        if (this.isConnected()) {
            const message: MyNameUpdateMessage = {
                type: myNameUpdateMessageType,
                name: name
            }
            this.websocket.send(JSON.stringify(message))
        }
    }

    public sendUpdatedDrawingState(raster: IRaster<string>) {
        if (this.isConnected()) {
            const diff = Raster.diff(this.latestServerRasterState, raster)
            this.latestServerRasterState = Raster.updateWith(this.latestServerRasterState, diff)
            const diffCells = Raster.getCells(diff)
            for (const c of diffCells) {
                if (!c.value) {
                    console.log("null")
                }
            }
            if (diffCells.length > 0) {
                const message: ContentChangedMessage = {
                    type: contentChangedMessageType,
                    cells: diffCells.map(c => ({ x: c.point.x, y: c.point.y, c: c.value }))
                }
                this.websocket.send(JSON.stringify(message))
            }
        }
    }

    public isConnected(): boolean {
        return (this.websocket?.readyState) === WebSocket.OPEN
    }


    private collaborationUpdateContent(raster: IRaster<string>) {
        console.log("Send updated raster to participants")
        if (this.isConnected()) {
            this.sendUpdatedDrawingState(raster)
        }
    }
}


export const collaborationService = new CollaborationService()