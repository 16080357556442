import * as React from "react";

function SvgTextTool(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
      <path d="M8.788 8.204l-.11 7.583 17.019-.105 1.768 44.127H38.96l-2.431-43.917 17.461.316.221-9.583z" />
    </svg>
  );
}

export default SvgTextTool;
