import { IRasterPoint, RasterPoint } from "./raster-point";

export class RasterBox {
    topLeft: IRasterPoint
    bottomRight: IRasterPoint
    
    constructor(from: IRasterPoint, to: IRasterPoint) {
        if (from.x < to.x) {
            if (from.y < to.y) {
                this.topLeft = from
                this.bottomRight = to
            } else {
                this.topLeft = RasterPoint.xy(from.x, to.y)
                this.bottomRight = RasterPoint.xy(to.x, from.y)
            }
        } else {
            if (from.y < to.y) {
                this.topLeft = RasterPoint.xy(to.x, from.y)
                this.bottomRight = RasterPoint.xy(from.x, to.y)
            } else {
                this.topLeft = to
                this.bottomRight = from
            }
        }
    }

    minX() { return this.topLeft.x }
    maxX() { return this.bottomRight.x }
    minY() { return this.topLeft.y }
    maxY() { return this.bottomRight.y }
    equals(other: RasterBox) { return other.topLeft.x === this.topLeft.x && other.topLeft.y === this.topLeft.y && other.bottomRight.x === this.bottomRight.x && other.bottomRight.y === this.bottomRight.y }
    static equals(a: RasterBox, b: RasterBox) { return a.topLeft.x === b.topLeft.x && a.topLeft.y === b.topLeft.y && a.bottomRight.x === b.bottomRight.x && a.bottomRight.y === b.bottomRight.y }
    
    getUnitedWith(other: RasterBox) {
        return new RasterBox(RasterPoint.xy(Math.min(this.minX(), other.minX()), Math.min(this.minY(), other.minY())),
        RasterPoint.xy(Math.max(this.maxX(), other.maxX()), Math.max(this.maxY(), other.maxY())))
    }

    getExpandedBy(dxMin: number, dyMin: number, dxMax: number, dyMax: number): RasterBox {
        return new RasterBox(RasterPoint.xy(this.minX() - dxMin, this.minY() - dyMin), RasterPoint.xy(this.maxX() + dxMax, this.maxY() + dyMax))
    }

    isPointInside(point: IRasterPoint): boolean {
        const result = this.topLeft.x < point.x && point.x < this.bottomRight.x && this.topLeft.y < point.y && point.y < this.bottomRight.y
        return result
    }

    isPointOutside(point: IRasterPoint): boolean {
        const result = this.topLeft.x > point.x || point.x > this.bottomRight.x || this.topLeft.y > point.y || point.y > this.bottomRight.y
        return result
    }

    isPointOnBorder(point: IRasterPoint): boolean {
        const result = !this.isPointInside(point) && !this.isPointOutside(point)
        return result
    }

}
