import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { Watermarker } from '../../services/watermarker'
import { drawingSlice } from '../store/reducers/drawing-reducer'

export function ClipboardImportDialog() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const canPasteDirectly = navigator?.clipboard?.readText != undefined
    return <Dialog maxWidth="lg" onClose={() => navigate("/")} aria-labelledby="simple-dialog-title" open={true}>
        <DialogTitle id="simple-dialog-title">Import Drawing</DialogTitle>
        <DialogContent>
            <Timeline align="left">
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Copy drawing from your text editor.</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{
                        canPasteDirectly ?
                            (<Button variant="contained" color="primary" onClick={() => importFromClipboard(navigate, dispatch)}>Import</Button>) :
                            (<textarea placeholder="Paste drawing here" onChange={(event) => importText(dispatch, navigate, event.target.value)}></textarea>)
                    }
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot />
                    </TimelineSeparator>
                    <TimelineContent>Edit! 🎨😄</TimelineContent>
                </TimelineItem>
            </Timeline>
            <div>
                ... or <Button variant="contained" onClick={() => startFromScratch(navigate, dispatch)}>Start from scratch</Button>
            </div>
        </DialogContent>
        <DialogActions></DialogActions>
    </Dialog>
}

function importFromClipboard(navigate: NavigateFunction, dispatch: React.Dispatch<any>) {
    navigator.clipboard.readText().then(text => {
        console.log("paste text already in clipboard: " + text)
        importText(dispatch, navigate, text)
    })
}

function startFromScratch(navigate: NavigateFunction, dispatch: React.Dispatch<any>) {
    importText(dispatch, navigate, "")
}

function importText(dispatch: React.Dispatch<any>, navigate: NavigateFunction, text: string) {
    const watermarker = new Watermarker(text)
    watermarker.removeWatermark()
    const documentLoadedAction = drawingSlice.actions.documentLoaded({ content: watermarker.getContent() })
    dispatch(documentLoadedAction)
    navigate("/")
}